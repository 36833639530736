<script setup>
import CustomSelect from "../components/CustomSelect.vue";
import HauptMenue from "../components/HauptMenue.vue";
import FooterMenu from "../components/FooterMenu.vue";
import DatenschutzHinweis from "../components/DatenschutzHinweis.vue";
import { store } from "../components/store.vue";
/** Komponente zum Einbinden des Menüpunktes mit den Firmen
 * @displayName Seite Firmen Adressdaten
 */
</script>
<script>
export default {
  name: "FirmenAdressdaten",
  components: {
    // myRouter: router,
    HauptMenue,
    FooterMenu,
    DatenschutzHinweis,
  },
  data() {
    return {
      store,
      //statusCurrent: "999",
      //meldung: "",
      sLandOptions: this.$servertalk.getSelectOpts("NATIONALITAET"),
      sLand: {
        text: this.$servertalk.getName(
          "NATIONALITAET",
          store.subData.data.Geschaeftsadresse.GLKZ
        ),
        key: store.subData.data.Geschaeftsadresse.GLKZ,
      },
    };
  },
  methods: {},
  beforeCreate: function () {
    this.$servertalk.doLog("# Reading From Local, Create ");
    if (!store.auth) {
      //if (!this.$servertalk.restoreStore())
      this.$router.push("/");
    }
  },
  mount: function () {
    this.$servertalk.doLog("# Reading From Local, Mount");
    if (!store.auth) {
      if (!this.$servertalk.restoreStore()) this.$router.push("/");
    }
    window._paq.push(["setDocumentTitle", this.name]);
    window._paq.push(["trackPageView"]);
  },
  computed: {
    setSchwebendClass() {
      // Erhalte den Wert "Privatadresse" von $servertalk
      //const aenderung = this.$servertalk.gettAttr("fadata.Firmenstammdaten");
      //const aenderungPrivatadresseValue = this.$servertalk.gettAttr("subData.draft.sService.Entwuerfe.AenderungKontaktdaten");
      return null;
    },
  },
};
</script>
<template>
  <div class="main-wrapper schwebend" :class="store.accessRestriction">
    <HauptMenue />
    <div class="mt-36 md:mt-48 lg:mt-56 mb-4 max-w-7xl mx-auto px-5 lg:px-0">
      <div class="max-w-5xl mx-auto mb-24">
        <h1 class="text-primary/75 font-bold text-2xl md:text-3xl lg:text-4xl text-center mb-12 uppercase">
          Adressdaten von Firma
          <br />
          <span>
            "{{ store.subData.data.Firmenstammdaten.FNAME }}
            {{
              $servertalk.getName(
                "GESFORM",
                store.subData.data.Firmenstammdaten?.FGESFORM
              )
            }} {{ store.subData.data.Firmenstammdaten.FKENNR }}/{{
              store.subData.data.Firmenstammdaten.FABRGR
            }}"
          </span>
        </h1>
        <div class="grid xl:grid-cols-1 sm:gap-5">
          <div class="relative">
            <label for="aendStr" class="text-sm mb-2 block">
              Strasse Firmensitz
            </label>
            <input type="text" id="aendStr"
              class="peer w-full p-3 border border-secondary/50 rounded-md mb-8 invalid:border-red-500 invalid:bg-red-50 focus:outline-0 peer invalid:text-red-500 focus:invalid:border-red-500"
              v-model="store.subData.data.Geschaeftsadresse.GSTRASSE" />
            <span class="invisible peer-invalid:visible text-red-500 text-sm absolute bottom-2 left-0 min-w-max">
              Bitte geben Sie eine Straße mit Hausnummer ein.
            </span>
          </div>
        </div>
        <div class="plz-stadt grid lg:grid-cols-6 gap-x-8">
          <div class="plz lg:col-span-1 relative">
            <label for="plz" class="text-sm mb-2 block">PLZ Firmensitz</label>
            <input maxlength="255" type="text" id="plz"
              class="w-full p-3 border border-secondary/50 rounded-md mb-8 invalid:border-red-500 invalid:bg-red-50 focus:outline-0 focus:border-tertiary/50 peer invalid:text-red-500 focus:invalid:border-red-500"
              required v-model="store.subData.data.Geschaeftsadresse.GPLZ" inputmode="numeric" />
          </div>
          <div class="ort lg:col-span-3">
            <label for="ort" class="text-sm mb-2 block">Ort Firmensitz</label>
            <input maxlength="255" type="text" id="ort"
              class="w-full p-3 border border-secondary/50 rounded-md mb-8 focus:outline-0 focus:border-tertiary/50"
              required v-model="store.subData.data.Geschaeftsadresse.GORT" />
          </div>
          <div class="ortzusatz lg:col-span-2">
            <label for="ortzusatz" class="text-sm mb-2 block">Ort Zusatz</label>
            <input maxlength="255" type="text" id="ortzusatz"
              class="w-full p-3 border border-secondary/50 rounded-md mb-8 focus:outline-0 focus:border-tertiary/50"
              required v-model="store.subData.data.Geschaeftsadresse.GORTZUS" />
          </div>
        </div>
        <div class="plz-stadt-postfach grid lg:grid-cols-6 gap-x-8">
          <div class="plz-postfach lg:col-span-1 relative">
            <label for="plz-postfach" class="text-sm mb-2 block">PLZ Postfach</label>
            <input maxlength="255" type="text" id="plz-postfach"
              class="w-full p-3 border border-secondary/50 rounded-md mb-8 invalid:border-red-500 invalid:bg-red-50 focus:outline-0 focus:border-tertiary/50 peer invalid:text-red-500 focus:invalid:border-red-500"
              required v-model="store.subData.data.Geschaeftsadresse.GPOSTPLZ" inputmode="numeric" />
          </div>
          <div class="postfach lg:col-span-5">
            <label for="postfach" class="text-sm mb-2 block">Postfach</label>
            <input maxlength="255" type="text" id="postfach"
              class="w-full p-3 border border-secondary/50 rounded-md mb-8 focus:outline-0 focus:border-tertiary/50"
              required v-model="store.subData.data.Geschaeftsadresse.GPOSTFACH" />
          </div>
        </div>
        <div class="grid xl:grid-cols-1 sm:gap-5">
          <div class="relative">
            <label for="land" class="text-sm mb-2 block">Land</label>
            <CustomSelect @input="sLand" class="select" :options="sLandOptions"
              :default="store.subData.data.Geschaeftsadresse.GLKZ"
              :oldDefault="store.subData.oldData.Geschaeftsadresse.GLKZ" />
          </div>
        </div>
        <router-link to="/sservice-aenderung-firmenadressdaten" custom v-slot="{ navigate }">
          <button @click="navigate" role="link" type="submit" :disabled="store.subData.isReadOnly || store.selectedFirma?.VERMITTLER == 1
            "
            class="bg-primary text-white py-4 w-64 rounded-md text-lg lg:text-xl mx-auto block hover:bg-tertiary transition disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:bg-primary">
            Adressdaten ändern
          </button>
        </router-link>
      </div>
    </div>
  </div>

  <FooterMenu />
</template>
