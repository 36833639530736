<script setup>
import CustomSelect from "./CustomSelect.vue";
</script>

<script>
import { store } from "./store.vue";
/** Komponente zur Anzeige persönlicher Daten des Ansprechpartners ohne Änderungsmöglichkeit
 * @displayName Anzeige persönlicher Daten Ansprechpartner
 */
export default {
  name: "DataDetailsAnsprechpartnerTab",
  data() {
    return {
      store,
      sAnredeOptions: this.$servertalk.getSelectOpts("ADRANREDE"),
      // Auf folgenden Feldern liegen Plausis, sie dürfen deshalb nur wenn korrekt in den Store übertragen werden
      EMAIL: store.masterData.data.Stammdaten.EMAIL,
      TELEFON: store.masterData.data.Stammdaten.TELEFON,
      GEBDAT: store.masterData.data?.Stammdaten?.GEBDAT ? store.masterData.data.Stammdaten.GEBDAT : "1960-01-01",
    };
  },

  mounted() {
    window.scrollTo(0, 0);
  },
  computed: {},
  methods: {
    selectAnrede(key) {
      store.masterData.data.Stammdaten.ADRANRD = key.value;
    },

    aendern(e) {
      this.$servertalk.saveStore();
      e.preventDefault();
    },
    async reload() {
      this.render = false;
      await this.$nextTick();
      this.render = true;
    },
  },
  watch: {
    EMAIL: function (EMAIL) {
      // Lokales Feld nur in den Store übertragen, wenn Plausi erfolgreich war.
      if (
        EMAIL.match(
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
      ) {
        store.masterData.data.Stammdaten.EMAIL =
          EMAIL.toLowerCase();
      }
    },
    TELEFON: function (TELEFON) {
      // Lokales Feld nur in den Store übertragen, wenn Plausi erfolgreich war.
      if (TELEFON.match(/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g)) {
        store.masterData.data.Stammdaten.TELEFON = TELEFON;
      }
    },
  },
  //props: ["options", "value"],
};
</script>

<template>
  <form class="schwebend">
    <div class="grid lg:grid-cols-2 gap-x-8">
      <div>
        <label for="anrede" class="text-sm mb-2 block">Anrede</label>
        <CustomSelect class="schwebend" :options="sAnredeOptions"
          :default="store.masterData.data.Stammdaten.ADRANRD" :oldDefault="store.masterData.oldData.Stammdaten.ADRANRD
            " />
      </div>
      <div>
        <label for="titel" class="text-sm mb-2 block">Titel</label>
        <input maxlength="255" type="text" id="titel" class="w-full p-3 border border-secondary/50 rounded-md mb-8"
          required v-model="store.masterData.data.Stammdaten.TITEL" disabled />
      </div>
    </div>
    <div class="grid lg:grid-cols-2 gap-x-8">
      <div class="relative">
        <label for="vorname" class="text-sm mb-2 block">Vorname</label>
        <input maxlength="255" type="text" id="vorname"
          class="w-full p-3 border border-secondary/50 rounded-md mb-8 invalid:border-red-500 invalid:bg-red-50 focus:outline-0 focus:border-tertiary/50 peer invalid:text-red-500 focus:invalid:border-red-500"
          disabled v-model="store.masterData.data.Stammdaten.VORNAME" />
        <span class="invisible peer-invalid:visible text-red-500 text-sm absolute bottom-2 left-0">
          Bitte tragen Sie hier Ihren Vornamen ein.
        </span>
      </div>
      <div class="relative">
        <label for="nachname" class="text-sm mb-2 block">Nachname</label>
        <input maxlength="255" type="text" id="nachname"
          v-model="store.masterData.data.Stammdaten.NAME"
          class="w-full p-3 border border-secondary/50 rounded-md mb-8 invalid:border-red-500 invalid:bg-red-50 focus:outline-0 focus:border-tertiary/50 peer invalid:text-red-500 focus:invalid:border-red-500"
          disabled />
        <span class="invisible peer-invalid:visible text-red-500 text-sm absolute bottom-2 left-0">
          Bitte tragen Sie hier Ihren Nachnamen ein.
        </span>
      </div>
    </div>
    <div class="grid lg:grid-cols-2 gap-x-8">
      <div class="relative">
        <label for="telefonnummer" class="text-sm mb-2 block">
          Telefonnummer
        </label>
        <input maxlength="255" type="tel" id="telefonnummer"
          class="w-full p-3 border border-secondary/50 rounded-md mb-8 invalid:border-red-500 invalid:bg-red-50 focus:outline-0 peer invalid:text-red-500 focus:invalid:border-red-500 peer"
          v-model="TELEFON" inputmode="numeric" disabled />
        <span class="invisible peer-invalid:visible text-red-500 text-sm absolute bottom-2 left-0">
          Bitte tragen Sie hier Ihre Telefonnummer ein.
        </span>
      </div>
      <div class="relative">
        <label for="email" class="text-sm mb-2 block">E-Mail Adresse</label>
        <input maxlength="255" type="email" id="email"
          class="w-full p-3 border border-secondary/50 rounded-md mb-8 invalid:border-red-500 invalid:bg-red-50 focus:outline-0 focus:border-tertiary/50 peer invalid:text-red-500 focus:invalid:border-red-500"
          v-model="EMAIL" disabled inputmode="email" />
        <span class="invisible peer-invalid:visible text-red-500 text-sm absolute bottom-2 left-0">
          Bitte tragen Sie hier Ihre E-Mail Adresse ein.
        </span>
      </div>
    </div>
    <div class="grid lg:grid-cols-2 gap-x-8">
      <div class="relative">
        <label for="geburtsdatum" class="text-sm mb-2 block">
          Geburtsdatum
        </label>
        <input maxlength="255" type="date" id="geburtsdatum" v-model="GEBDAT"
          class="w-full p-3 border border-secondary/50 rounded-md mb-8" disabled />
      </div>
      <div class="relative">
      </div>
    </div>
    <router-link to="/sservice-aenderung-ansprechpartnerdaten" custom v-slot="{ navigate }">
      <button @click="navigate" :disabled="store.masterData.isReadOnly" role="link" type="submit"
        class="bg-primary text-white py-4 w-72 rounded-md text-lg lg:text-xl mx-auto block hover:bg-tertiary transition disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:bg-primary">
        Persönliche Daten ändern
      </button>
    </router-link>
  </form>
</template>
