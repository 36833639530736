<script setup>
import HauptMenue from "../components/HauptMenue.vue";
import FooterMenu from "../components/FooterMenu.vue";
import DatenschutzHinweis from "../components/DatenschutzHinweis.vue";
import { store } from "../components/store.vue";
/** Komponente zum Einbinden des Menüpunktes mit den Firmen
 * @displayName Seite Firmen Adressdaten
 */
</script>
<script>
export default {
  name: "FirmenFinanzamtsdaten",
  components: {
    // myRouter: router,
    HauptMenue,
    FooterMenu,
    DatenschutzHinweis,
  },
  data() {
    return {
      store,
      sLandOptions: this.$servertalk.getSelectOpts("BANK_LAND"),
      sLand: {
        text: this.$servertalk.getName(
          "BANK_LAND",
          store.subData.data.Firmenstammdaten.FINLKZ
        ),
        key: store.subData.data.Firmenstammdaten.FINLKZ,
      },
      //statusCurrent: "999",
      //meldung: "",
    };
  },
  methods: {},
  beforeCreate: function () {
    this.$servertalk.doLog("# Reading From Local, Create ");
    if (!store.auth) {
      //if (!this.$servertalk.restoreStore())
      this.$router.push("/");
    }
  },
  mount: function () {
    this.$servertalk.doLog("# Reading From Local, Mount");
    if (!store.auth) {
      if (!this.$servertalk.restoreStore()) this.$router.push("/");
    }
    window._paq.push(["setDocumentTitle", this.name]);
    window._paq.push(["trackPageView"]);
  },
  computed: {
    setSchwebendClass() {
      // Erhalte den Wert "Privatadresse" von $servertalk
      //const aenderung = this.$servertalk.gettAttr("fadata.Firmenstammdaten");
      //const aenderungPrivatadresseValue = this.$servertalk.gettAttr("subData.draft.sService.Entwuerfe.AenderungKontaktdaten");
      return null;
    },
  },
};
</script>
<template>
  <div class="main-wrapper schwebend" :class="store.accessRestriction">
    <HauptMenue />
    <div class="mt-36 md:mt-48 lg:mt-56 mb-4 max-w-7xl mx-auto px-5 lg:px-0">
      <div class="max-w-5xl mx-auto mb-24">
        <h1
          class="text-primary/75 font-bold text-2xl md:text-3xl lg:text-4xl text-center mb-12 uppercase">
          Finanzamtsdaten von Firma
          <br />
          <span>
            "{{ store.subData.data.Firmenstammdaten.FNAME }}
            {{
              $servertalk.getName(
                "GESFORM",
                store.subData.data.Firmenstammdaten?.FGESFORM
              )
            }} {{ store.subData.data.Firmenstammdaten.FKENNR }}/{{
              store.subData.data.Firmenstammdaten.FABRGR
            }}"
          </span>
        </h1>
        <div class="grid lg:grid-cols-6 sm:gap-x-5">
          <div class="relative lg:col-span-6">
            <label for="steuernummer" class="text-sm mb-2 block">Steuernummer</label>
            <input
              type="text"
              id="steuernummer"
              class="peer w-full p-3 border border-secondary/50 rounded-md mb-8"
              v-model="store.subData.data.Firmenstammdaten.FSTEUNR" />
          </div>
          <div class="relative lg:col-span-4">
            <label for="finanzamt" class="text-sm mb-2 block">Finanzamt</label>
            <input
              type="text"
              id="finanzamt"
              class="peer w-full p-3 border border-secondary/50 rounded-md mb-8"
              v-model="store.subData.data.Finanzamt.FINFNAME1" />
          </div>
          <div class="relative lg:col-span-2">
            <label for="finanzamtnr" class="text-sm mb-2 block">
              Finanzamtsnummer
            </label>
            <input
              type="text"
              id="finanzamtnr"
              class="peer w-full p-3 border border-secondary/50 rounded-md mb-8"
              v-model="store.subData.data.Finanzamt.FINFNAME2" />
          </div>
          <div class="relative lg:col-span-6">
            <label for="finanzamtstr" class="text-sm mb-2 block">
              Finanzamt Straße
            </label>
            <input
              type="text"
              id="finanzamtstr"
              class="peer w-full p-3 border border-secondary/50 rounded-md mb-8"
              v-model="store.subData.data.Finanzamt.FINSTRASSE" />
          </div>
          <div class="plz lg:col-span-1 relative">
            <label for="finanzamtplz" class="text-sm mb-2 block">
              PLZ Finanzamt
            </label>
            <input
              maxlength="255"
              type="text"
              id="finanzamtplz"
              class="w-full p-3 border border-secondary/50 rounded-md mb-8 invalid:border-red-500 invalid:bg-red-50"
              required
              v-model="store.subData.data.Finanzamt.FINPLZ"
              inputmode="numeric" />
          </div>
          <div class="ort lg:col-span-3 relative">
            <label for="finanzamtort" class="text-sm mb-2 block">
              Ort Finanzamt
            </label>
            <input
              maxlength="255"
              type="text"
              id="finanzamtort"
              class="w-full p-3 border border-secondary/50 rounded-md mb-8 invalid:border-red-500 invalid:bg-red-50"
              required
              v-model="store.subData.data.Finanzamt.FINORT" />
          </div>
          <div class="ortzusatz lg:col-span-2">
            <label for="ortzusatz" class="text-sm mb-2 block">
              Ort Zusatz Finanzamt
            </label>
            <input
              maxlength="255"
              type="text"
              id="ortzusatz"
              class="w-full p-3 border border-secondary/50 rounded-md mb-8 focus:outline-0 focus:border-tertiary/50"
              required
              v-model="store.subData.data.Finanzamt.FINORTZUS" />
          </div>
          <!--<div class="relative lg:col-span-6">
            <label for="firmenname" class="text-sm mb-2 block">
              Sitz des Finanzamts
            </label>
            <CustomSelect
              @input="sLand"
              class="select"
              :options="sLandOptions"
              :default="store.subData.data.Finanzamt.FINLKZ"
              :oldDefault="store.subData.oldData.Finanzamt.FINLKZ" />
          </div>-->
        </div>

        <router-link
          to="/sservice-aenderung-finanzamtsdaten"
          custom
          v-slot="{ navigate }">
          <button
            @click="navigate"
            role="link"
            type="submit"
            :disabled="
              store.subData.isReadOnly || store.selectedFirma?.VERMITTLER == 1
            "
            class="bg-primary text-white py-4 w-64 rounded-md text-lg lg:text-xl mx-auto block hover:bg-tertiary transition disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:bg-primary">
            Finanzamtsdaten ändern
          </button>
        </router-link>
      </div>
    </div>
  </div>

  <FooterMenu />
</template>
