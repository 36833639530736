<script>
import { reactive } from "vue";

/** Store beschreibungstext
 *
 */

//if (! store.restoreStore()) this.$router.push("/");
export default {
  name: "Store",
};
export const store = reactive({
  user: "",
  passwd: "",
  identhash: "",
  decrypthash: "",
  //iKey:"",
  /**
   * Abweichung der Browserzeit von der Serverzeit.
   * sollte normal bei ca. 0 liegen.
   * store.deltaT = ServerDate - Date.now();
   * ServerDate = Date.now() + store.deltaT
   */
  deltaT: 0,
  /**
   * Kennung, dass nur lesend und in begrenztem Umfang zugegriffen werden darf
   * weil z.B. der User bereits in einer anderen Session angemeldet ist.
   * @values true, false
   */

  isTrackerBlockActive: false,
  /**
   * Kennung, in welcher Umgebung die aktuelle Anwendung läuft
   * @values 'GPM', 'GPMTEST'
   */
  datenart: "GPM",
  /**
   * Kennung, wer sich angemeldet hat (eingestellt von Pensus über Existenz von store.data['@attributes'].Person oder store.data['@attributes'].Ansprechpartner)
   * @values 'person', 'ansprechpartner'
   */
  satzart: "person",
  /**
   * Aktivieren/Deaktivieren von Debug-Meldungen und -zugängen
   * @values true, false
   */
  isDebug: false,
  /**
   * Ausblenden von noch nicht fertiggestellten Funktionsblöcken in der Live-Umgebung
   * @values true, false
   */
  goLive: true,
  jwt: false,
  myUUID: "",
  birthday: "",
  auth: false,

  /**
   * Min. Version eines gespeicherten Schnellzugriffs, damit er nicht gelöscht wird (hochzusetzen bei neuen Selfservices oder relevanten Änderungen)
   */
  minVersionSchnellzugriff: "1.0",

  /**
   * Aktuell bereitstehenden Daten wie angezeigt, ggf. mit Änderungs- oder Sperrattribut von Pensus
   * (NICHT für Selfservices und Schnellzugriff)
   */
  masterData: {
    config: {},
    cdata: {},
    isReadOnly: false,
    isLoaded: false,
    /**
     * Letzter Hashwert nach dem Abspeichern
     */
    lastmod: "",
    modifyhash: {},
    datasent: 0,
    data: {
      "@attributes": {
        Person: "244554",
        Datenart: "GPM",
        Version: "0.0.1",
        alias:
          "a5354503cb243c4825e46d20b9ba68c659654d72e8909b6512c638b7bb42cf58",
        ident2:
          "483d74691b67b096a32c14644ca422b8bb26f5b936671395789cf01db50dd832",
        identextra:
          "5f6508663084a2cdbe3cc2d42faf2d0730b5c17a0c1f412c2f3e011eec453b77",
      },
      Stammdaten: {
        Timestamp: "2024-01-31T09:40:16.798Z",
        PERSNR: "123456",
        EMAIL: "mustermensch@email.de",
        IS_PWD_INIT: "0",
        IS_USER_INIT: "0",
        NAME: "Mustermensch",
        VORNAME: "Erika",
        EHEDAT: "",
        FAMSTD: "4",
        GEBDAT: "1960-10-11",
        GEBNAME: "",
        GESCHL: "1",
        NAMEVS: "",
        NAMEZUS: "",
        NATKZ: "135",
        SVNR: "59111053K019",
        STEUERIDNR: "58923764054",
        TITEL: "",
        TODDAT: "",
      },
      Privatadresse: {
        PADRTYP: "1",
        PPLZ: "19306",
        PSTRASSE: "Musterstrasse 123",
        PPOSTPLZ: "",
        PPOSTFACH: "",
        PLKZ: "135",
        PCONAME: "",
        PORT: "Musterort",
        PORTZUS: "",
        PTELEFON: "",
        PHANDY: "",
        PADRANRD: "1",
        PBRFANRD: "",
      },
      Privatbank: {
        PRB_IBAN: "DE02120300000000202051",
        PRB_BIC: "BYLADEM1001",
        PRB_BNAME: "Deutsche Kreditbank Berlin",
        PRB_ABWNAME: "",
        PRB_LAND: "DE",
        BL_LKZ: "135",
      },
      Message: {
        MSGIDENT: [
          {
            "@attributes": {
              MSGIDENT: "387642",
            },
            MSGBETREFF: "Willkommen im Pensus Serviceportal!",
            MSGNACHRICHT: "",
            MSGGELESEN: "1",
            MSGDATE: "2024-01-08",
            MSGDELETE: "0",
            MSGPROTECTED: "0",
            MSGDOKID: "",
          },
        ],
      },
      Versorgung: {
        PMNR: {
          "@attributes": {
            PMNR: "346576",
          },
          FENR: "8245",
          FANR: "18",
          STNR: "",
          PSTATUSN: "3",
          FKOEIN: "",
          KONZE: "1900-01-01",
          FEEIN: "",
          ABRKREIS: "",
          AUSZAHLKNR: "46",
          FENAME: "Musterkasse",
          KENNR: "",
          ABRGR: "9999",
          GESFORM: "",
          ABRSTNAME: "Musterkasse",
          ABRSTKURZNAME: "MKA",
          FNAME: "Musterkasse",
          Krankenkasse: {
            KKNR: "16",
            KKVERSNR: "",
            KKSTATUS: "1",
            KKDATAB: "2020-05-01",
            KKKILO: "1",
          },
          Kinder: "",
          Dokumente: {
            DOKUNR: [
              {
                "@attributes": {
                  DOKUNR: "41333",
                },
                MD5: "",
                TYP: "PDF",
                TEXT: "Abrechnung 10 / 2023",
                DOKUTYPNR: "443",
                GELESEN: "0",
              },
            ],
          },
        },
      },
      passwdModified: "true",
      sService: {
        KontaktPensus: {
          nr1: {
            NAME: "Mustermensch",
            VORNAME: "Erika",
            EMAIL: "mustermensch@email.de",
            PTELEFON: "",
            ANLIEGEN: "1",
            NACHRICHT: "Musternachricht",
            uuid: "u64f0adbe-5410-4ee3-b2c4-084d1db9ceea",
            uploadsDokumente: [],
            changes: {
              ANLIEGEN: "1",
              NACHRICHT: "Musternachricht",
              uploadsDokumente: {},
            },
          },
        },
        AenderungKontaktdaten: {
          nr1: {
            PCONAME: "",
            PTELEFON: "",
            PHANDY: "",
            PADRTYP: "1",
            PSTRASSE: "Musterweg 123",
            PPLZ: "12345",
            PORT: "Musterort",
            uuid: "u4fbe9482-b781-40a5-9fd1-64461bfe09d2",
            PLKZ: "135",
            changes: {
              PSTRASSE: "Musterweg 234",
            },
          },
        },
        AenderungBankverbindung: {
          nr1: {
            PRB_IBAN: "DE02120300000000202051",
            PRB_BIC: "BYLADEM1001",
            PRB_BNAME: "Deutsche Kreditbank Berlin",
            PRB_LAND: "DE",
            PRB_ABWNAME: "",
            uuid: "u5c716cf1-c7a9-4a08-a700-a9c60c496796",
            changes: {
              PRB_ABWNAME: "Mustername neu",
            },
          },
        },
      },
    },
    docs: {},
    /**
     * Ursprüngliche bereitgestellte Daten wie von Pensus geliefert, ggf. mit Änderungs- oder Sperrattribut von Pensus
     * (NICHT für Selfservices und Schnellzugriff)
     */
    oldData: {
      "@attributes": {
        Person: "244554",
        Datenart: "GPMTEST",
        Version: "0.0.1",
      },
      Stammdaten: {
        Timestamp: "2024-01-10",
        PERSNR: "123456",
        EMAIL: "mustermensch@email.de",
        IS_PWD_INIT: "1",
        IS_USER_INIT: "0",
        NAME: "Mustermensch",
        VORNAME: "Erika",
        EHEDAT: "",
        FAMSTD: "4",
        GEBDAT: "1960-10-11",
        GEBNAME: "",
        GESCHL: "1",
        NAMEVS: "",
        NAMEZUS: "",
        NATKZ: "135",
        SVNR: "59111053K019",
        STEUERIDNR: "58923764054",
        TITEL: "",
        TODDAT: "",
      },
      Privatadresse: {
        PADRTYP: "1",
        PPLZ: "12345",
        PSTRASSE: "Musterweg 123",
        PPOSTPLZ: "",
        PPOSTFACH: "",
        PLKZ: "135",
        PCONAME: "",
        PORT: "Musterort",
        PORTZUS: "",
        PTELEFON: "",
        PHANDY: "",
        PADRANRD: "1",
        PBRFANRD: "",
      },
      Privatbank: {
        PRB_IBAN: "DE02120300000000202051",
        PRB_BIC: "BYLADEM1001",
        PRB_BNAME: "Deutsche Kreditbank Berlin",
        PRB_ABWNAME: "",
        PRB_LAND: "DE",
        BL_LKZ: "135",
      },
      Message: {
        MSGIDENT: [
          {
            "@attributes": {
              MSGIDENT: "387642",
            },
            MSGBETREFF: "Willkommen im Pensus Serviceportal!",
            MSGNACHRICHT: "",
            MSGGELESEN: "0",
            MSGDATE: "2024-01-08",
            MSGDELETE: "0",
            MSGPROTECTED: "0",
            MSGDOKID: "",
          },
        ],
      },
      Versorgung: {
        PMNR: {
          "@attributes": {
            PMNR: "246576",
          },
          FENR: "8245",
          FANR: "18",
          STNR: "",
          PSTATUSN: "3",
          FKOEIN: "",
          KONZE: "1900-01-01",
          FEEIN: "",
          ABRKREIS: "",
          AUSZAHLKNR: "46",
          FENAME: "Gothaer Pensionskasse",
          KENNR: "",
          ABRGR: "9999",
          GESFORM: "",
          ABRSTNAME: "Gothaer Pensionskasse",
          ABRSTKURZNAME: "GPK",
          FNAME: "Gothaer Pensionskasse",
          Krankenkasse: {
            KKNR: "16",
            KKVERSNR: "",
            KKSTATUS: "1",
            KKDATAB: "2019-05-01",
            KKKILO: "1",
          },
          Kinder: "",
          Dokumente: {
            DOKUNR: [
              {
                "@attributes": {
                  DOKUNR: "41333",
                },
                MD5: "",
                TYP: "PDF",
                TEXT: "Abrechnung 10 / 2023",
                DOKUTYPNR: "443",
                GELESEN: "0",
              },
            ],
          },
        },
      },
    }, // Daten wie von Pensus geliefert, ggf. mit Änderungs- oder Sperrattribut von Pensus
    /**
     * Nur Änderungen zwischen data und oldData, hier sollte kein Feld mit Sperrattribut existieren dürfen
     * (NICHT für Selfservices und Schnellzugriff)
     */
    datadiff: {
      "@attributes": {
        alias:
          "a5354503cb243c4825e46d20b9ba68c659654d72e8909b6512c638b7bb42cf58",
        ident2:
          "483d74691b67b096a32c14644ca422b8bb26f5b936671395789cf01db50dd832",
        identextra:
          "5f6508663084a2cdbe3cc2d42faf2d0730b5c17a0c1f412c2f3e011eec453b77",
      },
      Stammdaten: {
        EMAIL: "pensus132@data-and-vision.de",
        IS_PWD_INIT: "0",
      },
      Privatadresse: {},
      Privatbank: {},
      Message: {
        MSGIDENT: {
          0: {
            "@attributes": {},
            MSGGELESEN: "1",
          },
        },
      },
      Versorgung: {
        PMNR: {
          "@attributes": {},
          Krankenkasse: {},
          Dokumente: {
            DOKUNR: {
              0: {
                "@attributes": {},
              },
            },
          },
        },
      },
      passwdModified: "true",
      sService: {
        KontaktPensus: {
          nr1: {
            NAME: "Mustermensch",
            VORNAME: "Erika",
            EMAIL: "mustermensch@email.de",
            PTELEFON: "",
            ANLIEGEN: "1",
            NACHRICHT: "Musternachricht",
            uuid: "u64f0adbe-5410-4ee3-b2c4-084d1db9ceea",
            uploadsDokumente: [],
            changes: {
              ANLIEGEN: "1",
              NACHRICHT: "Musternachricht",
              uploadsDokumente: {},
            },
          },
        },
        AenderungKontaktdaten: {
          nr1: {
            PCONAME: "",
            PTELEFON: "",
            PHANDY: "",
            PADRTYP: "1",
            PSTRASSE: "Musterweg 123",
            PPLZ: "12345",
            PORT: "Musterort",
            uuid: "u4fbe9482-b781-40a5-9fd1-64461bfe09d2",
            PLKZ: "135",
            changes: {
              PSTRASSE: "Musterweg 345",
            },
          },
        },
        AenderungBankverbindung: {
          nr1: {
            PRB_IBAN: "DE02120300000000202051",
            PRB_BIC: "BYLADEM1001",
            PRB_BNAME: "Deutsche Kreditbank Berlin",
            PRB_LAND: "DE",
            PRB_ABWNAME: "Theo Mustermensch",
            uuid: "u5c716cf1-c7a9-4a08-a700-a9c60c496796",
            changes: {
              PRB_ABWNAME: "Theo Mustermensch",
            },
          },
        },
      },
    },

    /**
     * Aktuell bereitstehenden Daten für Selfservices und Schnellzugriff wie angezeigt, ggf. mit Änderungs- oder Sperrattribut von Pensus
     */
    draft: {
      /*
options: {
  Schnellzugriff: { list1: {}, list2: {} },
},
sService: { Entwuerfe: {} },
*/
      options: {
        Schnellzugriff: {
          list1: "",
          list2: "",
        },
        SchnellZugriff: {},
      },
      sService: {
        Entwuerfe: {
          AenderungKontaktdaten: {
            PCONAME: "",
            PTELEFON: "",
            PHANDY: "",
            PADRTYP: "1",
            PSTRASSE: "Musterweg 234",
            PPLZ: "12345",
            PORT: "Musterort",
            uuid: "ufd09b833-f684-4cec-b2ca-42b6596b42fb",
            PLKZ: "135",
          },
          AenderungBankverbindung: {
            PRB_IBAN: "DE02120300000000202051",
            PRB_BIC: "BYLADEM1001",
            PRB_BNAME: "Deutsche Kreditbank Berlin",
            PRB_LAND: "DE",
            PRB_ABWNAME: "Mustermensch Anders",
            GEB_IBAN: "DE02120300000000202051",
            GEB_BIC: "BYLADEM1001",
            GEB_BNAME: "Deutsche Kreditbank Berlin",
            GEB_LAND: "DE",
            GEB_ABWNAME: "Mustermensch Anders",
            uuid: "u8db4ebd6-a9bb-42e6-aa65-10cc3a0f3948",
          },
          KontaktPensus: {
            NAME: "Mustermensch",
            VORNAME: "Erika",
            EMAIL: "mustermensch@email.de",
            PTELEFON: "",
            ANLIEGEN: "2",
            NACHRICHT: "Musternachricht",
            uuid: "u8f719f79-1ae8-4db5-a38c-09b4b17366c3",
            uploadsDokumente: [],
          },
        },
      },
    },
    /**
     * Initialisierte Datenfelder für Selfservices und Schnellzugriff, ergänzt um ursprünglich bereitgestellte Daten wie von Pensus geliefert, ggf. mit Änderungs- oder Sperrattribut von Pensus
     */
    olddraft: {
      /*
    options: {
      Schnellzugriff: { list1: {}, list2: {} },
    },
    sService: { Entwuerfe: {} },
    */
      options: {
        Schnellzugriff: {
          list1: "",
          list2: "",
        },
      },
      sService: {
        Entwuerfe: {
          AenderungKontaktdaten: {
            PCONAME: "",
            PTELEFON: "",
            PHANDY: "",
            PADRTYP: "1",
            PSTRASSE: "Musterweg 123",
            PPLZ: "12345",
            PORT: "Musterort",
            uuid: "ufd09b833-f684-4cec-b2ca-42b6596b42fb",
            PLKZ: "135",
          },
          AenderungBankverbindung: {
            PRB_IBAN: "DE02120300000000202051",
            PRB_BIC: "BYLADEM1001",
            PRB_BNAME: "Deutsche Kreditbank Berlin",
            PRB_LAND: "DE",
            PRB_ABWNAME: "",
            GEB_IBAN: "DE02120300000000202051",
            GEB_BNAME: "Deutsche Kreditbank Berlin",
            GEB_LAND: "DE",
            GEB_ABWNAME: "Mustermensch Anders",
            uuid: "u8db4ebd6-a9bb-42e6-aa65-10cc3a0f3948",
          },
          KontaktPensus: {
            NAME: "Mustermensch",
            VORNAME: "Erika",
            EMAIL: "mustermensch@email.de",
            PTELEFON: "",
            ANLIEGEN: "-1",
            NACHRICHT: "",
            uuid: "u8f719f79-1ae8-4db5-a38c-09b4b17366c3",
            uploadsDokumente: [],
          },
        },
      },
    },
    /**
     * Nur Änderungen zwischen draft und olddraft, hier sollte kein Feld mit Sperrattribut existieren dürfen
     * (nur für Selfservices und Schnellzugriff)
     */
    draftdiff: {
      options: {
        Schnellzugriff: {},
        SchnellZugriff: {},
      },
      sService: {
        Entwuerfe: {
          AenderungKontaktdaten: {
            PSTRASSE: "Musterweg 234",
          },
          AenderungBankverbindung: {
            PRB_ABWNAME: "Mustermensch Anders",
          },
          KontaktPensus: {
            ANLIEGEN: "2",
            NACHRICHT: "Musternachricht Anders",
            uploadsDokumente: {},
          },
        },
      },
    },
    indextable: {},
  },
  subData: {
    /**
     * Firmendaten wie von Pensus bereitgestellt
     */
    config: {},
    cdata: {},
    isReadOnly: false,
    isLoaded: false,
    /**
     * Letzter Hashwert nach dem Abspeichern
     */
    lastmod: "",
    modifyhash: {},
    datasent: -1, //weil das schreiben der Subdata (also DIESE Zuweisung) das senden auslöst
    data: {},
    oldData: {},
    datadiff: {},
    docs: {},

    /**
     * Aktuell bereitstehenden Daten für Selfservices und Schnellzugriff wie angezeigt, ggf. mit Änderungs- oder Sperrattribut von Pensus
     */
    draft: {},
    /**
     * Initialisierte Datenfelder für Selfservices und Schnellzugriff, ergänzt um ursprünglich bereitgestellte Daten wie von Pensus geliefert, ggf. mit Änderungs- oder Sperrattribut von Pensus
     */
    olddraft: {},
    /**
     * Nur Änderungen zwischen draft und olddraft, hier sollte kein Feld mit Sperrattribut existieren dürfen
     * (nur für Selfservices und Schnellzugriff)
     */
    draftdiff: {},
    indextable: {},
  },

  test1: {},
  test2: {},
  /**
   * Zentrales Feld für den Schriftgrößen-Faktor in der Anzeige
   */
  fontfactor: 1.0,
  uploadFiles: { docs: [], files: [] },
  /**
   * Globales Feld zum Einstellen von Nachrichten. Enthält "text" und "status". Wird via App.vue nach definiertem Zeitraum zurückgesetzt.
   * status @values 'ok', 'error'
   */
  message: {},
  /**
   * Zugriffsbeschränkung durch Einstellen einer bestimmten Klasse, die via CSS verarbeitet wird
   * (eingebunden via  :class="store.accessRestriction")
   */
  accessRestriction: false,

  /**
   * FESTWERTE
   * Aufstellung aller Selfservices für die Anzeige als Schnellzugriff sowie als Auftrag an Pensus in der Übersicht.
   * Zugriffsbeschränkungen werden in den beiden genannten Bereichen berücksichtigt
   */
  selfService: {
    MeineDokumente: {
      title: "Meine Dokumente",
      image: "",
      icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zm-22.6 22.7c2.1 2.1 3.5 4.6 4.2 7.4H256V32.5c2.8 .7 5.3 2.1 7.4 4.2l83.9 83.9zM336 480H48c-8.8 0-16-7.2-16-16V48c0-8.8 7.2-16 16-16h176v104c0 13.3 10.7 24 24 24h104v304c0 8.8-7.2 16-16 16zm-48-244v8c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm0 64v8c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm0 64v8c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12z"/></svg>',
      url: "versorgungsdokumente",
      conditionHide: ["ansprechpartner"],
    },
    AenderungPersoenlichedaten: {
      title: "Änderung persönliche Daten",
      image: "assets/img/pensus-persoenlichedaten-self-service.webp",
      icon: '<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512"><path d="M637.2 199.8c-.9-.9-3-2.5-5.7-2.2-36.2 2.4-84.6 29.9-123.4 51.9-16 9.1-29.8 16.9-41.1 22-30.7 14-57.1 26.2-81.4 26.2-10.6 0-18.5-3-23.8-9.3-9.5-11-9.3-29.7-6.1-54.3 3.7-28.4.1-50.5-9.7-61.3-6-6.5-14.5-9.3-25.5-8.6-27.8 1.6-76.6 39-168.7 129.1l-27.4 26.9L181 175.9c13.2-33.5 4-70.1-23.3-93.1-21.8-18.4-58.8-29.2-97.7-4L4 117.1c-4 2.6-5.1 7.8-2.7 11.6L18.9 157c1.2 1.9 3 3.2 5.2 3.7 2.1.4 4.3.1 6.2-1.1L89.6 119c5.4-3.4 11.2-5.1 17-5.1 7 0 13.9 2.5 19.7 7.4 10.6 9 14.2 23.1 9.1 36.1L34.6 413.6c-2.9 7.3-1.7 17.3 3 24.3 3.1 4.6 9 10.1 19.9 10.1 6.6 0 12.8-2.6 17.4-7.3 43.5-44.2 158.5-157.2 217.3-205l14.8-12-1.5 19.2c-2.1 27.9-2.5 57.2 19 81.2 14.1 15.7 34.7 23.7 61.2 23.7 34.8 0 67.2-14.9 101.6-30.6 10.5-4.8 25-13.4 40.3-22.5 35.2-20.9 75.1-44.5 104.4-47 4.7-.4 8.1-3.8 8.1-8.2V206c-.1-2.3-1.1-4.6-2.9-6.2z"/></svg>',
      url: "sservice-aenderung-persoenlichedaten",
      conditionHide: ["ansprechpartner"],
    },
    AenderungAnsprechpartnerdaten: {
      title: "Änderung Ansprechpartnerdaten",
      image: "assets/img/pensus-persoenlichedaten-self-service.webp",
      icon: '<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512"><path d="M637.2 199.8c-.9-.9-3-2.5-5.7-2.2-36.2 2.4-84.6 29.9-123.4 51.9-16 9.1-29.8 16.9-41.1 22-30.7 14-57.1 26.2-81.4 26.2-10.6 0-18.5-3-23.8-9.3-9.5-11-9.3-29.7-6.1-54.3 3.7-28.4.1-50.5-9.7-61.3-6-6.5-14.5-9.3-25.5-8.6-27.8 1.6-76.6 39-168.7 129.1l-27.4 26.9L181 175.9c13.2-33.5 4-70.1-23.3-93.1-21.8-18.4-58.8-29.2-97.7-4L4 117.1c-4 2.6-5.1 7.8-2.7 11.6L18.9 157c1.2 1.9 3 3.2 5.2 3.7 2.1.4 4.3.1 6.2-1.1L89.6 119c5.4-3.4 11.2-5.1 17-5.1 7 0 13.9 2.5 19.7 7.4 10.6 9 14.2 23.1 9.1 36.1L34.6 413.6c-2.9 7.3-1.7 17.3 3 24.3 3.1 4.6 9 10.1 19.9 10.1 6.6 0 12.8-2.6 17.4-7.3 43.5-44.2 158.5-157.2 217.3-205l14.8-12-1.5 19.2c-2.1 27.9-2.5 57.2 19 81.2 14.1 15.7 34.7 23.7 61.2 23.7 34.8 0 67.2-14.9 101.6-30.6 10.5-4.8 25-13.4 40.3-22.5 35.2-20.9 75.1-44.5 104.4-47 4.7-.4 8.1-3.8 8.1-8.2V206c-.1-2.3-1.1-4.6-2.9-6.2z"/></svg>',
      url: "sservice-aenderung-ansprechpartnerdaten",
      conditionHide: ["person"],
    },
    AenderungFirmendaten: {
      title: "Änderung Firmenstammdaten",
      image: "assets/img/pensus-bankverbindung-self-service.webp",
      icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M192 107v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12zm116-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12zm-128 96h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12zm128 0h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12zm-128 96h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12zm128 0h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12zm140 205v20H0v-20c0-6.6 5.4-12 12-12h20V24C32 10.7 42.7 0 56 0h336c13.3 0 24 10.7 24 24v456h20c6.6 0 12 5.4 12 12zm-64-12V32H64v448h128v-85c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v85h128z"/></svg>',
      url: "sservice-aenderung-firmendaten",
      conditionHide: ["person", "firmaNotSelected"],
    },
    AenderungFirmenAdressdaten: {
      title: "Änderung Firmen-Adressdaten",
      image: "assets/img/pensus-firmenadresse-self-service.webp",
      icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M560 160c-2 0-4 .4-6 1.2L384 224l-10.3-3.6C397 185.5 416 149.2 416 123 416 55 358.7 0 288 0S160 55.1 160 123c0 11.8 4 25.8 10.4 40.6L20.1 216C8 220.8 0 232.6 0 245.7V496c0 9.2 7.5 16 16 16 2 0 4-.4 6-1.2L192 448l172 60.7c13 4.3 27 4.4 40 .2L555.9 456c12.2-4.9 20.1-16.6 20.1-29.7V176c0-9.2-7.5-16-16-16zM176 419.8L31.9 473l-1.3-226.9L176 195.6zM288 32c52.9 0 96 40.8 96 91 0 27-38.1 88.9-96 156.8-57.9-67.9-96-129.8-96-156.8 0-50.2 43.1-91 96-91zm-80 387.7V228.8c24.4 35.3 52.1 68 67.7 85.7 3.2 3.7 7.8 5.5 12.3 5.5s9-1.8 12.3-5.5c12.8-14.5 33.7-39.1 54.3-66.9l13.4 4.7v223.9zm192 56.7V252.2L544.1 199l1.3 226.9z"/></svg>',
      url: "sservice-aenderung-firmenadressdaten",
      conditionHide: ["person", "firmaNotSelected"],
    },
    AenderungFirmenBankdaten: {
      title: "Änderung Firmenbankdaten",
      image: "assets/img/pensus-beitragsfreistellung-self-service.webp",
      icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M432 256c-8.8 0-16 7.2-16 16s7.2 16 16 16 16-7.2 16-16-7.2-16-16-16zm128-32h-29.5c-11.1-25.3-28.7-46.9-50.5-63.4V96h-16c-30.3 0-57.8 10.1-80.9 26.2 .4-3.4 .9-6.7 .9-10.2C384 50.1 333.9 0 272 0S160 50.1 160 112c0 9.7 1.6 18.9 4 27.9C115 159.7 78 203 67.2 256H56c-14.8 0-26.5-13.5-23.5-28.8C34.7 215.8 45.4 208 57 208h1c3.3 0 6-2.7 6-6v-20c0-3.3-2.7-6-6-6-28.5 0-53.9 20.4-57.5 48.6C-3.9 258.8 22.7 288 56 288h8c0 52.2 25.4 98.1 64 127.3V496c0 8.8 7.2 16 16 16h112c8.8 0 16-7.2 16-16v-48h64v48c0 8.8 7.2 16 16 16h112c8.8 0 16-7.2 16-16v-80.9c11.7-9 22.4-19.3 31.3-31.1H560c8.8 0 16-7.2 16-16V240c0-8.8-7.2-16-16-16zM272 32c44.2 0 80 35.8 80 80 0 5.5-.6 10.8-1.6 16H224c-10.1 0-19.9 1.2-29.5 3-1.6-6.1-2.5-12.4-2.5-19 0-44.2 35.8-80 80-80zm272 320h-48.7c-17 22.4-17 24.1-47.3 47.3V480h-80v-64H240v64h-80v-80.7c-64.9-49-64-93.5-64-111.3 0-70.6 57.4-128 128-128h161.7c16.9-13.5 33.2-26.5 62.3-30.8v47.3c39.7 30.1 44 39.3 61.6 79.5H544v96z"/></svg>',
      url: "sservice-aenderung-firmenbankdaten",
      conditionHide: ["person", "firmaNotSelected"],
    },
    AenderungFirmenFinanzamtsdaten: {
      title: "Änderung Finanzamtsdaten",
      image: "assets/img/pensus-finanzamt-self-service.webp",
      icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M560 0c-8.8 0-16 7.2-16 16v64h-64V64c0-17.7-14.3-32-32-32h-32c-17.7 0-32 14.3-32 32v16h-96V64c0-17.7-14.3-32-32-32h-32c-17.7 0-32 14.3-32 32v16h-32V64c0-17.7-14.3-32-32-32H96c-17.7 0-32 14.3-32 32v16H32V16c0-8.8-7.2-16-16-16S0 7.2 0 16v488c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8v-72h32v16c0 17.7 14.3 32 32 32h32c17.7 0 32-14.3 32-32v-16h32v16c0 17.7 14.3 32 32 32h32c17.7 0 32-14.3 32-32v-16h96v16c0 17.7 14.3 32 32 32h32c17.7 0 32-14.3 32-32v-16h64v72c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8V16c0-8.8-7.2-16-16-16zM416 64h32v64h-32V64zm-192 0h32v64h-32V64zM96 64h32v64H96V64zm32 384H96v-64h32v64zm128 0h-32v-64h32v64zm192 0h-32v-64h32v64zm96-48h-64v-16c0-17.7-14.3-32-32-32h-32c-17.7 0-32 14.3-32 32v16h-96v-16c0-17.7-14.3-32-32-32h-32c-17.7 0-32 14.3-32 32v16h-32v-16c0-17.7-14.3-32-32-32H96c-17.7 0-32 14.3-32 32v16H32V272h32v16c0 17.7 14.3 32 32 32h32c17.7 0 32-14.3 32-32v-16h32v16c0 17.7 14.3 32 32 32h32c17.7 0 32-14.3 32-32v-16h32v16c0 17.7 14.3 32 32 32h32c17.7 0 32-14.3 32-32v-16h128v128zM96 288v-64h32v64H96zm128 0v-64h32v64h-32zm128 0v-64h32v64h-32zm192-48H416v-16c0-17.7-14.3-32-32-32h-32c-17.7 0-32 14.3-32 32v16h-32v-16c0-17.7-14.3-32-32-32h-32c-17.7 0-32 14.3-32 32v16h-32v-16c0-17.7-14.3-32-32-32H96c-17.7 0-32 14.3-32 32v16H32V112h32v16c0 17.7 14.3 32 32 32h32c17.7 0 32-14.3 32-32v-16h32v16c0 17.7 14.3 32 32 32h32c17.7 0 32-14.3 32-32v-16h96v16c0 17.7 14.3 32 32 32h32c17.7 0 32-14.3 32-32v-16h64v128z"/></svg>',
      url: "sservice-aenderung-finanzamtsdaten",
      conditionHide: ["person", "firmaNotSelected"],
    },
    AenderungBankverbindung: {
      title: "Änderung Bankverbindung",
      image: "assets/img/pensus-bankverbindung-self-service.webp",
      icon: '<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><path d="M432 256c-8.8 0-16 7.2-16 16s7.2 16 16 16 16-7.2 16-16-7.2-16-16-16zm128-32h-29.5c-11.1-25.3-28.7-46.9-50.5-63.4V96h-16c-30.3 0-57.8 10.1-80.9 26.2.4-3.4.9-6.7.9-10.2C384 50.1 333.9 0 272 0S160 50.1 160 112c0 9.7 1.6 18.9 4 27.9C115 159.7 78 203 67.2 256H56c-14.8 0-26.5-13.5-23.5-28.8C34.7 215.8 45.4 208 57 208h1c3.3 0 6-2.7 6-6v-20c0-3.3-2.7-6-6-6-28.5 0-53.9 20.4-57.5 48.6C-3.9 258.8 22.7 288 56 288h8c0 52.2 25.4 98.1 64 127.3V496c0 8.8 7.2 16 16 16h112c8.8 0 16-7.2 16-16v-48h64v48c0 8.8 7.2 16 16 16h112c8.8 0 16-7.2 16-16v-80.9c11.7-9 22.4-19.3 31.3-31.1H560c8.8 0 16-7.2 16-16V240c0-8.8-7.2-16-16-16zM272 32c44.2 0 80 35.8 80 80 0 5.5-.6 10.8-1.6 16H224c-10.1 0-19.9 1.2-29.5 3-1.6-6.1-2.5-12.4-2.5-19 0-44.2 35.8-80 80-80zm272 320h-48.7c-17 22.4-17 24.1-47.3 47.3V480h-80v-64H240v64h-80v-80.7c-64.9-49-64-93.5-64-111.3 0-70.6 57.4-128 128-128h161.7c16.9-13.5 33.2-26.5 62.3-30.8v47.3c39.7 30.1 44 39.3 61.6 79.5H544v96z"/></svg>',
      url: "sservice-aenderung-bankverbindung",
      conditionHide: ["ansprechpartner", "notRentner"],
    },
    ErfassungSteuerId: {
      title: "Erfassung Steuer-ID",
      image: "assets/img/pensus-steuerid-self-service.webp",
      icon: '<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><path d="M360 320h112c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8H360c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8zm0-64h112c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8H360c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8zm0 128h112c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8H360c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8zm-168-32c44.2 0 80-35.8 80-80s-35.8-80-80-80-80 35.8-80 80 35.8 80 80 80zm0-128c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zM512 32H64C28.7 32 0 60.7 0 96v320c0 35.3 28.7 64 64 64h448c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64zM272 448H112v-15.1c0-7 2.1-13.8 6-19.6 5.6-8.3 15.8-13.2 27.3-13.2 12.4 0 20.8 7 46.8 7 25.9 0 34.3-7 46.8-7 11.5 0 21.7 5 27.3 13.2 3.9 5.8 6 12.6 6 19.6V448h-.2zm272-32c0 17.6-14.4 32-32 32H304v-15.1c0-13.9-4.2-26.8-11.4-37.5-12.1-17.9-32.7-27.4-53.8-27.4-19.5 0-24.4 7-46.8 7s-27.3-7-46.8-7c-21.2 0-41.8 9.4-53.8 27.4C84.2 406.1 80 419 80 432.9V448H64c-17.6 0-32-14.4-32-32V160h512v256zm0-288H32V96c0-17.6 14.4-32 32-32h448c17.6 0 32 14.4 32 32v32z"/></svg>',
      url: "sservice-erfassung-steuerid",
      conditionHide: ["ansprechpartner", "Steuernummer"],
    },
    AenderungKontaktdaten: {
      title: "Änderung Kontaktdaten",
      image: "assets/img/pensus-kontaktdaten-aendern-self-service.webp",
      icon: '<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512"><path d="M358.9 433.3l-6.8 61c-1.1 10.2 7.5 18.8 17.6 17.6l60.9-6.8 137.9-137.9-71.7-71.7-137.9 137.8zM633 268.9L595.1 231c-9.3-9.3-24.5-9.3-33.8 0l-41.8 41.8 71.8 71.7 41.8-41.8c9.2-9.3 9.2-24.4-.1-33.8zM223.9 288c79.6.1 144.2-64.5 144.1-144.1C367.9 65.6 302.4.1 224.1 0 144.5-.1 79.9 64.5 80 144.1c.1 78.3 65.6 143.8 143.9 143.9zm-4.4-239.9c56.5-2.6 103 43.9 100.4 100.4-2.3 49.2-42.1 89.1-91.4 91.4-56.5 2.6-103-43.9-100.4-100.4 2.3-49.3 42.2-89.1 91.4-91.4zM134.4 352c14.6 0 38.3 16 89.6 16 51.7 0 74.9-16 89.6-16 16.7 0 32.2 5 45.5 13.3l34.4-34.4c-22.4-16.7-49.8-26.9-79.9-26.9-28.7 0-42.5 16-89.6 16-47.1 0-60.8-16-89.6-16C60.2 304 0 364.2 0 438.4V464c0 26.5 21.5 48 48 48h258.3c-3.8-14.6-2.2-20.3.9-48H48v-25.6c0-47.6 38.8-86.4 86.4-86.4z"/></svg>',
      url: "sservice-aenderung-kontaktdaten",
      conditionHide: ["ansprechpartner"],
    },
    AntragBetriebsrente: {
      title: "Antrag Betriebsrente",
      image: "assets/img/pensus-auszahlung-self-service.webp",
      icon: '<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M336 32c-48.6 0-92.6 9-124.5 23.4-.9.4-51.5 21-51.5 56.6v16.7C76.1 132.2 0 163.4 0 208v192c0 44.2 78.8 80 176 80s176-35.8 176-80v-16.4c89.7-3.7 160-37.9 160-79.6V112c0-37-62.1-80-176-80zm-16 368c0 13.9-50.5 48-144 48S32 413.9 32 400v-50.1c31.8 20.6 84.4 34.1 144 34.1s112.2-13.5 144-34.1V400zm0-96c0 13.9-50.5 48-144 48S32 317.9 32 304v-50.1c31.8 20.6 84.4 34.1 144 34.1s112.2-13.5 144-34.1V304zm-144-48c-81 0-146.7-21.5-146.7-48S95 160 176 160s146.7 21.5 146.7 48S257 256 176 256zm304 48c0 13.1-45 43.6-128 47.3v-64.1c52.8-2.2 99.1-14.6 128-33.3V304zm0-96c0 13.1-45.1 43.4-128 47.2V208c0-5.6-1.7-11-4.1-16.3 54.6-1.7 102.4-14.5 132.1-33.8V208zm-144-48c-7.3 0-14-.5-20.9-.9-36.9-21.7-85-28.2-115.6-30-6.3-5.3-10.1-11-10.1-17.1 0-26.5 65.7-48 146.7-48s146.7 21.5 146.7 48S417 160 336 160z"/></svg>',
      url: "sservice-antrag-betriebsrente",
      //conditionHide: ["notAnwaerter"],
      conditionHide: ["notAnwaerter","ansprechpartner", "notVerbotFirma"], // Freischalten nur für Testzwecke
    },
    AenderungKrankenkasse: {
      title: "Antrag Änderung der Krankenkasse",
      image: "assets/img/pensus-krankenkasse-self-service.webp",
      icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M336 64h-88.6c.4-2.6 .6-5.3 .6-8 0-30.9-25.1-56-56-56s-56 25.1-56 56c0 2.7 .2 5.4 .6 8H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48zM192 32c13.3 0 24 10.7 24 24s-10.7 24-24 24-24-10.7-24-24 10.7-24 24-24zm160 432c0 8.8-7.2 16-16 16H48c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16h48v20c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12V96h48c8.8 0 16 7.2 16 16v352zm-72-176h-56v-56c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v56h-56c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h56v56c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8v-56h56c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8z"/></svg>',
      url: "sservice-antrag-aenderungkrankenkasse",
      conditionHide: ["ansprechpartner"],
    },
    KontaktPensus: {
      title: "Kontakt zu Pensus",
      image: "",
      icon: '<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M336 137.4c-12.5-12.5-32.8-12.5-45.2 0l-126.1 126c-2 2-3.4 4.5-4.2 7.3l-16 61.2c-1.4 5.5.1 11.3 4.2 15.4 3 3 7.1 4.7 11.3 4.7 1.3 0 2.7-.2 4-.5l61.2-16c2.8-.7 5.3-2.2 7.3-4.2l126.1-126.1c12.5-12.5 12.5-32.8 0-45.2L336 137.4zM213 305.6l-30.6 8 8-30.6 75-75 22.6 22.6-75 75zm97.6-97.6L288 185.4l25.4-25.4 22.6 22.6-25.4 25.4zM256 32C114.6 32 0 125.1 0 240c0 47.6 19.9 91.2 52.9 126.3C38 405.7 7 439.1 6.5 439.5c-6.6 7-8.4 17.2-4.6 26S14.4 480 24 480c61.5 0 110-25.7 139.1-46.3C192 442.8 223.2 448 256 448c141.4 0 256-93.1 256-208S397.4 32 256 32zm0 384c-28.3 0-56.3-4.3-83.2-12.8l-15.2-4.8-13 9.2c-23 16.3-58.5 35.3-102.6 39.6 12-15.1 29.8-40.4 40.8-69.6l7.1-18.7-13.7-14.6C47.3 313.7 32 277.6 32 240c0-97 100.5-176 224-176s224 79 224 176-100.5 176-224 176z"/></svg>',
      url: "kontaktformular",
      conditionHide: [],
    },

    AntragVorausberechnung: {
      title: "Antrag Vorausberechnung",
      image: "assets/img/pensus-vorausberechnung-self-service.webp",
      icon: "",
      url: "sservice-antrag-vorausberechnung",
      conditionHide: ["goLive", "ansprechpartner"],
    },
    AenderungBeitrag: {
      title: "Änderung Beitrag",
      image: "assets/img/pensus-beitrag-self-service.webp",
      icon: '<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 480 512"><path d="M72 160h112c4.42 0 8-3.58 8-8v-16c0-4.42-3.58-8-8-8H72c-4.42 0-8 3.58-8 8v16c0 4.42 3.58 8 8 8zm5.09 247.6l11.31 11.31c3.12 3.12 8.19 3.12 11.31 0L128 390.63l28.29 28.29c3.12 3.12 8.19 3.12 11.31 0l11.31-11.31c3.12-3.12 3.12-8.19 0-11.31L150.63 368l28.29-28.29c3.12-3.12 3.12-8.19 0-11.31l-11.31-11.31c-3.12-3.12-8.19-3.12-11.31 0L128 345.37l-28.29-28.29c-3.12-3.12-8.19-3.12-11.31 0L77.09 328.4c-3.12 3.12-3.12 8.19 0 11.31L105.37 368l-28.29 28.28a8.006 8.006 0 0 0 .01 11.32zM448 16H32C14.33 16 0 30.33 0 48v416c0 17.67 14.33 32 32 32h416c17.67 0 32-14.33 32-32V48c0-17.67-14.33-32-32-32zM224 464H32V272h192v192zm0-224H32V48h192v192zm224 224H256V272h192v192zm0-224H256V48h192v192zm-152-80h40v40c0 4.42 3.58 8 8 8h16c4.42 0 8-3.58 8-8v-40h40c4.42 0 8-3.58 8-8v-16c0-4.42-3.58-8-8-8h-40V88c0-4.42-3.58-8-8-8h-16c-4.42 0-8 3.58-8 8v40h-40c-4.42 0-8 3.58-8 8v16c0 4.42 3.58 8 8 8zm0 256h112c4.42 0 8-3.58 8-8v-16c0-4.42-3.58-8-8-8H296c-4.42 0-8 3.58-8 8v16c0 4.42 3.58 8 8 8zm0-64h112c4.42 0 8-3.58 8-8v-16c0-4.42-3.58-8-8-8H296c-4.42 0-8 3.58-8 8v16c0 4.42 3.58 8 8 8z"/></svg>',
      url: "sservice-aenderung-beitrag",
      conditionHide: ["goLive", "ansprechpartner"],
    },
    Beitragsfreistellung: {
      title: "Beitragsfreistellung/entgeltfreie Zeit",
      image: "assets/img/pensus-beitragsfreistellung-self-service.webp",
      icon: "",
      url: "sservice-beitragsfreistellung",
      conditionHide: ["goLive", "ansprechpartner"],
    },
    Firmenaustritt: {
      title: "Firmenaustritt",
      image: "assets/img/pensus-firmenaustritt-self-service.webp",
      icon: "",
      url: "sservice-firmenaustritt",
      conditionHide: ["goLive", "ansprechpartner"],
    },
    Leistungsmeldung: {
      title: "Leistungsmeldung",
      image: "assets/img/pensus-leistungsmeldung-self-service.webp",
      icon: '<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512"><path d="M425.23 406.49A32.06 32.06 0 0 0 448 416h64a32 32 0 0 0 32-32v-64a32 32 0 0 0-9.5-22.76L246.68 12.07a41.15 41.15 0 0 0-58.24 0l-48.38 48.4A41.48 41.48 0 0 0 128 89.89 40.68 40.68 0 0 0 140.34 119zM289.46 100L512 320v64h-64L228.1 161.4l61.36-61.4zM162.69 83.09l48.39-48.4A9.21 9.21 0 0 1 217.6 32a9 9 0 0 1 6.45 2.69l44.53 44.54-61.36 61.38-44.53-44.54a9.18 9.18 0 0 1 0-12.98zM128 408a8 8 0 0 0 8 8h253.61l-31.71-32H136a8 8 0 0 0-8 8zm8-88h158.49l-31.71-32H136a8 8 0 0 0-8 8v16a8 8 0 0 0 8 8zm472-192H409.15l32.3 32H608v320H32V160h104l-18-18.12A73.25 73.25 0 0 1 107.13 128H32a32 32 0 0 0-32 32v320a32 32 0 0 0 32 32h576a32 32 0 0 0 32-32V160a32 32 0 0 0-32-32z"/></svg>',
      url: "sservice-leistungsmeldung",
      conditionHide: ["goLive", "ansprechpartner"],
    },
    Wiederinkraftsetzung: {
      title: "Wiederinkraftsetzung",
      image: "assets/img/pensus-wiederinkraftsetzung-self-service.webp",
      icon: "",
      url: "sservice-wiederinkraftsetzung",
      conditionHide: ["goLive", "ansprechpartner"],
    },
    TeilnahmeBeiratsversammlung: {
      title: "Teilnahme an Beiratsversammlung",
      image: "assets/img/pensus-beiratsversammlung-self-service.webp",
      icon: '<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512"><path d="M544 224c44.2 0 80-35.8 80-80s-35.8-80-80-80-80 35.8-80 80 35.8 80 80 80zm0-128c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zM320 256c61.9 0 112-50.1 112-112S381.9 32 320 32 208 82.1 208 144s50.1 112 112 112zm0-192c44.1 0 80 35.9 80 80s-35.9 80-80 80-80-35.9-80-80 35.9-80 80-80zm244 192h-40c-15.2 0-29.3 4.8-41.1 12.9 9.4 6.4 17.9 13.9 25.4 22.4 4.9-2.1 10.2-3.3 15.7-3.3h40c24.2 0 44 21.5 44 48 0 8.8 7.2 16 16 16s16-7.2 16-16c0-44.1-34.1-80-76-80zM96 224c44.2 0 80-35.8 80-80s-35.8-80-80-80-80 35.8-80 80 35.8 80 80 80zm0-128c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zm304.1 180c-33.4 0-41.7 12-80.1 12-38.4 0-46.7-12-80.1-12-36.3 0-71.6 16.2-92.3 46.9-12.4 18.4-19.6 40.5-19.6 64.3V432c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-44.8c0-23.8-7.2-45.9-19.6-64.3-20.7-30.7-56-46.9-92.3-46.9zM480 432c0 8.8-7.2 16-16 16H176c-8.8 0-16-7.2-16-16v-44.8c0-16.6 4.9-32.7 14.1-46.4 13.8-20.5 38.4-32.8 65.7-32.8 27.4 0 37.2 12 80.2 12s52.8-12 80.1-12c27.3 0 51.9 12.3 65.7 32.8 9.2 13.7 14.1 29.8 14.1 46.4V432zM157.1 268.9c-11.9-8.1-26-12.9-41.1-12.9H76c-41.9 0-76 35.9-76 80 0 8.8 7.2 16 16 16s16-7.2 16-16c0-26.5 19.8-48 44-48h40c5.5 0 10.8 1.2 15.7 3.3 7.5-8.5 16.1-16 25.4-22.4z"/></svg>',
      url: "sservice-teilnahme-beiratsversammlung",
      conditionHide: ["goLive", "ansprechpartner"],
    },
    Standmitteilung: {
      title: "Standmitteilung",
      image: "",
      icon: '<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M492 8h-10c-6.627 0-12 5.373-12 12v110.627C426.929 57.261 347.224 8 256 8 123.228 8 14.824 112.338 8.31 243.493 7.971 250.311 13.475 256 20.301 256h10.016c6.353 0 11.646-4.949 11.977-11.293C48.157 132.216 141.097 42 256 42c82.862 0 154.737 47.077 190.289 116H332c-6.627 0-12 5.373-12 12v10c0 6.627 5.373 12 12 12h160c6.627 0 12-5.373 12-12V20c0-6.627-5.373-12-12-12zm-.301 248h-10.015c-6.352 0-11.647 4.949-11.977 11.293C463.841 380.158 370.546 470 256 470c-82.608 0-154.672-46.952-190.299-116H180c6.627 0 12-5.373 12-12v-10c0-6.627-5.373-12-12-12H20c-6.627 0-12 5.373-12 12v160c0 6.627 5.373 12 12 12h10c6.627 0 12-5.373 12-12V381.373C85.071 454.739 164.777 504 256 504c132.773 0 241.176-104.338 247.69-235.493.339-6.818-5.165-12.507-11.991-12.507z"/></svg>',
      url: "",
      conditionHide: ["goLive", "ansprechpartner"],
    },
    Pensionsbescheid: {
      title: "Pensionsbescheid",
      image: "",
      icon: '<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M149.106 512c-33.076 0-66.153-12.59-91.333-37.771-50.364-50.361-50.364-132.305-.002-182.665L319.842 29.498c39.331-39.331 103.328-39.331 142.66 0 39.331 39.332 39.331 103.327 0 142.657l-222.63 222.626c-28.297 28.301-74.347 28.303-102.65 0-28.3-28.301-28.3-74.349 0-102.649l170.301-170.298c4.686-4.686 12.284-4.686 16.97 0l5.661 5.661c4.686 4.686 4.686 12.284 0 16.971l-170.3 170.297c-15.821 15.821-15.821 41.563.001 57.385 15.821 15.82 41.564 15.82 57.385 0l222.63-222.626c26.851-26.851 26.851-70.541 0-97.394-26.855-26.851-70.544-26.849-97.395 0L80.404 314.196c-37.882 37.882-37.882 99.519 0 137.401 37.884 37.881 99.523 37.882 137.404.001l217.743-217.739c4.686-4.686 12.284-4.686 16.97 0l5.661 5.661c4.686 4.686 4.686 12.284 0 16.971L240.44 474.229C215.26 499.41 182.183 512 149.106 512z"/></svg>',
      url: "",
      conditionHide: ["goLive", "ansprechpartner"],
    },
    PSVTestat: {
      title: "PSV-Testat",
      image: "",
      icon: '<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M466.5 83.7l-192-80a48.15 48.15 0 0 0-36.9 0l-192 80C27.7 91.1 16 108.6 16 128c0 198.5 114.5 335.7 221.5 380.3 11.8 4.9 25.1 4.9 36.9 0C360.1 472.6 496 349.3 496 128c0-19.4-11.7-36.9-29.5-44.3zM262.2 478.8c-4 1.6-8.4 1.6-12.3 0C152 440 48 304 48 128c0-6.5 3.9-12.3 9.8-14.8l192-80c3.9-1.6 8.4-1.6 12.3 0l192 80c6 2.5 9.9 8.3 9.8 14.8.1 176-103.9 312-201.7 350.8zm136.2-325c-4.7-4.7-12.3-4.7-17-.1L218 315.8l-69-69.5c-4.7-4.7-12.3-4.7-17-.1l-8.5 8.5c-4.7 4.7-4.7 12.3-.1 17l85.9 86.6c4.7 4.7 12.3 4.7 17 .1l180.5-179c4.7-4.7 4.7-12.3.1-17z"/></svg>',
      url: "",
      conditionHide: ["goLive", "ansprechpartner"],
    },
    SEPAMandat: {
      title: "SEPA-Mandat",
      image: "",
      icon: '<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><path d="M196.66 363.33l-13.88-41.62c-3.28-9.81-12.44-16.41-22.78-16.41s-19.5 6.59-22.78 16.41L119 376.36c-1.5 4.58-5.78 7.64-10.59 7.64H96c-8.84 0-16 7.16-16 16s7.16 16 16 16h12.41c18.62 0 35.09-11.88 40.97-29.53L160 354.58l16.81 50.48a15.994 15.994 0 0 0 14.06 10.89c.38.03.75.05 1.12.05 6.03 0 11.59-3.41 14.31-8.86l7.66-15.33c2.78-5.59 7.94-6.19 10.03-6.19s7.25.59 10.19 6.53c7.38 14.7 22.19 23.84 38.62 23.84H288c8.84 0 16-7.16 16-16s-7.16-16-16-16h-15.19c-4.28 0-8.12-2.38-10.16-6.5-11.93-23.85-46.24-30.33-65.99-14.16zM369.83 97.98L285.94 14.1c-9-9-21.2-14.1-33.89-14.1H47.99C21.5.1 0 21.6 0 48.09v415.92C0 490.5 21.5 512 47.99 512h287.94c26.5 0 48.07-21.5 48.07-47.99V131.97c0-12.69-5.17-24.99-14.17-33.99zM255.95 51.99l76.09 76.08h-76.09V51.99zM336 464.01H47.99V48.09h159.97v103.98c0 13.3 10.7 23.99 24 23.99H336v287.95zM88 112h80c4.42 0 8-3.58 8-8V88c0-4.42-3.58-8-8-8H88c-4.42 0-8 3.58-8 8v16c0 4.42 3.58 8 8 8zm0 64h80c4.42 0 8-3.58 8-8v-16c0-4.42-3.58-8-8-8H88c-4.42 0-8 3.58-8 8v16c0 4.42 3.58 8 8 8z"/></svg>',
      url: "",
      conditionHide: ["goLive", "ansprechpartner"],
    },
  },
  /**
   * FESTWERTE
   * Bei Anzeige beauftragter aber noch nicht abgerufener Änderungen werden die Feldnamen sofern hier angegeben durch den Kurztitel ersetzt.
   * Der Langtext wird bisher nicht genutzt
   */
  selfServiceFields: {
    ARBEITGEBER: {
      titleShort: "Arbeitgeber",
      titleLong: "Name des Arbeitgerbers",
    },
    BEMERKUNGEN: {
      titleShort: "Bemerkungen",
      titleLong: "Bemerkungen",
    },
    PRB_IBAN: {
      titleShort: "IBAN",
      titleLong: "IBAN",
    },
    PRB_BIC: {
      titleShort: "BIC",
      titleLong: "BIC",
    },
    PRB_BNAME: {
      titleShort: "Bankname",
      titleLong: "Name der Bank",
    },
    PRB_ABWNAME: {
      titleShort: "Kontoinhaber",
      titleLong: "Abweichender Name des Kontoinhabers",
    },
    PRB_LAND: {
      titleShort: "Land Bank",
      titleLong: "Landessitz der Bank",
    },
    EMAIL: {
      titleShort: "E-Mail",
      titleLong: "E-Mail Adresse",
    },
    PCONAME: {
      titleShort: "c/o Name",
      titleLong: "c/o Name",
    },
    PTELEFON: {
      titleShort: "Telefon",
      titleLong: "Telefon",
    },
    PHANDY: {
      titleShort: "Handy",
      titleLong: "Handynummer",
    },
    PADRTYP: {
      titleShort: "Adresstyp",
      titleLong: "Adresstyp",
    },
    PSTRASSE: {
      titleShort: "Straße / Hausnummer",
      titleLong: "Straße / Hausnummer",
    },
    PPLZ: {
      titleShort: "PLZ",
      titleLong: "PLZ",
    },
    PORT: {
      titleShort: "Ort",
      titleLong: "Ort",
    },
    PLZ: {
      titleShort: "Land",
      titleLong: "Land",
    },
    NAME: {
      titleShort: "Name",
      titleLong: "Name",
    },
    VORNAME: {
      titleShort: "Vorname",
      titleLong: "Vorname",
    },
    TITEL: {
      titleShort: "Titel",
      titleLong: "Titel",
    },
    NAMEVS: {
      titleShort: "Vorsatz Name",
      titleLong: "Vorsatz Name",
    },
    NAMEZUS: {
      titleShort: "Namenszusatz",
      titleLong: "Namenszusatz",
    },
    FAMSTD: {
      titleShort: "Familienstand",
      titleLong: "Familienstand",
    },
    STEUERIDNR: {
      titleShort: "Steuer-ID",
      titleLong: "Steuerliche Identifikationsnummer",
    },
    ANLIEGEN: {
      titleShort: "Ihr Anliegen",
      titleLong: "Ihr Anliegen",
    },
    GESCHL: {
      titleShort: "Geschlecht",
      titleLong: "Geschlecht",
    },
    BEITRAGSPFLICHTIG: {
      titleShort: "Beitragspflicht",
      titleLong: "Beitragspflicht",
    },
    KILO: {
      titleShort: "Status Kinder",
      titleLong: "Status Kinder",
    },
    uploadFolgt: {
      titleShort: "Nachreichen",
      titleLong: "Nachreichen eines Dokuments",
    },
    PENSART: {
      titleShort: "Pensionsart",
      titleLong: "Pensionsart",
    },
    KRANKENKASSE: {
      titleShort: "Krankenkasse",
      titleLong: "Krankenkasse",
    },
    PLKZ: {
      titleShort: "Nationalität",
      titleLong: "Nationalität",
    },
    GLKZ: {
      titleShort: "Land",
      titleLong: "Land",
    },
    PMNR: {
      titleShort: "PM-NR.",
      titleLong: "PM-NR.",
    },
    GEBDAT: {
      titleShort: "Geb.datum",
      titleLong: "Geburtsdatum",
    },
    EHEDAT: {
      titleShort: "Datum der Eheschließung",
      titleLong: "Datum der Eheschließung",
    },
    RENTENVERSICHERUNGSNUMMER: {
      titleShort: "Sozial- /Rentenversicherungsnummer",
      titleLong: "Sozial- /Rentenversicherungsnummer",
    },
    RENTENBEGINN: {
      titleShort: "Rentenbeginn",
      titleLong: "Rentenbeginn",
    },
    APTITEL: {
      titleShort: "Titel",
      titleLong: "Titel",
    },
    APVORNAME: {
      titleShort: "Vorname",
      titleLong: "Vorname",
    },
    APNAME: {
      titleShort: "Name",
      titleLong: "Name",
    },
    APGEBDAT: {
      titleShort: "Geb.datum",
      titleLong: "Geburtsdatum",
    },
    FNAME: {
      titleShort: "Firmenname",
      titleLong: "Firmenname",
    },
    GORT: {
      titleShort: "Firmenort",
      titleLong: "Firmenort",
    },
    GTELEFON: {
      titleShort: "Firmentelefonnummer",
      titleLong: "Firmentelefonnummer",
    },
    GEB_BNAME: {
      titleShort: "Bankname Firma",
      titleLong: "Bankname Firma",
    },
    GEB_LAND: {
      titleShort: "Banksitz Firma",
      titleLong: "Banksitz Firma",
    },
    GEB_ABWNAME: {
      titleShort: "Abweichender Konteninhaber Firma",
      titleLong: "Abweichender Konteninhaber Firma",
    },
    GEB_IBAN: {
      titleShort: "IBAN Firma",
      titleLong: "IBAN Firma",
    },
    GEB_BIC: {
      titleShort: "BIC Firma",
      titleLong: "BIC Firma",
    },
    APTELEFON: {
      titleShort: "Telefon",
      titleLong: "Telefon",
    },
    GTELEFON: {
      titleShort: "Telefon",
      titleLong: "Telefon",
    },
    GEB_EINZUG: {
      titleShort: "Einzug",
      titleLong: "Einzugsermächtigung",
    },
    GSTRASSE: {
      titleShort: "Straße",
      titleLong: "Straße",
    },
    GPLZ: {
      titleShort: "PLZ",
      titleLong: "Postleitzahl",
    },
    GORTZUS: {
      titleShort: "Ort-Zusatz",
      titleLong: "Ort-Zusatz",
    },
    GEMAIL: {
      titleShort: "E-Mail",
      titleLong: "E-Mail Adresse",
    },
    GFAX: {
      titleShort: "Fax",
      titleLong: "Fax-Nummer",
    },
    GPOSTPLZ: {
      titleShort: "PLZ Postfach",
      titleLong: "Postleitzahl Postfach",
    },
    GPOSTFACH: {
      titleShort: "Postfach",
      titleLong: "Postfach",
    },
    FSTEUNR: {
      titleShort: "Steuernr.",
      titleLong: "Steuernummer",
    },
    FGESFORM: {
      titleShort: "Ges.form",
      titleLong: "Gesellschaftsform",
    },
  },
});
</script>
<template></template>
