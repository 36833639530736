<script setup>
import HauptMenue from "../components/HauptMenue.vue";
import FooterMenu from "../components/FooterMenu.vue";
import DataDokumenteFirma from "../components/DataDokumenteFirma.vue";
</script>
<script>
import { store } from "../components/store.vue";
/** View-Komponente zur Anzeige aller Versorgungsdokumente zu einer Firma gegliedert nach PMNR
 * @displayName Seite Dokumente zur Firma
 */
export default {
  name: "Firmendokumente",
  components: {
    // myRouter: router,
    HauptMenue,
    FooterMenu,
    DataDokumenteFirma,
  },
  data() {
    return {
      store,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    window._paq.push(["setDocumentTitle", this.name]);
    window._paq.push(["trackPageView"]);
  },
  methods: {},
};
</script>
<template>
  <div class="main-wrapper flex-grow" :class="store.accessRestriction">
    <HauptMenue />
    <div class="mt-36 md:mt-48 lg:mt-56 max-w-5xl mx-auto px-5 lg:px-0 mb-32">
      <h1
        class="text-primary/75 font-bold text-2xl md:text-3xl lg:text-4xl text-center mb-12 uppercase">
        Dokumente für Firma {{ store.subData.data.Firmenstammdaten.FNAME }} {{ $servertalk.getName('GESFORM',store.subData.data.Firmenstammdaten?.FGESFORM) }} {{
          store.subData.data.Firmenstammdaten.FKENNR
        }}/{{ store.subData.data.Firmenstammdaten.FABRGR }}
      </h1>
      <div class="p-8 border rounded-md text-xs">
        Die Dokumente werden je nach Art nach 3 Jahren (jährlich) oder 12
        Monaten (monatlich) nicht weiter im Portal angezeigt. Bis zum Ablauf der
        jeweiligen gesetzlichen Aufbewahrungsfrist werden diese aber bei Pensus
        gespeichert.
      </div>
      <h2
        class="text-gray-700 mb-8 text-2xl font-bold pb-2 border-b border-primary mt-16">
        Übersicht der Firmendokumente</h2>
      <div v-if="typeof store.subData.data.Dokumente == 'object' && typeof store.subData.data.Dokumente.DOKUNR == 'object'">
        <div >
          <div>
            <DataDokumenteFirma  />
          </div>
        </div>
      </div>
      <div v-else>
        <p>
          Es wurden bislang keine Firmendokumente
          hinterlegt.
        </p>
      </div>
    </div>
  </div>
  <FooterMenu />
</template>
