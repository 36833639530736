<script setup>
import CustomSelect from "./CustomSelect.vue";
</script>
<script>
import { store } from "./store.vue";

/** Komponente zur Anzeige der Firmendaten ohne Änderungsmöglichkeit
 * @displayName Anzeige der Firmendaten
 */
export default {
  name: "DataFirmaTab",
  components: {},
  data() {
    return {
      statusCurrent: "999",
      meldung: "",
      store,
      sGesellschaftsformOptions: this.$servertalk.getSelectOpts("GESFORM"),
      sGesellschaftsform: {
        text: this.$servertalk.getName(
          "GESFORM",
          store.subData.data.Firmenstammdaten.FGESFORM
        ),
        key: store.subData.data.Firmenstammdaten.FGESFORM,
      },
    };
  },

  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    setMeldung(msg) {
      this.meldung = msg;
    },
    /* sendData(e) {
      // Click-Function auf Button
      if (e) e.preventDefault();
      this.statusCurrent = "999";
      this.setMeldung("starte");
      //console.log('abc sendData');
      this.$servertalk.sendDataToDB(this.$event, this.setStatus);
    },
    setStatus(status) {
      // Callback serverTalk erhält Status nach asynchroner Verarbeitung
      this.statusCurrent = status;
      //console.log('abc setStatus ' + status);
    }, */
  },
  computed: {
    statusModified: function () {
      return this.statusCurrent;
    },
    combinedFirmenstammdaten() {
      // Kombiniert FKENNR und FABRGR aus dem Store und trennt mit "/"
      const { FKENNR, FABRGR } = store.subData.data.Firmenstammdaten;
      return `${FKENNR || ""}/${FABRGR || ""}`;
    },
  },
  watch: {
    statusModified: function (neuerStatus) {
      //console.log('abc statusModified ' + neuerStatus);
      this.statusCurrent = neuerStatus;
    },
    statusCurrent: function (neuerStatus) {
      //console.log('abc statusCurrent ' + neuerStatus);
      let meldg = "Die Daten wurden erfolgreich übertragen.";
      if (neuerStatus != 200 && neuerStatus != 999) {
        meldg =
          "Bei der Datenübertragung ist ein Fehler aufgetreten. Status " +
          neuerStatus;
      }
      this.setMeldung(meldg);
      setTimeout(
        function (setMeldung) {
          //console.log('time');
          setMeldung("");
        },
        5000,
        this.setMeldung
      );
    },
  },
};
</script>
<template>
  <form class="schwebend">
    <div class="grid lg:grid-cols-12 sm:gap-5">
      <div class="relative lg:col-span-3">
        <label for="kennnr" class="text-sm mb-2 block">Kennnummer/Abrechnungsgruppe</label>
        <input
          maxlength="10"
          type="text"
          id="kennnr"
          class="w-full p-3 border border-secondary/50 rounded-md mb-8 focus:outline-0 focus:border-tertiary/50"
          required
          v-model="combinedFirmenstammdaten" />
      </div>
      <div class="relative lg:col-span-6">
        <label for="firmenname" class="text-sm mb-2 block">Firmenname</label>
        <input
          maxlength="100"
          type="text"
          id="firmenname"
          class="w-full p-3 border border-secondary/50 rounded-md mb-8 focus:outline-0 focus:border-tertiary/50"
          required
          v-model="store.subData.data.Firmenstammdaten.FNAME" />
      </div>
      <div class="relative lg:col-span-3">
        <label for="firmenname" class="text-sm mb-2 block">
          Gesellschaftsform
        </label>
        <CustomSelect
          @input="sGesellschaftsform"
          class="select"
          :options="sGesellschaftsformOptions"
          :default="store.subData.data.Firmenstammdaten.FGESFORM"
          :oldDefault="store.subData.oldData.Firmenstammdaten.FGESFORM" 
          placeholder="Keine" />
      </div>
    </div>
    <div class="grid lg:grid-cols-2 sm:gap-5">
      <div class="relative">
        <label for="email" class="text-sm mb-2 block">E-Mail Adresse</label>
        <input
          maxlength="255"
          type="email"
          id="email"
          inputmode="email"
          class="w-full p-3 border border-secondary/50 rounded-md mb-8 focus:outline-0 focus:border-tertiary/50 peer invalid:text-red-500 focus:invalid:border-red-500"
          v-model="store.subData.data.Geschaeftsadresse.GEMAIL" />
      </div>
      <div class="relative">
        <label for="aendTelefon" class="text-sm mb-2 block">Telefon</label>
        <input
          maxlength="100"
          type="text"
          id="aendTelefon"
          class="peer w-full p-3 border border-secondary/50 rounded-md mb-8 invalid:border-red-500 invalid:bg-red-50 focus:outline-0 peer invalid:text-red-500 focus:invalid:border-red-500"
          v-model="store.subData.data.Geschaeftsadresse.GTELEFON" />
        <span
          class="invisible peer-invalid:visible text-red-500 text-sm absolute bottom-2 left-0 min-w-max">
          Bitte geben Sie eine gültige Telefonnummer ein.
        </span>
      </div>
    </div>

    <router-link
      to="/sservice-aenderung-firmendaten"
      custom
      v-slot="{ navigate }">
      <button
        @click="navigate"
        role="link"
        type="submit"
        :disabled="
          store.subData.isReadOnly || store.selectedFirma?.VERMITTLER == 1
        "
        class="bg-primary text-white py-4 w-64 rounded-md text-lg lg:text-xl mx-auto block hover:bg-tertiary transition disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:bg-primary">
        Stammdaten ändern
      </button>
    </router-link>
  </form>
</template>
