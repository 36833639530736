<script>
// plugins/serverTalk.vue
//import VueCryptojs from 'vue-cryptojs'
import axios from "axios";
import { store } from "../components/store.vue";
import CryptoJS from "crypto-js";
//ahn##
import streamSaver from "./StreamSaver.js";
//import { store } from "./components/store.vue";
import { diffObj, textInTree, supplementObj } from "../utility";
//import router from '../router/index.js';


/** Server Talk
 * @displayName Server Talk
 */


export default {
  install: (app) => {
    // inject a globally available $servertalk() method
    app.config.globalProperties.$servertalk = {
      doLog: function () {
        if (store.isDebug) console.log(...arguments);
      },
      getDatenart: function () {
        return store.datenart;
      },

      globalHelper: function () {
        alert("Hello world");
      },
      getUUID: function () {
        return "u10000000-1000-4000-8000-100000000000".replace(/[018]/g, (c) =>
          (
            c ^
            (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
          ).toString(16)
        );
      },
      gettAttr: function (path, attrname = "Status") {
        //liefert den Wert eines Attributes aus dem Baum.
        //das letzte Attribute = name wird zurückgegeben
        //der Pfad wird als Text angegeben Bsp data.Stammdaten.Vorname
        this.doLog('gettAttr:' + path);
        let patharr = path.split("."); // z.B. "masterData.draft.sService.Entwuerfe.AenderungPersoenlichedaten.TITEL"
        let myatt = false;
        let mydata = store[patharr[0]][patharr[1]]; // z.B. Inhalt von "masterData.draft"
        let mydiffdata = store[patharr[0]][patharr[1] + "diff"]; // z.B. Inhalt von "masterData.draftdiff"
        //console.log(patharr[0]+'/'+patharr[1])
        //console.log(path);
        //schaue ob geändert
        //gehe in den Baum
        patharr.shift(); // Erstes Element ('masterData' o. 'subData') entfernen.
        patharr.shift(); // Erstes Element ('data' o. 'draft') entfernen.
        //console.log(patharr);
        //console.log(mydiffdata);
        //console.log('#subData');
        //console.log(store.subData);

        let treelen = patharr.length;
        patharr.forEach((el) => { //z.B. sService | Entwuerfe | AenderungPersoenlichedaten | TITEL
          if (el in mydiffdata) {
            mydiffdata = mydiffdata[el];
            //bin ich am letzten element?
            treelen--;
            let keys = Object.keys(mydiffdata);
            if (treelen == 0 && keys.length !== 0) {
              // Objekt existiert und ist nicht leer
              keys.forEach((key, index) => {
                // u.U. existiert hier ein (leeres?) Objekt wie bspw. für KINDER im Antrag auf Betriebsrente.
                let innerObjectKeys = Object.keys(mydiffdata[key]);
                if (
                  typeof mydiffdata[key] !== "object" ||
                  innerObjectKeys.length > 0
                ) {
                  myatt = "modified";
                }
              });
              //this.doLog(path + ': ' + myatt)
            }
          }
        });

        //gehe in den Baum
        patharr.forEach((el) => {
          if (mydata.length > 0 && el in mydata) {
            mydata = mydata[el];
            //hat es Attribute und hat es das Attribut attrname?
            if (
              typeof mydata === "object" &&
              mydata.hasOwnProperty("@attributes") &&
              attrname in mydata["@attributes"]
            ) {
              myatt = mydata["@attributes"][attrname];
            }
          } else {
            return myatt;
          }
        });
        return myatt;
      },
      gettModified: function (path) {
        //wurde der Wert im path verändert?
        //this.doLog('gettModified:' + path);
        let patharr = path.split(".");
        let mydata = store[patharr[0] + "diff"];
        patharr.shift(); // Erstes Element ('masterData' o. 'subData') entfernen.
        patharr.shift(); // Erstes Element ('data' o. 'draft') entfernen.
        //gehe in den Baum
        patharr.forEach((el) => {
          if (el in mydata) {
            mydata = mydata[el];
          } else {
            return false;
          }
        });
        return true;
      },
      gettValue: function (path) {
        //gebe den alten Wert aus path zurück
        let patharr = path.split("."); // z.B. "masterData.draft.sService.Entwuerfe.AenderungPersoenlichedaten.TITEL"
        let mydata = store[patharr[0]][patharr[1]]; // z.B. Inhalt von "masterData.draft"

        patharr.shift(); // Erstes Element ('masterData' o. 'subData') entfernen.
        patharr.shift(); // Erstes Element ('data' o. 'draft') entfernen.
        let n = patharr.length;
        let v = false;
        //this.doLog(path);
        //this.doLog(mydata);
        //patharr.shift(); // Erstes Element ('data' o. 'draft') entfernen.
        //gehe in den Baum
        patharr.forEach((el) => {
          n--;
          //this.doLog(n+' '+el);
          //this.doLog(mydata);

          if (el in mydata) {
            mydata = mydata[el];
            if (n == 0) {
              //this.doLog('##### '+mydata)
              v = mydata;
            }
          }
        });
        return v;
      },
      gettOldValue: function (path) {
        //gebe den alten Wert aus path zurück
        let patharr = path.split("."); // z.B. "masterData.draft.sService.Entwuerfe.AenderungPersoenlichedaten.TITEL"
        let mydata = store[patharr[0]][patharr[1] + "diff"];
        patharr.shift(); // Erstes Element ('masterData' o. 'subData') entfernen.
        patharr.shift(); // Erstes Element ('data' o. 'draft') entfernen.
        //gehe in den Baum
        patharr.forEach((el) => {
          if (el in mydata) {
            mydata = mydata[el];
          } else {
            return mydata.toString();
          }
        });
        return true;
      },
      getIndex: function (tabname, iname) {
        //gibt die Tabelle mit dem Namen zurück
        //suche die Tabelle
        let temp = store.indextable.TAB.find(
          (tab) => tab["@attributes"]["NAME"] == tabname
        )["KEY"];
        let tindex = "";
        try {
          tindex = temp.find((tab) => tab["KEY_TXTL"] == iname)["KEY_NR"];
        } catch (error) {
          tindex = "-1";
        }
        //suche den passenden String und gib den Index zurück
        return tindex;
      },
      getName: function (tabname, ikey, textk = false) {
        //gibt die Tabelle mit dem Namen zurück
        //suche die Tabelle
        if (store.auth) {
          let temp = store.indextable.TAB.find(
            (tab) => tab["@attributes"]["NAME"] == tabname
          )["KEY"];
          let tname = "";
          //suche den passenden Index und gib den Name zurück
          try {
            tname = textk
              ? temp.find((tab) => tab["KEY_NR"] == ikey)["KEY_TXTL"]
              : temp.find((tab) => tab["KEY_NR"] == ikey)["KEY_TXTK"];
          } catch (error) {
            //tname = 'nicht gefunden: ' + ikey + ' in ' + tabname;
            tname = "unbekannt";
          }
          return (typeof tname == 'string')?tname:'';
        } else {
          return "";
        }
      },

      getSelectOpts: function (iname, textk = false) {
        if (store.indextable && store.indextable.TAB) {
          const table = store.indextable.TAB.find(
            (tab) => tab["@attributes"]["NAME"] === iname
          );
          if (table && table["KEY"]) {
            if (textk) {
              return table["KEY"].map((tab) => ({
                text: (typeof tab["KEY_TXTK"] == 'string')?tab["KEY_TXTK"]:'',
                value: parseInt(tab["KEY_NR"]),
              }));
            } else {
              return table["KEY"].map((tab) => ({
                text: (typeof tab["KEY_TXTL"] == 'string')?tab["KEY_TXTL"]:'',
                value: parseInt(tab["KEY_NR"]),
              }));
            }
          } else {
            console.error(`Table or KEY not found for ${iname}`);
            return [];
          }
        } else {
          console.error("store.indextable or TAB is missing");
          return [];
        }
      },
      getVersorgung: function () {
        //gibt die Versorgungsnummern immer als Array zurück
        let vari = [];
        if (store.satzart == 'person') {
          if (Array.isArray(store.masterData.data?.Versorgung.PMNR)) {
            //es ist ein Array. Alles ok.            
            vari = store.masterData.data.Versorgung.PMNR;
          } else if (typeof store.masterData.data?.Versorgung.PMNR == "object") {
            //es ist ein Object. Dann zum Array hinzufügen
            //console.log('##Versorgung2')
            vari.push(store.masterData.data.Versorgung.PMNR);
          }
        }
        return vari;
      },

      getPMNROpts: function (status = "") {
        //gibt Options für ein CustomSelect aus
        /*
        const tab = [
          { status: '3', text: 'rentner' },
          { status: '1', text: 'aktiv' },
          { status: '14', text: 'anwaerter' },
          { status: '24', text: 'anwaerter' }
        ];
        */
        const tab = [];
        const rentner = this.getStatusRente();
        rentner.forEach((t) => {
          tab.push({ status: t, text: "rentner" });
        });
        const anwaerter = this.getStatusAnwaerter();
        anwaerter.forEach((t) => {
          tab.push({ status: t, text: "anwaerter" });
        });
        //status glatt ziehen
        status = status.toLowerCase();
        //Liste gewollter stati erzeugen. '' == alle!
        let PSTATUSNs = [];
        tab.forEach((t) => {
          if (t.text == status || status == "") PSTATUSNs.push(t.status);
        });

        //Versorgungsnummern holen
        let vari = this.getVersorgung();

        let result = [];
        //neutraler Eintrag
        let entry = { text: "Bitte wählen Sie", value: "0" };

        /*if (typeof (vari) === 'undefined' || typeof vari[0] !== 'object') {
          vari = store.masterData.data.Versorgung; // Bei nur einer PMNR ist das Objekt nicht Teil eines Arrays
        } else {
          result.push(entry);
        }*/

        const keys = Object.keys(vari);

        keys.forEach((key, index) => {
          // Key : '@attributes', 'PSTATUSN' ...
          if (PSTATUSNs.includes(vari[key]["PSTATUSN"])) {
            /*if ((status.toLowerCase() == 'rentner' && vari[key]['PSTATUSN'] === '3') ||
            (status.toLowerCase() == 'aktiv' && vari[key]['PSTATUSN'] === '1') ||
            (status.toLowerCase() == 'anwaerter' && vari[key]["PSTATUSN"] === '14' || vari[key]['PSTATUSN'] === '24') ||
            status == '') {*/
            let pmnr = vari[key]["@attributes"]["PMNR"];
            entry = { text: pmnr, value: pmnr };
            result.push(entry);
          }
        });
        return result;
      },
      loginSystem: function () {
        this.doLog("$servertalk.loginSystem");
        //zu tun nach erfolgreichem Login
        //JWT von local Storage nehmen. So kann kein 2. Tab mit dem JWT arbeiten

        localStorage.setItem("jwt", "");
      },
      enterSystem: function () {
        //zu tun nachdem das System läuft (vor dem Login)
        this.doLog("$servertalk.enterSystem");
        this.doLog(localStorage.getItem("jwt"));
        store.masterData.isReadOnly = false;

        //this.doLog(this.$router.fullPath );

        //JWT holen und entfernen
        let _jwt = localStorage.getItem("jwt");
        if (_jwt !== null && _jwt !== undefined && _jwt != "") {
          this.doLog(_jwt);
          try {
            this.doLog("try");
            store.jwt = _jwt; //JSON.parse(_jwt);
            this.doLog("try2");
            if (!this.checkJWT("l")) {
              this.doLog("try3");
              store.jwt = false;
            }
          } catch (error) {
            store.jwt = false;
            this.doLog("error JWT");
          }

          this.doLog("JWRSub: " + this.getJWT());
          this.doLog("Session: " + this.getSessionID());

          localStorage.setItem("jwt", "");
        }
        this.doLog(store.jwt);
        this.startPing();
      },
      logoutSystem: function () {
        this.doLog("$servertalk.logoutSystem");
        //zu tun nach dem LogOut
        if (store.masterData.datasent <= 0) {
          store.masterData.datasent = -1;
          this.doLog("set -1");
        }
        store.masterData.isReadOnly = false;
        //this.$router.push("/");
        let cookieAccepted = localStorage.getItem('GDPR_accepted'); // Muss true sein
        //local storage löschen
        localStorage.clear();
        //das JWT und GDPR_accepted aber speichern
        localStorage.setItem("jwt", store.jwt);
        localStorage.setItem('GDPR_accepted', cookieAccepted);
        store.identhash = "";

        window.location.href = "/";
      },
      exitSystem: function () {
        this.doLog("$servertalk.exitSystem");
        this.doLog(store.jwt);
        this.doLog(store.masterData.isReadOnly);
        //zu tun nach schließen von Tab/Browser
        if (store.jwt.length > 10 && !store.masterData.isReadOnly) {
          //wenn ich ein gültiges JWT habe
          localStorage.clear();
          //das JWT aber speichern
          this.doLog("store.jwt gespeichert");
          localStorage.setItem("jwt", store.jwt);
        }
      },

      saveStore: function () {
        if (store.masterData.data == {}) {
          // 3. Gleichheitszeichen entfernt wg. Fehlermeldung - Luce
          this.doLog(store.masterData.data);
          return false;
        } else {
          //alles in local storage packen
          localStorage.setItem("data", JSON.stringify(store.masterData.data));
          localStorage.setItem("oldData", JSON.stringify(store.masterData.oldData));
          localStorage.setItem("draft", JSON.stringify(store.masterData.draft));
          localStorage.setItem("olddraft", JSON.stringify(store.masterData.olddraft));
          localStorage.setItem("cdata", JSON.stringify(store.masterData.cdata));
          //localStorage.setItem("jwt", JSON.stringify(store.jwt));
          localStorage.setItem("key", JSON.stringify(store.key));
          localStorage.setItem("user", JSON.stringify(store.user));
          localStorage.setItem("passwd", JSON.stringify(store.passwd));
          localStorage.setItem(
            "decrypthash",
            JSON.stringify(store.decrypthash)
          );
          localStorage.setItem("identhash", JSON.stringify(store.identhash));
          localStorage.setItem("indextable", JSON.stringify(store.indextable));

          localStorage.setItem("birthday", JSON.stringify(store.birthday));
          localStorage.setItem("auth", JSON.stringify(store.auth));
          localStorage.setItem("docs", JSON.stringify(store.masterData.docs));
          localStorage.setItem("fontfactor", JSON.stringify(store.fontfactor));
          localStorage.setItem(
            "uploadFiles",
            JSON.stringify(store.uploadFiles)
          );
          localStorage.setItem(
            "accessRestriction",
            JSON.stringify(store.accessRestriction)
          );

          return true;
        }
      },
      restoreStore: function () {
        //hole Daten aus localStorage
        //this.doLog(this.data);
        //habe ich schon Daten? Nur holen, wenn nix da ist

        if (false && store.masterData.data.length === undefined) {
          //versuche Daten zu laden
          let data = localStorage.getItem("data");
          let oldData = localStorage.getItem("oldData");
          let draft = localStorage.getItem("draft");
          let olddraft = localStorage.getItem("olddraft");
          let birthday = localStorage.getItem("birthday");
          store.identhash = JSON.parse(localStorage.getItem("identhash"));
          //wenn das geklappt hat, dann hole auch den Rest
          if (data && this.checkJWT("l")) {
            this.doLog(data);
            store.masterData.data = JSON.parse(data);
            store.masterData.oldData = JSON.parse(oldData);
            if (draft) {
              store.masterData.draft = JSON.parse(draft);
              store.masterData.olddraft = JSON.parse(olddraft);
            }
            if (birthday) {
              store.birthday = JSON.parse(localStorage.getItem("birthday"));
              store.auth = JSON.parse(localStorage.getItem("auth"));
              store.masterData.docs = JSON.parse(localStorage.getItem("docs"));
              store.fontfactor = JSON.parse(localStorage.getItem("fontfactor"));
              store.uploadFiles = JSON.parse(
                localStorage.getItem("uploadFiles")
              );
              store.accessRestriction = JSON.parse(
                localStorage.getItem("accessRestriction")
              );
            }
            store.masterData.cdata = JSON.parse(localStorage.getItem("cdata"));
            store.jwt = JSON.parse(localStorage.getItem("jwt"));
            if (store.jwt == undefined || store.jwt == "") store.jwt = false;
            store.key = JSON.parse(localStorage.getItem("key"));
            store.user = JSON.parse(localStorage.getItem("user"));
            store.passwd = JSON.parse(localStorage.getItem("passwd"));
            store.decrypthash = JSON.parse(localStorage.getItem("decrypthash"));
            store.identhash = JSON.parse(localStorage.getItem("identhash"));
            store.indextable = JSON.parse(localStorage.getItem("indextable"));
            store.auth = true; //anmeldung hat geklappt
            this.doLog("lokale Daten gefunden");
            this.doLog(store.store);
            return true;
          } else {
            store.auth = false;
          }
          this.doLog("keine lokale Daten gefunden");
          return false;
        }
        this.doLog("Ich hatte doch schon Daten!");
        return true;
      },
      hashString(string) {
        return CryptoJS.SHA256(string).toString(CryptoJS.enc.Hex);
      },
      encryptString($string, $key, $iv) {
        const Utf8 = CryptoJS.enc.Utf8;
        const key = CryptoJS.SHA256($key)
          .toString(CryptoJS.enc.Hex)
          .substring(0, 32);
        let iv = CryptoJS.SHA256($iv)
          .toString(CryptoJS.enc.Hex)
          .substring(0, 16);
        const encrypt = CryptoJS.AES.encrypt(
          Utf8.parse($string),
          Utf8.parse(key),
          {
            iv: Utf8.parse(iv),
          }
        );
        return window.btoa(CryptoJS.enc.Base64.stringify(encrypt.ciphertext));
      },
      encryptBinary($data, $key, $iv) {
        const Utf8 = CryptoJS.enc.Utf8;
        const key = CryptoJS.SHA256($key)
          .toString(CryptoJS.enc.Hex)
          .substring(0, 32);
        let iv = CryptoJS.SHA256($iv)
          .toString(CryptoJS.enc.Hex)
          .substring(0, 16);
        const encrypt = CryptoJS.AES.encrypt($data, Utf8.parse(key), {
          iv: Utf8.parse(iv),
        });
        return window.btoa(CryptoJS.enc.Base64.stringify(encrypt.ciphertext));
      },
      decryptString($string, $key, $iv) {
        //console.log('decryptString',$string, $key, $iv);
        const Utf8 = CryptoJS.enc.Utf8;
        const $secret_key = $key;
        const $secret_iv = $iv;
        const key = CryptoJS.SHA256($secret_key)
          .toString(CryptoJS.enc.Hex)
          .substring(0, 32);
        let iv = CryptoJS.SHA256($secret_iv)
          .toString(CryptoJS.enc.Hex)
          .substring(0, 16);
        const encrypt = CryptoJS.enc.Base64.parse($string).toString(
          CryptoJS.enc.Utf8
        );
        const decrypt = CryptoJS.AES.decrypt(encrypt, Utf8.parse(key), {
          iv: Utf8.parse(iv),
        });
        //console.log(decrypt,decrypt.toString(CryptoJS.enc.Hex))
        //this.doLog('+'+decrypt+'+');
        return decrypt.toString(Utf8);
      },
      calcIdent(EMail, passwd) {
        //let _email = EMail.indexOf('#') != -1 ? EMail : EMail.toLowerCase();
        let _email = EMail.toLowerCase();
        this.doLog(_email + ":" + passwd + ":xxxx");
        if (_email == "" && passwd == "") return "-";
        return CryptoJS.SHA256(_email + ":" + passwd + ":xxxx").toString();
      },
      calcIdentCrypt(EMail, passwd) {
        //let _email = EMail.indexOf('#') != -1 ? EMail : EMail.toLowerCase();
        let _email = EMail.toLowerCase();
        return CryptoJS.SHA256(_email + ":" + passwd + ":yyyy").toString();
      },
      calcIdentXtra(EMail, BDate) {
        //let _email = EMail.indexOf('#') != -1 ? EMail : EMail.toLowerCase();
        let _email = EMail.toLowerCase();
        if (typeof BDate == 'undefined' || BDate == 'undefined') {
          BDate = '1960-01-01';
        }
        this.doLog(EMail + ":" + BDate);
        return CryptoJS.SHA256(_email + ":" + BDate).toString();
      },

      //Konvertierungsfunktionen
      convertBinaryStringToUint8Array(bStr) {
        let len = bStr.length,
          u8_array = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
          u8_array[i] = bStr.charCodeAt(i);
        }
        return u8_array;
      },

      convertUint8ArrayToBinaryString(u8Array) {
        var i,
          len = u8Array.length,
          b_str = "";
        for (i = 0; i < len; i++) {
          b_str += String.fromCharCode(u8Array[i]);
        }
        return b_str;
      },

      convertWordArrayToUint8Array(wordArray) {
        var len = wordArray.words.length,
          ulen = wordArray.sigBytes,
          u8_array = new Uint8Array(ulen),
          offset = 0,
          word,
          i;

        for (i = 0; i < len; i++) {
          word = wordArray.words[i];
          u8_array[offset++] = word >> 24;
          if (offset >= ulen) break;
          u8_array[offset++] = (word >> 16) & 0xff;
          if (offset >= ulen) break;
          u8_array[offset++] = (word >> 8) & 0xff;
          if (offset >= ulen) break;
          u8_array[offset++] = word & 0xff;
        }
        return u8_array;
      },

      convertUint8ArrayToWordArray(u8Array) {
        var words = [],
          i = 0,
          len = u8Array.length;
        while (i < len) {
          words.push(
            (u8Array[i++] << 24) |
            (u8Array[i++] << 16) |
            (u8Array[i++] << 8) |
            u8Array[i++]
          );
        }
        return {
          sigBytes: len,
          words: words,
        };
      },

      //will Base64
      //gibt Int8Array
      decryptStringToBa($string, $key, $iv) {
        let Utf8 = CryptoJS.enc.Utf8;
        const $secret_key = $key;
        const $secret_iv = $iv;
        const key = CryptoJS.SHA256($secret_key)
          .toString(CryptoJS.enc.Hex)
          .substring(0, 32);
        let iv = CryptoJS.SHA256($secret_iv)
          .toString(CryptoJS.enc.Hex)
          .substring(0, 16);
        const encrypt = window.atob($string);
        const decrypt = CryptoJS.AES.decrypt(encrypt, Utf8.parse(key), {
          iv: Utf8.parse(iv),
        });
        return this.convertWordArrayToUint8Array(decrypt);
      },

      //Masterfunktion. Holt Datei

      getDBFile(docinfo, dataSet = store.masterData) {
        //'file.pdf',p.hmac,p.chunks
        //const url = 'cat.mp4'
        const fname =
          docinfo.document.FILENAME.split(".")[0] +
          "." +
          docinfo.document.TYP.toLowerCase();
        const url = "/app.php?doc=" + docinfo.hmac + "&nr=";
        let isMaster = (dataSet == store.masterData)
        //isMaster ? store.key : dataSet.cdata.keydata,
        let dkey = isMaster ? this.decryptString(
          store.key,
          store.decrypthash,
          dataSet.cdata.iv
        ) : dataSet.identData.key.toLowerCase();
        //ahn##
        //const streamSaver = {};
        streamSaver.mitm =
          //"https://pensus-dev.data-and-vision.de/dectest/mitm.html";
          "https://portal.pensus.de/dectest/mitm.html";
        const fileStream = streamSaver.createWriteStream(fname, {
          writableStrategy: undefined, // (optional)
          readableStrategy: undefined, // (optional)
        });

        this.doLog("filestream " + fileStream);
        window.writer = fileStream.getWriter();
        this.doLog("writer " + window.writer);

        //writer.write(convertBinaryStringToUint8Array('Test'));
        //writer.close();
        //die Datei Chunkweise abholen
        this.getFile(url, dkey, docinfo.chunks);
      },
      getFile(urln, dkey, imax, i = 1) {
        fetch(urln + i).then((res) => {
          //const readableStream = res.body

          //let utf8decoder = new TextDecoder();
          let chunk = [];
          let ivector = false;

          const reader = res.body.getReader();

          //function definition
          const pump = () =>
            reader.read().then((res) => {
              if (!res.done) {
                //console.log('pump ' + i + '/' + imax)

                //let x = new TextEncoder().encode(res.value +'### '+i+' ###');
                if (ivector === false) {
                  //hier steckt der ivector in den ersten 16 Zeichen
                  chunk.push(res.value.subarray(16));
                  ivector = this.convertUint8ArrayToBinaryString(
                    res.value.subarray(0, 16)
                  );
                } else {
                  chunk.push(res.value);
                }
                pump();
                //writer.write(x).then(pump)
              } else {
                //console.log(decryptStringToBa(chunk.join(''),dkey,iv));
                //console.log('#' + i +' i '+ivector);
                //console.log(decryptString(chunk.join(''),dkey,iv));
                let decs = [];
                let c = 0;
                for (c in chunk) {
                  decs.push(this.convertUint8ArrayToBinaryString(chunk[c]));
                  //window.writer.write(chunk[c]);
                }
                window.writer.write(
                  this.decryptStringToBa(decs.join(""), dkey, ivector)
                );
                i++;
                if (i <= imax) {
                  //console.log('close '+i+'/'+imax);
                  this.getFile(urln, dkey, imax, i);
                } else {
                  window.writer.close();
                }
              }
            });

          pump();
        });
      },
      sendDocToDB(dataBlob, docmeta, statusCallback = false) {
        if (store.masterData.isReadOnly) {
          this.doLog("ReadOnly!!");
          if (typeof statusCallback === "function") {
            // Callback-Funktion aufrufen zum Verarbeiten des Response-Status
            statusCallback(
              dataBlob,
              docmeta,
              "Daten nicht gesendet. ReadOnly Mode."
            );
          }
          return false;
        }
        this.doLog("upload");
        const today = new Date();
        let dkey = this.decryptString(
          store.key,
          store.decrypthash,
          store.masterData.cdata.iv
        );
        let docdata = docmeta;
        docmeta["@attributes"] = {
          Person: store.masterData.data["@attributes"].Person,
          Datenart: this.getDatenart(),
          Version: "0.0.1",
        };
        docmeta.Timestamp = today.toISOString();
        docmeta.FILENAME = "upload.DAT";
        docmeta.TYP = "unknown";

        if (dataBlob.blob instanceof File) {
          //wenn es ein File ist, dann nehme den Filenamen
          docdata.FILENAME = dataBlob.name;
          docdata.TYP = dataBlob.type;
        }

        let postdata = {
          ident: store.identhash,
          verb: "doc",
          hmac: docmeta.hmac,
          filesize: dataBlob.size,
          data: this.encryptString(
            JSON.stringify(docdata),
            dkey,
            store.masterData.cdata.iv
          ),
          //data: this.encryptString(JSON.stringify(docdata),dkey,store.masterData.cdata.iv ),
          ivector: store.masterData.cdata.iv,
        };
        //$dbh->execute(['ident' => $jdata->ident, 'status' => 2,'data' => $jdata->data, 'ivector' => $jdata->ivector, 'hmac' => $jdata->hmac, 'filesize' => $jdata->filesize]);

        this.doLog(postdata);
        axios
          .post("/app.php", postdata, {
            headers:
              store.jwt > ""
                ? {
                  AUTHORIZATION: "Bearer " + store.jwt,
                }
                : {},
          })
          .then((response) => {
            if (typeof statusCallback === "function") {
              // Callback-Funktion aufrufen zum Verarbeiten des Response-Status
              if (response.status == 200) {
                statusCallback(dataBlob, docmeta, "Metadaten gesendet");
              } else {
                statusCallback(dataBlob, docmeta, "Probleme beim senden");
              }

              //dataBlob.uploadStatus = 'Metadaten gesendet'
            }
            //console.log(response.status);

            //so - die Datei Meta Infos sind nun beim Server. Nun folgen die Daten
            //diese Routine schickt den Blob stückweise (Chunk) zur DB
            //console.log(store.masterData.cdata.iv);
            this.sendFileToDB(
              postdata,
              dataBlob,
              docmeta,
              0,
              dkey,
              store.masterData.cdata.iv,
              statusCallback
            );
          });
      },
      sendFileToDB(
        docinfo,
        dataBlob,
        docmeta,
        CNummer,
        dkey,
        iv,
        statusCallback = false
      ) {
        if (store.masterData.isReadOnly) {
          this.doLog("ReadOnly!!");
          if (typeof statusCallback === "function") {
            // Callback-Funktion aufrufen zum Verarbeiten des Response-Status
            statusCallback(
              dataBlob,
              docmeta,
              "Daten nicht gesendet. ReadOnly Mode."
            );
          }
          return false;
        }
        //Rekursive asynchrone Routine
        let chunkSize = 100002;
        let start = CNummer * chunkSize;
        let FileSize = dataBlob.size;
        //der iv ist 16 Byte lang
        let myiv = iv.substring(0, 16);

        //dies speichert this zwischen, da dies sonst nicht innerhalb der promise erreichbar wäre
        let me = this;

        //nur reingehen, wenn noch was zu senden ist.
        if (start < FileSize) {
          //schneide auch dem Blob einen Chunk (Blob) aus
          const chunk = dataBlob.blob.slice(
            start,
            Math.min(start + chunkSize, FileSize)
          );

          this.doLog("chunk " + CNummer + " length " + chunk.size);

          //var wBuffer = [];

          //dieser liest den Blob asyncron ein
          var fileReader = new FileReader();

          //wird aufgerufen, wenn der Blob vollständig gelesen wurde, also nicht sofort.
          //Daten werden verarbeitet und der nächste Chunk in Auftrag gegeben
          fileReader.onload = function (event) {
            let uarray = new Uint8Array(event.target.result);
            //console.log(uarray.byteLength)
            let wBuffer = me.convertUint8ArrayToWordArray(uarray);

            //console.log('AB ' + CNummer + ' length ' + wBuffer.sigBytes + ' iv ' + myiv);
            let encstr = me.encryptBinary(wBuffer, dkey, myiv);
            //console.log(encstr.length);

            let postdata = {
              ident: docinfo.ident,
              verb: "chunk",
              hmac: docinfo.hmac,
              nr: CNummer + 1,
              //data:  arrayBuffer,// 'Dies ist ein leerer Text',
              //data:   'Dies ist ein leerer Text',
              //data: me.encryptString(arrayBuffer,)
              data: myiv + encstr,
            };

            axios
              .post("/app.php", postdata, {
                headers:
                  store.jwt > ""
                    ? {
                      AUTHORIZATION: "Bearer " + store.jwt,
                    }
                    : {},
              })
              .then((response) => {
                //console.log('Chunk Sended nr.:' + CNummer);
                //dataBlob.uploadStatus = (CNummer * 100000) +' Bytes übertragen' ;
                if (typeof statusCallback === "function") {
                  // Callback-Funktion aufrufen zum Verarbeiten des Response-Status
                  if (response.status == 200) {
                    statusCallback(
                      dataBlob,
                      docmeta,
                      "Sende ..".padEnd(CNummer, ".")
                    );
                  } else {
                    statusCallback(dataBlob, docmeta, "Probleme beim senden");
                  }

                  //dataBlob.uploadStatus = 'Metadaten gesendet'
                }
                me.sendFileToDB(
                  docinfo,
                  dataBlob,
                  docmeta,
                  CNummer + 1,
                  dkey,
                  encstr.substring(0, 16),
                  statusCallback
                );
              });
          };
          //fileReader.readAsText(chunk);
          fileReader.readAsArrayBuffer(chunk);
          //fileReader.readAsBinaryString(chunk);
        } else {
          statusCallback(dataBlob, docmeta, "hochgeladen");
        }
      },
      sendDataToDB(event, statusCallback = false, dataSet = store.masterData) {
        let isMaster = (dataSet == store.masterData)
        //Daten versenden
        if (dataSet.isReadOnly) {
          this.doLog("ReadOnly!!");
          if (typeof statusCallback === "function") {
            // Callback-Funktion aufrufen zum Verarbeiten des Response-Status
            statusCallback(403);
          }
          return false;
        }
        if (!store.auth) {
          this.doLog("notLoggedIn!!");
          if (typeof statusCallback === "function") {
            // Callback-Funktion aufrufen zum Verarbeiten des Response-Status
            statusCallback(403);
          }
          return false;
        }
        this.doLog("sende zum Server ...");
        //dkey zwischenspeichern
        let alias = false;
        let oldkey = "";
        let dkey = '';
        if (isMaster) {
          dkey = this.decryptString(
            store.key,
            store.decrypthash,
            dataSet.cdata.iv
          );
        } else {
          dkey = dataSet.identData.key.toLowerCase()
        }
        const today = new Date();

        //Schreibzugriff auf Store
        if (isMaster) dataSet.data.Stammdaten.Timestamp = today.toISOString();

        //es gibt ein neues Passwort!
        //dann muss das Ident geändert werden
        //und es wird neu verschlüsselt
        this.doLog(dataSet.datadiff);

        if (isMaster) {
          //gibt es ein neues Passwd?

          if (
            typeof store.newpasswd !== "undefined" &&
            store.newpasswd !== ""
          ) {
            store.passwd = store.newpasswd;
          }

          //passt der Hash noch?
          if (
            store.identhash !=
            this.calcIdent(dataSet.data.Stammdaten.EMAIL, store.passwd) &&
            store.passwd != ""
          ) {
            //Hash hat sich geändert!
            //alias ist der alte ident
            alias = store.identhash;
            //neuen ident berechnen

            //this.$CryptoJS.SHA256(store.user + ":" + store.passwd + ":xxxx").toString();

            //dies ist der Commit. Der alte Hash wird übrschrieben
            store.identhash = this.calcIdent(
              dataSet.data.Stammdaten.EMAIL,
              store.passwd
            );
            this.doLog("newpwd: " + store.newpasswd);
            this.doLog("user: " + dataSet.data.Stammdaten.EMAIL);
            //neuen dechash berechnen
            store.decrypthash = this.calcIdentCrypt(
              dataSet.data.Stammdaten.EMAIL,
              store.passwd
            );
            //Key neu verschlüsseln
            oldkey = store.key;
            store.key = this.encryptString(
              dkey,
              store.decrypthash,
              dataSet.cdata.iv
            );
            //der ident2 soll übertragen werden
            //Schreibzugriff auf Store
            dataSet.data["@attributes"].alias = alias;
            //Schreibzugriff auf Store
            //der ident2 soll übertragen werden
            //Schreibzugriff auf Store
            dataSet.data["@attributes"].ident2 = store.decrypthash;
            //der ident2 soll übertragen werden
            //Schreibzugriff auf Store
            dataSet.data["@attributes"].identextra = this.calcIdentXtra(
              dataSet.data.Stammdaten.EMAIL,
              dataSet.data.Stammdaten.GEBDAT
            );

            //hier wurden im Senden Daten geändert. Damit nicht doppelt gesendet wird:
            //dataSet.datasent = -1; // Aktiv in GPM - hier nicht??? s.petersen 
          }
        }

        this.doLog("Diff:");
        this.doLog(dataSet.datadiff);
        this.doLog("Draft:");
        this.doLog(dataSet.draft);
        //key >d33c64cf1861b793011fca1d7e26f8f5961c03e320cfa080bb84e0c2f3ca8870<
        //console.log('key >' + dkey + '<');

        let postdata = {
          ident: isMaster ? store.identhash : dataSet.identData.ident,
          alias: alias ? alias : "",
          oldkey: alias ? oldkey : "",
          verb: "data",
          identextra: isMaster ? this.calcIdentXtra(
            dataSet.data.Stammdaten.EMAIL,
            dataSet.data.Stammdaten.GEBDAT
          ) : '',
          //data :  this.decryptString( store.key,store.decrypthash,dataSet.cdata.iv)
          keydata: isMaster ? store.key : dataSet.cdata.keydata,
          ivector: dataSet.cdata.iv,
          data: this.encryptString(
            JSON.stringify(dataSet.data),
            dkey,
            dataSet.cdata.iv
          ),
          draft: this.encryptString(
            JSON.stringify(dataSet.draft),
            dkey,
            dataSet.cdata.iv
          ),
          olddraft: this.encryptString(
            JSON.stringify(dataSet.olddraft),
            dkey,
            dataSet.cdata.iv
          ),
          changes:
            Object.keys(dataSet.datadiff).length == 0
              ? JSON.stringify({})
              : this.encryptString(
                JSON.stringify(dataSet.datadiff),
                dkey,
                dataSet.cdata.iv
              ),
        };
        this.doLog(postdata);

        let sentJWT = isMaster ? store.jwt : dataSet.identData.jwt;

        axios
          .post("/app.php", postdata, {
            headers:
              sentJWT > ""
                ? {
                  AUTHORIZATION: "Bearer " + sentJWT,
                }
                : {},
          })
          .then((response) => {
            //das neue PWD ist nun das alte PWD
            //store.newpasswd = '';
            //dataSet.datasent = 0;

            if (response.status == 200 && response.data != "") {
              if (store.isDebug) console.log(response.data);
              if (response.data.jwt != undefined) store.jwt = response.data.jwt;
            }
            if (typeof statusCallback === "function") {
              // Callback-Funktion aufrufen zum Verarbeiten des Response-Status
              statusCallback(response.status);
            }
            if (store.isDebug) console.log(response.status);
            if (response.status == 409) {
              //conflict!!
              //sofortiger Ausstieg!
              localStorage.clear();
              store.jwt = false;
              store.identhash = "";
              window.location.href = "/";
            }
          });

        //sonst nix machen
        //event.preventDefault()
      },
      getSessionID() {
        let p = this.getJWT();
        if (p) {
          return p.sub.split(":")[3];
        } else {
          //console.log("keine SessionID");
          return false;
        }
      },
      getJWT(jwt = store.jwt) {
        if (!jwt) {
          return false;
        }
        try {
          let J = jwt.split(".");
          if (J.length === 3) {
            let payload = JSON.parse(window.atob(J[1]));
            let ts = (Date.now() + store.deltaT) / 1000; // sec, nicht msecS
            //console.log(ts);
            if (
              payload.iss == "PensusWeb" &&
              payload.exp > ts &&
              payload.iat < (ts + 300)
            ) {
              //console.log(payload.sub);
              return payload;
            } else {
              return false;
            }
          } else {
            return false;
          }
        } catch (error) {
          return false;
        }
      },
      checkJWT(right) {
        this.doLog("checkJWT");
        let payload = this.getJWT();

        this.doLog(payload);
        //this.doLog(this.getDatenart());
        if (
          !payload === false &&
          payload.sub.split(":")[0] == this.getDatenart() //dies blockt Logins mit falschen Server
        ) {
          let r = payload.sub.split(":")[2];
          let identH = payload.sub.split(":")[1];
          //let ident = this.calcIdent(store.user, store.passwd);
          this.doLog("Recht " + right + " in " + r + ", ident=" + identH);
          if (right == "l") {
            //login geht auch ohne passendes ident
            return r.indexOf(right) !== -1;
          } else {
            this.doLog("checkJWT(right) " + right);
            return (
              r.indexOf(right) !== -1 &&
              identH ==
              CryptoJS.SHA256(
                this.calcIdent(store.user, store.passwd) +
                ":" +
                this.getSessionID()
              ).toString()
            ); //payload.sub
          }
        }
        return false;
      },
      resetPass(email, bdate, statusCallback = false) {
        //fragt den Server, ob das Passwort schonmal verwendet wurde
        let xhash = this.calcIdentXtra(email, bdate);

        this.doLog("ResetPass " + email + " " + bdate + " " + xhash);

        axios
          .get("/app.php", {
            params: {
              passreset: xhash,
              email: email,
            },
            headers:
              store.jwt > ""
                ? {
                  AUTHORIZATION: "Bearer " + store.jwt,
                }
                : {},
          })
          .catch(function (error) {
            if (error.response) {
              if (typeof statusCallback === "function") {
                // Callback-Funktion aufrufen zum Verarbeiten des Response-Status
                statusCallback(error.response.status);
              }
            } else if (error.request) {
              if (typeof statusCallback === "function") {
                // Callback-Funktion aufrufen zum Verarbeiten des Response-Status
                statusCallback(error.request.status);
              }
            }
          })
          .then((response) => {
            //console.log(response.data);
            if (typeof statusCallback === "function") {
              // Callback-Funktion aufrufen zum Verarbeiten des Response-Status
              if (response === undefined || response.status === undefined)
                statusCallback(999);
              else statusCallback(response.status);
            }
          });
      },
      checkPass(passwd, newEMail, statusCallback = false) {
        //fragt den Server, ob das Passwort schonmal verwendet wurde
        //eventuell mit der nehen E-Mail
        let ihash =
          newEMail == ""
            ? this.calcIdent(store.masterData.data.Stammdaten.EMAIL, passwd)
            : this.calcIdent(newEMail, passwd);

        this.doLog("CheckPass " + passwd);

        axios
          .get("/app.php", {
            params: {
              checkpass: ihash,
            },
            headers:
              store.jwt > ""
                ? {
                  AUTHORIZATION: "Bearer " + store.jwt,
                }
                : {},
          })
          .catch(function (error) {
            if (error.response) {
              if (typeof statusCallback === "function") {
                // Callback-Funktion aufrufen zum Verarbeiten des Response-Status
                statusCallback(error.response.status);
              }
            } else if (error.request) {
              if (typeof statusCallback === "function") {
                // Callback-Funktion aufrufen zum Verarbeiten des Response-Status
                statusCallback(error.request.status);
              }
            }
          })
          .then((response) => {
            //console.log(response.data);
            if (typeof statusCallback === "function") {
              // Callback-Funktion aufrufen zum Verarbeiten des Response-Status
              if (response === undefined || response.status === undefined)
                statusCallback(999);
              else statusCallback(response.status);
            }
          });
      },
      getPing(dataSet = store.masterData) {
        let isMaster = (dataSet == store.masterData);
        const myIdent = isMaster ? store.identhash : dataSet.identData.ident;
        const myJWT = isMaster ? store.jwt : dataSet.identData.jwt;
        //wenn Readonly, dann den ganzen Satz holen
        if (dataSet.isReadOnly) {
          //hole neue Daten.
          if (isMaster) {
            this.getDataFromDB(myIdent, dataSet);
          } else {
            //console.log('#Refresh SubSata');
            this.fetchDataFromDB(myIdent, "", dataSet.identData.key, dataSet);
          }
        } else {
          //ansonsten Ping ausführen

          //this.doLog('dp-jwt: ' + store.jwt);
          //console.log(this.getJWT());


          axios
            .get("/app.php", {
              params: {
                CallJWT: myIdent > " " ? myIdent : "-",//leeres Ident ersetzen
              },
              headers:
                myJWT > ""
                  ? {
                    AUTHORIZATION: "Bearer " + myJWT,
                  }
                  : {},
            })
            .catch(function (error) {
              if (error.response) {
                console.log("Ping Error " + error.response.status);
              }
              if (error.code) {
                if (error.code == 'ERR_NETWORK') {
                  store.isTrackerBlockActive = true;
                }
              }
            })
            .then((response) => {
              //console.log("Ping response " + response);
              let isMaster = (dataSet == store.masterData);
              if (
                response !== undefined &&
                response.status !== undefined &&
                response.status == 200 &&
                response.data !== "false"
              ) {
                if (response.headers) {
                  try { //Differenz zur Serverzeit bestimmen
                    //console.log('# deltaT1');
                    let sDate = Date.parse(response.headers['date']);
                    store.deltaT = sDate - Date.now();
                    //console.log('# deltaT, ',store.deltaT);
                  } catch (error) {
                    store.deltaT = 0;
                  }
                }
                if (response.data.jwt != undefined) {
                  if (isMaster)
                    store.jwt = response.data.jwt
                  else
                    dataSet.identData.jwt = response.data.jwt
                }

                if (store.isDebug) console.log("pr-jwt: " + store.jwt);

                dataSet.config = response.data.config ?? {};

                if (response.data.readonly) {
                  dataSet.isReadOnly = true;
                } else {
                  dataSet.isReadOnly = false;
                }
                store.isTrackerBlockActive = false;
              } else {
                store.isTrackerBlockActive = true;
              }
            });
        }

      },
      startPing(one = false) {

        const doPing = function () {
          this.getPing(store.masterData);
          if (store.subData.isLoaded) {
            this.getPing(store.subData);
          }
        }.bind(this);

        doPing();

        if (!one)
          setInterval(doPing, 30000);
      },

      delDoc(hmac) {
        //console.log('dp-jwt: '+ store.jwt);
        //console.log(this.getJWT());
        axios
          .get("/app.php", {
            params: {
              ddoc: hmac,
            },
            headers:
              store.jwt > ""
                ? {
                  AUTHORIZATION: "Bearer " + store.jwt,
                }
                : {},
          })
          .catch(function (error) {
            if (error.response) {
              console.log("DelDoc Error " + error.response.status);
            }
          })
          .then((response) => {
            if (
              response !== undefined &&
              response.status !== undefined &&
              response.status == 200 &&
              response.data !== "false"
            ) {
              //store.jwt = response.data.jwt;
              //console.log('DelDoc: ' + response.data);
              //console.log('p-jwt: '+ store.jwt);
              //console.log(this.getJWT());
              //console.log(this.getJWT('l'));
            }
          });
      },

      pubDoc(hmac) {
        //console.log('dp-jwt: '+ store.jwt);
        //console.log(this.getJWT());
        axios
          .get("/app.php", {
            params: {
              pubdoc: hmac,
            },
            headers:
              store.jwt > ""
                ? {
                  AUTHORIZATION: "Bearer " + store.jwt,
                }
                : {},
          })
          .catch(function (error) {
            if (error.response) {
              console.log("pubDoc Error " + error.response.status);
            }
          })
          .then((response) => {
            if (
              response !== undefined &&
              response.status !== undefined &&
              response.status == 200 &&
              response.data !== "false"
            ) {
              //store.jwt = response.data.jwt;
              //console.log('pubDoc: ' + response.data);
              //console.log('p-jwt: '+ store.jwt);
              //console.log(this.getJWT());
              //console.log(this.getJWT('l'));
            }
          });
      },
      /*
       * Masterfunktion. Holt Daten von der Datenbank
       */
      getDataFromDB(ihash, statusCallback = false) {
        if (store.jwt > "") {
          //console.log('b-jwt: ' + store.jwt);
        }
        let xident = this.calcIdentXtra(store.user, store.birthday);
        if (store.datenart == 'GPMTEST') {
          xident = store.birthday == "1918-03-16" ? "###" : xident
        }
        //console.log('getDataFromDB');
        this.fetchDataFromDB(ihash, xident, false, store.masterData, statusCallback)

      },

      /*
       * abholfunktion. Holt Daten von der Datenbank
       
       */
      fetchDataFromDB(ihash, xident, iKey = false, adataSet = store.masterData, statusCallback = false) {
        //console.log('fetchDataFromDB');
        adataSet.isLoaded = false;


        //        CryptoJS
        //          .SHA256(ihash + ':' + store.birthday)
        //          .toString()

        //this.doLog(xident)
        //Zwischenspeicher, weil weiter untern sonst nicht im Scope
        //store.iKey = iKey;
        var dataSet = {
          set: adataSet,
          key: iKey,
          hash: ihash
        }

        axios
          .get("/app.php", {
            params: {
              ident: ihash,
              xident: xident,
            },
            headers:
              store.jwt > ""
                ? {
                  AUTHORIZATION: "Bearer " + store.jwt,
                }
                : {},
          })
          .catch(function (error) {
            if (error.response) {
              if (typeof statusCallback === "function") {
                // Callback-Funktion aufrufen zum Verarbeiten des Response-Status
                statusCallback(error.response.status);
              }
            } else if (error.request) {
              if (typeof statusCallback === "function") {
                // Callback-Funktion aufrufen zum Verarbeiten des Response-Status
                statusCallback(error.request.status);
              }
            }
          })
          .then((response) => {
            //console.log(response.data);
            //console.log('fetch 01')
            if (
              response !== undefined &&
              response.status !== undefined &&
              response.status == 200 &&
              response.data !== "false"
            ) {

              let isMaster = !dataSet.key
              //wenn kein key eplizit übergeben, dann sind es Mastedata
              if (isMaster) {
                store.auth = true;
              }
              if (typeof dataSet.set.identData != 'object') dataSet.set.identData = {};
              dataSet.set.identData.ident = dataSet.hash;
              dataSet.set.identData.key = dataSet.key;
              dataSet.set.identData.jwt = response.data.jwt


              //cdata ist der Datensatz vom Server
              dataSet.set.isReadOnly = response.data.readonly;
              dataSet.set.cdata = response.data;
              if (store.isDebug) console.log(response.data);
              if (isMaster) {
                //da wir Daten haben, war die Anmeldung erfolgreich
                this.meldung = "Anmeldung erfolgreich";
              } else {
                this.meldung = "Daten geladen";
              }

              //verschlüsselten Key im Store ablegen
              let dkey = false;

              if (isMaster) {
                store.key = response.data.keydata;
                //console.log(response.data);
                //console.log(store.decrypthash);
                //console.log(store.key);
                //console.log(store.masterData.cdata.iv);
                //console.log('Baaaammm!');
                dkey = this.decryptString(
                  store.key,
                  store.decrypthash,
                  store.masterData.cdata.iv
                );
              } else {
                dkey = dataSet.key.toLowerCase()
              }

              //config Daten holen
              dataSet.set.config = store.masterData.cdata.config ?? {};

              if (store.isDebug) console.log("key >" + dkey + "<");
              //jetzt wird mit dem entschlüsselten Key der Datensatz entschlüsselt und im Store abgelegt
              //console.log("# before");
              //console.log(response.data.docs);
              //console.log("# after");
              let tdoc = [];
              if (typeof response.data.docs !== "undefined") {
                for (const doc of response.data.docs) {
                  //console.log(
                  //  this.decryptString(doc.data, dkey, doc.iv)
                  //);
                  tdoc.push({
                    hmac: doc.hmac,
                    iv: doc.iv,
                    chunks: doc.chunks,
                    document: JSON.parse(
                      this.decryptString(doc.data, dkey, doc.iv)
                    ),
                  });
                  //console.log("#");
                }
              }
              dataSet.set.docs = tdoc;
              //console.log(store.masterData.docs);

              //dies packt die Daten in den Store
              //und füllt leere Tags mit einem Leerstring
              //resend blocken
              dataSet.set.datasent = -1;
              dataSet.set.data = JSON.parse(
                this.decryptString(
                  response.data.qdata,
                  dkey,
                  response.data.cinfo.data.iv
                ),
                (key, value) => {
                  return Object.keys(value).length === 0 ? "" : value;
                } //macht aus leeren Tags welche mit '' als Inhalt
              );
              if (isMaster && dataSet.set.data['@attributes'].Person) {
                store.satzart = 'person';
              }
              if (isMaster && store.masterData.data['@attributes'].Ansprechpartner) {
                store.satzart = 'ansprechpartner';
              }

              //console.log('###data###');
              //console.log(dataSet.set.data);
              let d = this.decryptString(
                response.data.qdata,
                dkey,
                response.data.cinfo.data.iv
              );
              //console.log(d);

              //resend blocken
              dataSet.set.datasent = -1;
              if (typeof response.data.oldqdata !== "undefined") {
                try {
                  dataSet.set.oldData = JSON.parse(
                    this.decryptString(
                      response.data.oldqdata,
                      dkey,
                      response.data.cinfo.oldData.iv
                    ),
                    (key, value) => {
                      return Object.keys(value).length === 0 ? "" : value;
                    } //macht aus leeren Tags welche mit '' als Inhalt
                  );
                } catch (error) {
                  //fallback, falls oldData kaputt ist.
                  if (store.isDebug) console.log("Crash bei dec oldqdata " + error);
                  if (store.isDebug) console.log(response.data.cinfo);
                  dataSet.set.oldData = JSON.parse(JSON.stringify(dataSet.set.data));
                }
              } else {
                dataSet.set.oldData = JSON.parse(JSON.stringify(dataSet.set.data));
              }

              //resend blocken
              dataSet.set.datasent = -1;
              if (typeof response.data.draft !== "undefined") {
                //console.log('draft gefunden: ' + response.data.draft);
                try {
                  dataSet.set.draft = JSON.parse(
                    this.decryptString(
                      response.data.draft,
                      dkey,
                      response.data.cinfo.draft.iv
                    ),
                    (key, value) => {
                      return Object.keys(value).length === 0 ? "" : value;
                    } //macht aus leeren Tags welche mit '' als Inhalt
                  );

                  //console.log(dataSet.set.draft);
                } catch (error) {
                  //fallback, falls oldData kaputt ist.
                  if (store.isDebug) console.log("Crash bei dec draft " + error);
                  if (store.isDebug) console.log(response.data.cinfo);
                  let decdraft = this.decryptString(
                    response.data.draft,
                    dkey,
                    response.data.cinfo.draft.iv
                  );
                  if (store.isDebug) console.log(decdraft);
                  dataSet.set.draft = {};
                }
              } else {
                dataSet.set.draft = {};
              }
              if ((typeof dataSet.set.draft == "string")) {
                //wenn beim Laden was schiefgegangen ist
                dataSet.set.draft =
                {
                  options: {
                    Schnellzugriff: {
                    },
                    SchnellZugriff: {},
                  },
                  sService: {
                    Entwuerfe: {},
                  },
                }
              }
              //console.log(typeof dataSet.set.draft);
              //console.log(dataSet.set.draft);
              //resend blocken
              dataSet.set.datasent = -1;
              if (typeof response.data.olddraft !== "undefined") {
                try {
                  dataSet.set.olddraft = JSON.parse(
                    this.decryptString(
                      response.data.olddraft,
                      dkey,
                      response.data.cinfo.olddraft.iv
                    ),
                    (key, value) => {
                      return Object.keys(value).length === 0 ? "" : value;
                    } //macht aus leeren Tags welche mit '' als Inhalt
                  );
                  if (typeof dataSet.set.olddraft !== "object") {
                    dataSet.set.olddraft = JSON.parse(JSON.stringify(dataSet.set.draft));
                  }
                  //console.log(dataSet.set.olddraft)
                } catch (error) {
                  if (store.isDebug) console.log("Crash bei dec olddraft " + error);
                  if (store.isDebug) console.log(response.data.cinfo);
                  //dataSet.set.olddraft = JSON.parse(JSON.stringify(dataSet.set.draft));
                }
              } else {
                dataSet.set.olddraft = JSON.parse(JSON.stringify(dataSet.set.draft));
              }
              dataSet.set.datasent = -1;

              if (isMaster) {
                //das JWT ablegen um autorisiert Daten zurückschicken zu können
                //es enthält die ident und die Nummer dieses Datensatzes
                store.jwt = response.data.jwt;
                //console.log('n-jwt: ' + store.jwt);
                store.indextable = response.data.indexes;
                // Weitere Tabellen ergänzen
                //console.log(store.indextable);
                //store.indextable.TAB.push(JSON.parse('{"@attributes": {"NAME": "ANLIEGEN"},"KEY": [{"KEY_NR": "0","KEY_TXTK": "keine","KEY_TXTL": "Bitte auswählen"},{"KEY_NR": "1","KEY_TXTK": "angebotsanfrage", "KEY_TXTL": "Angebotsanfrage"},{"KEY_NR": "2","KEY_TXTK": "versorgung", "KEY_TXTL": "Frage zur Versorgung"},{"KEY_NR": "3","KEY_TXTK": "portal", "KEY_TXTL": "Frage zum Portal"},{"KEY_NR": "4","KEY_TXTK": "leistungsabrechnung", "KEY_TXTL": "Frage zur Leistungsabrechnung"},{"KEY_NR": "5","KEY_TXTK": "steuerbescheinigung", "KEY_TXTL": "Frage zur Steuerbescheinigung"},{"KEY_NR": "6","KEY_TXTK": "fachlich", "KEY_TXTL": "Fachliche Frage"}]}'));
                store.indextable.TAB.push(
                  JSON.parse(
                    '{"@attributes": {"NAME": "BEITRAGSPFLICHTIG"},"KEY": [{"KEY_NR": "0","KEY_TXTK": "pflichtig","KEY_TXTL": "pflichtig"},{"KEY_NR": "1","KEY_TXTK": "freiwillig", "KEY_TXTL": "freiwillig"},{"KEY_NR": "2","KEY_TXTK": "privat", "KEY_TXTL": "privat"}]}'
                  )
                );
                store.indextable.TAB.push(
                  JSON.parse(
                    '{"@attributes": {"NAME": "PENSARTCUST"},"KEY": [{"KEY_NR": "0","KEY_TXTK": "altersrente","KEY_TXTL": "Altersrente"},{"KEY_NR": "1","KEY_TXTK": "erwerbsunfähigkeitsrente", "KEY_TXTL": "Erwerbsunfähigkeitsrente "},{"KEY_NR": "2","KEY_TXTK": "witwenrente ", "KEY_TXTL": "Witwenrente"},{"KEY_NR": "3","KEY_TXTK": "waisenrente ", "KEY_TXTL": "Waisenrente"}]}'
                  )
                );
              }

              if (store.isDebug){
                this.saveStore();
                console.log('####data###')
                console.log(dataSet);
              }
              
              //so - jetzt ist der Stand geladen und Änderungen dürfen gesendet werden

              const datadif = diffObj(dataSet.set.oldData, dataSet.set.data);
              const cdata = textInTree(datadif);

              //console.log('-----------------------------------')
              //console.log(dataSet.set.olddraft)
              //console.log(dataSet.set.draft)
              const draftdif = diffObj(dataSet.set.olddraft, dataSet.set.draft);
              //console.log(draftdif)
              const cdraft = textInTree(draftdif);

              dataSet.set.datasent = -1;
              dataSet.set.modifyhash.draft = this.hashString(cdraft);

              dataSet.set.datasent = -1;
              dataSet.set.modifyhash.data = this.hashString(cdata);

              dataSet.set.datasent = -1;
              dataSet.set.lastMod = "-1"; // store.masterData.modifyhash.data + store.masterData.modifyhash.draft;
              dataSet.set.datasent = 0;
            }
            // Bugfix Mail-Adresse doppelt und als Array gespeichert (tritt nur in Entwicklungsumgebung auf und muss von Stefan gefixt werden) 
            /*
            if (store.datenart == 'GPMTEST' && Array.isArray(store.masterData.data.Stammdaten.EMAIL)) {
              console.log('fixed');
              store.masterData.data.Stammdaten.EMAIL = store.masterData.data.Stammdaten.EMAIL[0];
            }
            */
            dataSet.set.isLoaded = true;

            if (typeof statusCallback === "function") {
              // Callback-Funktion aufrufen zum Verarbeiten des Response-Status
              if (response === undefined || response.status === undefined)
                statusCallback(999);
              else statusCallback(response.status);
              //ende if
            }
            //store.indextable.TAB.push(JSON.parse('{"@attributes": {"NAME": "KINDER"},"KEY": [{"KEY_NR": "0","KEY_TXTK": "kinderlos","KEY_TXTL": "kinderlos"},{"KEY_NR": "1","KEY_TXTK": "kinderhabend", "KEY_TXTL": "kinderhabend"}]}'));

            // Manipulation ReadOnly bis
            // Manipulation Anwärterkennung ab



          })
      },
      // globale Funktionen für selfServices ab
      /////////////////////////////////////////

      /**
       * Ermittelt alle Werte eines Objektbaumes zum übergebenen Namen.
       * Wird genutzt, um eine Liste der hmacs für das Löschen oder Publizieren von Dateien zu erhalten.
       * @param {object} obj SelfService-Objekt, das untersucht werden soll (z.B. "store.masterData.draft.sService.Entwuerfe.AenderungKontaktdaten")
       * @param {string} name Name der Objekt-Elemente, die berücksichtig werden sollen (übergeben wird immer "hmac")
       * @returns {array} Liste aller Einträge zum übergebenen Element
       */
      treeToArray(obj, name, arr = []) {
        //console.log('*');
        const keys = obj instanceof Object ? Object.keys(obj) : [];
        for (const key of keys) {
          //console.log('treeToArray ' + key);
          if (key == name) {
            arr.push(obj[key]);
          } else {
            arr = this.treeToArray(obj[key], name, arr);
          }
        }
        return arr;
      },
      /**
       * Initialisiert in olddraft und draft für den übergebenen Service die ebenfalls übergebenen Datenfelder.
       * (Felder werden ggf. erzeugt)
       * @param {string} sName Name des Services (z.B. "AenderungKontaktdaten")
       * @param {object} jMap Objekt mit den Datenfeldern des Services (z.B. {"PRB_IBAN":"","PRB_BIC":"","PRB_BNAME":"","PRB_LAND":"","PRB_LKZ":"","PRB_ABWNAME":"","uuid":""})
       * @param {string} minVersion Liegt ein Datensatz in älterer Version als dieser vor, wird ein ungespeicherter Entwurf gelöscht, da sich die Struktur wesentlich verändert hat.
       * @returns {number} 0 > Keine Aktion, 1 > olddraft wurde initialisiert, 2 > draft wurde initialisiert, 3 > olddraft und draft wurden initialisiert
       */
      initSelfService(sName, jMap, minVersion, dataSet = store.masterData) {
        let status = 0;
        //olddraft vorbereiten
        //console.log(sName,jMap,store.masterData.olddraft);
        let service = store.selfService[sName];

        if (typeof dataSet.olddraft !== "object") {
          dataSet.olddraft = JSON.parse("{}");
        }

        if (typeof dataSet.olddraft.sService !== "object")
          dataSet.olddraft.sService = {};
        if (typeof dataSet.olddraft.sService.Entwuerfe !== "object")
          dataSet.olddraft.sService.Entwuerfe = {};

        //draft vorbereiten
        if (typeof dataSet.draft.sService !== "object") {
          dataSet.draft.sService = {};
        }
        if (typeof dataSet.draft.sService.Entwuerfe !== "object") {
          dataSet.draft.sService.Entwuerfe = {};
        }
        //service anlegen 1
        if (typeof dataSet.olddraft.sService.Entwuerfe[sName] !== "object") {
          //anlegen
          this.doLog("jMap:", jMap);
          dataSet.olddraft.sService.Entwuerfe[sName] = JSON.parse(jMap);
          //olddraft wurde angelegt
          status += 1;
        } else {
          // Wenn veraltete Service-Version, olddraft initialisieren
          if (typeof dataSet.olddraft.sService.Entwuerfe[sName]['version'] !== "string" || dataSet.olddraft.sService.Entwuerfe[sName]['version'] < minVersion) {
            this.doLog("Reset ungespeicherten Entwurf wegen Versionsänderung");
            dataSet.olddraft.sService.Entwuerfe[sName] = JSON.parse(jMap);
            dataSet.draft.sService.Entwuerfe[sName] = {};
            status += 1;
          } else {
            //fehlende Felder ergänzen:
            dataSet.olddraft.sService.Entwuerfe[sName] = supplementObj(JSON.parse(jMap), dataSet.olddraft.sService.Entwuerfe[sName])
          }
        }
        if (
          typeof dataSet.draft.sService.Entwuerfe[sName] !== "object" ||
          Object.entries(dataSet.draft.sService.Entwuerfe[sName]).length === 0
        ) {
          dataSet.draft.sService.Entwuerfe[sName] = JSON.parse(jMap);
          status += 2;
        }
        else {
          //fehlende Felder ergänzen:
          dataSet.draft.sService.Entwuerfe[sName] = supplementObj(dataSet.olddraft.sService.Entwuerfe[sName], dataSet.draft.sService.Entwuerfe[sName])
        }


        return status;
      },
      /**
       * Überträgt olddraft nach draft für den übergebenen Service (z.B. "AenderungKontaktdaten").
       * (Reset-Funktion: Uploads in draft werden gelöscht. Wenn dies nicht gewünscht ist, ist Funktion "copyToDraftIgnoreFilesSelfService" zu nutzen.)
       * @param {string} sName Name des Services (z.B. "AenderungKontaktdaten")
       * @returns {number} (0 = Festwert)
       */
      copyToDraftSelfService(sName, dataSet = store.masterData) {
        //eventuelle Uploads suchen
        let files = this.treeToArray(
          dataSet.draft.sService.Entwuerfe[sName],
          "hmac"
        );
        //this.doLog(files);
        let olddraft = JSON.stringify(dataSet.olddraft.sService.Entwuerfe[sName]);
        //console.log('sName '+sName);
        //console.log(olddraft);
        //upload löschen
        files.forEach((hmac) => {
          this.doLog("copyToDraftSelfService " + hmac);
          this.delDoc(hmac);
        });
        dataSet.draft.sService.Entwuerfe[sName] = JSON.parse(olddraft);
        return 0;
      },
      /**
       * Übertrage olddraft nach draft für den übergebenen Service (z.B. "AenderungKontaktdaten").
       * (Es werden keine Daten gelöscht, wenn gewünscht, ist Funktion "copyToDraftSelfService" zu nutzen.)
       * @param {string} sName Name des Services (z.B. "AenderungKontaktdaten")
       * @returns {number} (0 = Festwert)
       */
      copyToDraftIgnoreFilesSelfService(sName, dataSet = store.masterData) {
        let olddraft = JSON.stringify(dataSet.olddraft.sService.Entwuerfe[sName]);
        dataSet.draft.sService.Entwuerfe[sName] = JSON.parse(olddraft);
        return 0;
      },
      /**
       * Löscht die SelfService-Daten des übergebenen Services von "draft" inkl. Files sowie von "oldData", sofern nicht die ReadOnly-Kennung gesetzt ist.
       * @param {string} sName Name des Services (z.B. "AenderungKontaktdaten")
       * @returns {number} (0 = Festwert)
       */
      deleteDraftSelfService(sName, dataSet = store.masterData) {
        if (dataSet.isReadOnly) {
          return -1;
        }
        if (
          typeof dataSet.draft.sService === "object" &&
          typeof dataSet.draft.sService[sName] !== "object"
        ) {
          let files = this.treeToArray(
            dataSet.draft.sService.Entwuerfe[sName],
            "hmac"
          );
          files.forEach((hmac) => {
            this.doLog("copyToDraftSelfService " + hmac);
            this.delDoc(hmac);
          });
          delete dataSet.draft.sService.Entwuerfe[sName];
        }
        if (
          typeof dataSet.olddraft.sService === "object" &&
          typeof dataSet.olddraft.sService[sName] !== "object"
        ) {
          delete dataSet.olddraft.sService.Entwuerfe[sName];
        }

        return 0;
      },
      /**
       * Überträgt die SelfService-Daten des übergebenen Services von "draft" nach "data".
       * @param {string} sName Name des Services (z.B. "AenderungKontaktdaten")
       * @returns {number} (0 = Festwert)
       */
      sendDataSelfService(sName, dataSet = store.masterData) {
        if (dataSet.isReadOnly) {
          return -1;
        }
        let currdraft = JSON.stringify(dataSet.draft.sService.Entwuerfe[sName]); // Aktuelle Daten ermitteln und klonen
        let draftdiff = JSON.stringify(
          dataSet.draftdiff.sService.Entwuerfe[sName]
        ); // Aktuelle Daten ermitteln und klonen
        //files veröffentlichen
        let files = this.treeToArray(
          dataSet.draft.sService.Entwuerfe[sName],
          "hmac"
        );
        files.forEach((hmac) => {
          this.doLog("copyToDraftSelfService " + hmac);
          this.pubDoc(hmac);
        });

        //oldData existiert nicht für SelfServices
        if (typeof dataSet.data.sService !== "object") {
          dataSet.data.sService = JSON.parse("{}");
        }
        //Diesen Selfservice einfügen
        let idx = 0;
        let uuid = this.getUUID();
        if (typeof dataSet.draft.sService.Entwuerfe[sName].uuid === "object") {
          uuid = dataSet.draft.sService.Entwuerfe[sName].uuid;
          //console.log('Found UUID ' + uuid);
        }

        if (typeof dataSet.data.sService[sName] !== "object") {
          dataSet.data.sService[sName] = JSON.parse("{}");
        } else {
          if (Object.keys(dataSet.data.sService[sName]).length == 0) {
            idx = 0;
          } else {
            idx = Object.keys(dataSet.data.sService[sName])[
              Object.keys(dataSet.data.sService[sName]).length - 1
            ];
            idx = parseInt(idx.substring(2)); // 'nr' ignorieren fürs Hochzählen
          }
        }
        idx++;

        //dataSet.data.sService[sName]['nr'+idx] = JSON.parse('{}');
        dataSet.data.sService[sName]["nr" + idx] = JSON.parse(currdraft); // Eingaben und Stammdaten in Liste kopieren
        dataSet.data.sService[sName]["nr" + idx]["changes"] =
          JSON.parse(draftdiff); // Für die Änderungs-Übersicht hier nur geänderte Felder einstellen
        //this.deleteDraftSelfService(sName);
        this.copyToDraftIgnoreFilesSelfService(sName, dataSet);
        //Uploads löschen
        //Achtung - dies funktioniert nicht für SubData
        if (uuid in store.uploadFiles.files) {
          this.doLog("delete " + uuid);
          store.uploadFiles.files[this.formId] = [];
          delete store.uploadFiles.files[this.formId];
        }
        return 0;
      },
      /**
       * Prüft, ob es Änderungsaufträge zum Service gibt, die noch nicht von Pensus abgeholt wurden.
       * @param {string} sName Name der Services (z.B."AenderungKontaktdaten")
       * @returns {boolean} true > Es existieren Änderungsaufträge zum Service, false > es liegen keine Änderungsaufträge vor
       */
      checkForUnsentSelfService(sName, dataSet = store.masterData) {
        if (typeof dataSet.data.sService !== "object") {
          dataSet.data.sService = JSON.parse("{}");
        }
        if (
          typeof dataSet.data !== "undefined" &&
          typeof dataSet.data.sService !== "undefined" &&
          typeof dataSet.data.sService[sName] !== "undefined" &&
          Object.keys(dataSet.data.sService[sName]).length !== 0
        )
          return true;
        else return false;
      },
      /**
       * Löscht vorhandene Änderungsaufträge zum Service, die noch nicht von Pensus abgeholt wurden inkl. Files.
       * @param {string} sName Name der Services (z.B."AenderungKontaktdaten")
       * @returns {number} (0 = Festwert)
       */
      removeDataSelfService(sName, key, dataSet = store.masterData) {
        if (typeof dataSet.data.sService !== "object") {
          dataSet.data.sService = JSON.parse("{}");
        }
        //eventuelle Uploads suchen
        let files = this.treeToArray(dataSet.data.sService[sName][key], "hmac");
        //upload löschen
        files.forEach((hmac) => {
          this.doLog("removeDataSelfService " + hmac);
          this.delDoc(hmac);
        });
        delete dataSet.data.sService[sName][key];
        return 0;
      },
      // globale Funktionen für selfServices bis
      //////////////////////////////////////////
      /**
       * Prüft, ob es sich bei der angemeldeten Person um einen Rentner handelt.
       * (Hierzu muss er für Aufruf ohne Parameter mindestens für eine seiner PMNR den entspr. Status haben.)
       * @param {number} pmnr false > Prüfung über alle PMNR's des Kunden, number > Prüfung nur für die übergebene PMNR
       * @returns {boolean} true > Es handelt sich um einen Rentner, false > Es handelt sich nicht um einen Rentner.
       */
      checkRentner(pmnr = false) {
        let result = false;
        let vari = this.getVersorgung();
        let rentner = this.getStatusRente();
        const keys = Object.keys(vari);
        keys.forEach((key, index) => {
          if (!pmnr || pmnr == vari[key]["@attributes"]["PMNR"]) {
            if (rentner.includes(vari[key].PSTATUSN)) {
              result = true;
            }
          }
        });
        return result;
      },
      /**
      * Prüft, ob die angemeldeten Person bei einer Firma beschäftigt ist, welche eigenständige Anträge untersagt.
      * (Hierzu muss er für Aufruf ohne Parameter mindestens für eine seiner PMNR den entspr. Status haben.)
      * @param {number} pmnr false > Prüfung über alle PMNR's des Kunden, number > Prüfung nur für die übergebene PMNR
      * @returns {boolean} true > Es gibt für mind. eine Firma das Verbot eingeständiger Anträge, false > Eigenständige Anträge sind erlaubt.
      */
      checkVerbotFirma(pmnr = false) {
        let result = false;
        let vari = this.getVersorgung();
        //verbotene Firmen
        let FirmenNummern = ["3"];
        const keys = Object.keys(vari);
        keys.forEach((key, index) => {
          if (!pmnr || pmnr == vari[key]["@attributes"]["PMNR"]) {
            if (FirmenNummern.includes(vari[key].FANR)) {
              result = true;
            }
          }
        });
        return result;
      },
      /**
       * Prüft, ob es sich bei der angemeldeten Person um einen Pensionsanwärter handelt.
       * (Hierzu muss er für Aufruf ohne Parameter mindestens für eine seiner PMNR den entspr. Status haben.)
       * @param {number} pmnr false > Prüfung über alle PMNR's des Kunden, number > Prüfung nur für die übergebene PMNR
       * @returns {boolean} true > Es handelt sich um einen Anwärter, false > Es handelt sich nicht um einen Anwärter.
       */
      checkAnwaerter(pmnr = false) {
        let result = false;
        let vari = this.getVersorgung();
        let anwaerter = this.getStatusAnwaerter();
        const keys = Object.keys(vari);
        keys.forEach((key, index) => {
          if (!pmnr || pmnr == vari[key]["@attributes"]["PMNR"]) {
            if (anwaerter.includes(vari[key].PSTATUSN)) {
              result = true;
            }
          }
        });
        return result;
      },
      /**
       * Gibt ein Array mit den Status (PSTATUSN) aller Rentner zurück
       * @returns {array} Array mit den Status, die einen Rentner kennzeichnen
       */
      getStatusRente() {
        let arr = ["3", "4", "5", "6", "7", "10", "11", "12", "28"];
        return arr;
      },
      /**
       * Gibt ein Array mit den Status (PSTATUSN) aller Rentner zurück
       * @returns {array} Array mit den Status, die einen Rentner kennzeichnen
       */
      getStatusAnwaerter() {
        let arr = ["1", "2", "9", "18", "21", "22", "23", "24", "25"];
        return arr;
      },
      /**
       * Prüft, ob Stammdaten wie Name, Vorname oder Geburtsdatum erfasst wurden.
       * @returns {boolean} true > Es wurde mindestens ein Teil der Stammdaten erfasst, false > Es wurden noch keinerlei Stammdaten erfasst.
       */
      checkStammdatenErfasst() {
        return (
          store.masterData.data.Stammdaten.NAME !== "" ||
          store.masterData.data.Stammdaten.VORNAME !== "" ||
          store.masterData.data.Stammdaten.NAMEVS !== "" ||
          store.masterData.data.Stammdaten.NAMEZUS !== "" ||
          (store.masterData.data.Stammdaten.GEBDAT !== "" &&
            store.masterData.data.Stammdaten.GEBDAT != 0)
        );
      },
      /**
       * Prüft, ob die Privatadresse (c/o Name, Straße, PLZ oder Ort) erfasst wurde.
       * @returns {boolean} true > Es wurde mindestens ein Teil der Privatadresse erfasst, false > Es wurden noch keine Privatadresse erfasst.
       */
      checkPrivatadresseErfasst() {
        return (
          store.masterData.data.Privatadresse.PCONAME !== "" ||
          store.masterData.data.Privatadresse.PSTRASSE !== "" ||
          store.masterData.data.Privatadresse.PPLZ !== "" ||
          store.masterData.data.Privatadresse.PORT !== ""
        );
      },
      /**
       * Prüft, ob die Privatbank (abw. Name, BIC, IBAN oder Bankname) erfasst wurde.
       * @returns {boolean} true > Es wurde mindestens ein Teil der Privatbank-Daten erfasst, false > Es wurden noch keine Privatbank erfasst.
       */
      checkPrivatbankErfasst() {
        return (
          store.masterData.data.Privatbank.PRB_ABWNAME !== "" ||
          store.masterData.data.Privatbank.PRB_BIC !== "" ||
          store.masterData.data.Privatbank.PRB_IBAN > "" ||
          store.masterData.data.Privatbank.PRB_BNAME !== ""
        );
      },
    };
  },

  /*(key) => {
    // retrieve a nested property in `options`
    // using `key` as the path
    return key.split('.').reduce((o, i) => {
      if (o) return o[i]
    }, options)
  }*/
};
</script>
<template></template>
