<script>
import { store } from "../components/store.vue";
import HauptMenue from "../components/HauptMenue.vue";
import FooterMenu from "../components/FooterMenu.vue";
import SchnellZugriff from "../components/SchnellZugriff.vue";
import AenderungsauftraegeSelfService from "../components/AenderungsauftraegeSelfService.vue";
import AuftraegeSelfService from "../components/AuftraegeSelfService.vue";
import Alertbox from "../components/Alertbox.vue";
//import DocumentCaptureVue from "../components/DocumentCapture.vue";
/** View-Komponente zur Anzeige der Startseite mit Schnellzugriff, ungespeicherten Änderungen sowie nicht abgeholter Änderungsaufträge
 * @displayName Startseite
 */
export default {
  name: "HomePage",
  components: {
    // myRouter: router,
    HauptMenue,
    FooterMenu,
    SchnellZugriff,
    AenderungsauftraegeSelfService,
    AuftraegeSelfService,
    Alertbox,
    //DocumentCaptureVue,
  },
  data() {
    return {
      inBearbeitung: {
        AenderungPersoenlichedaten: false,
        AenderungBankverbindung: false,
        ErfassungSteuerId: false,
        AenderungKontaktdaten: false,
        AntragBetriebsrente: false,
        AenderungKrankenkasse: false,
        AenderungAnsprechpartnerdaten: false,
        AenderungFirmendaten: false,
        AenderungFirmenAdressdaten: false,
        AenderungFirmenBankdaten: false,
        AenderungFirmenFinanzamtsdaten: false,
        KontaktPensus: false,
      },
      unversandt: {
        AenderungPersoenlichedaten: false,
        AenderungBankverbindung: false,
        ErfassungSteuerId: false,
        AenderungKontaktdaten: false,
        AntragBetriebsrente: false,
        AenderungKrankenkasse: false,
        AenderungAnsprechpartnerdaten: false,
        AenderungFirmendaten: false,
        AenderungFirmenAdressdaten: false,
        AenderungFirmenBankdaten: false,
        AenderungFirmenFinanzamtsdaten: false,
        KontaktPensus: false,
      },
      store,
      componentKey: 0,
    };
  },
  mounted() {
    store.selectedFirma = "";
    window._paq.push(["setDocumentTitle", this.name]);
    window._paq.push(["trackPageView"]);
    window.scrollTo(0, 0);
    if (
      store.satzart === "ansprechpartner" &&
      typeof store.subData.data["@attributes"] != "object"
    )
      this.loadFirma();
    this.chckUnbearbeitet();
    this.forceRerender();
    if (store.subData.data?.Firmenstammdaten?.FENR) { // Wenn bereits Firma ausgewählt war, einstellen für Dropdown-Menü
      let firmenkeys = Object.keys(store.masterData.data.Firmen.FIRMA);
      firmenkeys.forEach((key) => {
        if (store.masterData.data.Firmen.FIRMA[key]['FFENR'] == store.subData.data.Firmenstammdaten.FENR) {
          store.selectedFirma = store.masterData.data.Firmen.FIRMA[key];
        }
    });
    }
  },
  methods: {
    chckUnbearbeitet() {
      for (const [key] of Object.entries(this.inBearbeitung)) {
        this.inBearbeitung[key] =
          this.$servertalk.gettAttr(
            "subData.draft.sService.Entwuerfe." + key
          ) == "modified" ||
          this.$servertalk.gettAttr(
            "masterData.draft.sService.Entwuerfe." + key
          ) == "modified";
        this.unversandt[key] =
          this.$servertalk.checkForUnsentSelfService(key) ||
          this.$servertalk.checkForUnsentSelfService(key, store.subData);
      }
    },
    selectFirma(firma) {
      this.$servertalk.fetchDataFromDB(
        firma.FHASH1.toLowerCase(),
        "",
        firma.FAES_KEY.toLowerCase(),
        this.store.subData,
        function () {
          this.forceRerender();
          this.chckUnbearbeitet();
        }.bind(this)
      );
    },
    handleFirmaChange() {
      if (store.selectedFirma) {
        this.selectFirma(store.selectedFirma);
      }
    },
    loadFirma(i = 0) {
      //schnellzugriff ausschalten
      //console.log("#ladeFirma");
      //return false;
      //console.log("Inhalt Store:");
      //console.log(store);
      store.subData.data.Firmenstammdaten = {};
      if (
        (store.satzart === "ansprechpartner" &&
          store.masterData.data.Firmen &&
          !Array.isArray(store.masterData.data.Firmen.FIRMA)) ||
        Object.keys(store.masterData.data.Firmen.FIRMA).length == 1
      ) {
        //zum array machen
        let firmen = Array.isArray(store.masterData.data.Firmen.FIRMA)
          ? store.masterData.data.Firmen.FIRMA
          : [store.masterData.data.Firmen.FIRMA];
        let index = Object.keys(firmen)[i];
        let firma = firmen[index];
        store.subData.identData = {
          ident: firma.FHASH1,
          key: firma.FAES_KEY,
          pos: i,
          index: index,
        };
        //console.log("#### -subData loading");
        //console.log("Firma:");
        //console.log(firma);
        this.$servertalk.fetchDataFromDB(
          firma.FHASH1.toLowerCase(),
          "",
          firma.FAES_KEY.toLowerCase(),
          store.subData,
          //dies ist die Callback. Schnellzugriff wird nach dem Laden initialisiert
          function () {
            this.forceRerender();
          }.bind(this)
        );
      } else {
        this.forceRerender();
      }
    },
    forceRerender() {
      this.componentKey += 1;
    },
  },
  beforeCreate: function () {
    if (!store.auth) {
      this.$router.push("/");
    }
  },
  computed: {
    ungespeicherteEingaben() {
      // Prüfen, ob es in mind. einem Bereich ungespeicherte Änderungen gibt, Ausnahme: Ungespeicherte Nachricht an Pensus, da diese nicht anzuzeigen ist
      for (const [key] of Object.entries(this.inBearbeitung)) {
        if ((this.inBearbeitung[key] && key !== "KontaktPensus") || this.unversandt[key]) return true;
      }
      return false;
    },
    isWartung() {
      let wartung = false;
      let omessage = store.masterData.config?.messages?.DBWartung ?? false;
      let now = new Date();
      if (
        omessage &&
        Date.parse(omessage.von) < now &&
        Date.parse(omessage.bis) > now
      ) {
        wartung = true;
      }
      return wartung;
    },
    messageWartung() {
      let message = "";
      if (this.isWartung) {
        message = store.masterData.config?.messages?.DBWartung?.message ?? "";
      }

      return message;
    },
    isPerson() {
      return store.satzart === "person";
    },
    sortedFirmen() {
      return Array.isArray(this.store.masterData.data.Firmen.FIRMA)
        ? this.store.masterData.data.Firmen.FIRMA.sort((a, b) =>
          a.FNAME.localeCompare(b.FNAME)
        )
        : [this.store.masterData.data.Firmen.FIRMA];
    },
    firmen() {
      return Array.isArray(this.store.masterData.data.Firmen.FIRMA)
        ? this.store.masterData.data.Firmen.FIRMA
        : [this.store.masterData.data.Firmen.FIRMA];
    },
  },
};
</script>
<template>
  <div class="main-wrapper flex-grow" :class="store.accessRestriction">
    <HauptMenue />
    <div class="hero-image-wrapper">
      <img
        class="max-h-[200px] md:max-h-[400px] lg:max-h-[600px] object-top overflow-hidden object-cover w-full mt-[100px] lg:mt-0"
        src="/assets/img/pensus-aelteres-paerchen-vor-laptop.jpg" role="img" v-if="isPerson" />
      <img
        class="max-h-[200px] md:max-h-[400px] lg:max-h-[600px] object-center overflow-hidden object-cover w-full mt-[100px] lg:mt-0"
        src="/assets/img/pensus-ansprechpartner-startseite.webp" role="img" v-else />

      <div class="hero-description-block max-w-7xl mx-auto relative">
        <div class="hero-inside px-5 py-5 lg:py-10 lg:p-12 relative lg:absolute max-w-full lg:max-w-lg lg:bottom-12"
          :class="isPerson ? 'bg-primary text-white' : 'bg-white text-primary'">
          <h1 class="text-xl lg:text-4xl text-left font-bold tracking-wider mb-2"
            :class="isPerson ? ' text-white' : 'text-primary'">
            Willkommen im Pensus Serviceportal
            <br />
            <span class="font-normal text-lg lg:text-2xl">
              {{ store.masterData.data.Stammdaten.TITEL }}
              {{ store.masterData.data.Stammdaten.VORNAME }}
              {{ store.masterData.data.Stammdaten.NAMEZUS }}
              {{ store.masterData.data.Stammdaten.NAMEVS }}
              {{ store.masterData.data.Stammdaten.NAME }}
            </span>
          </h1>
          <h2>
            Ihrem digitalen Verwaltungsbereich rund um die betriebliche
            Altersversorgung
          </h2>
          <Alertbox />
        </div>
      </div>
    </div>

    <div class="bg-primary text-tertiary border-b border-white" v-if="!isPerson">
      <div class="max-w-3xl mx-auto py-8 lg:py-16 px-5 lg:px-0">
        <h2 class="text-2xl lg:text-4xl font-bold text-white mb-8 lg:mb-12 text-center">
          Firmen bearbeiten
        </h2>
        <div>
          <!-- Wenn mehr als 5 Firmen vorhanden sind, zeige eine Dropdown-Liste -->
          <div v-if="firmen.length > 5" class="mb-4">
            <select v-model="store.selectedFirma" @change="handleFirmaChange"
              class="custom-select w-full p-4 border rounded cursor-pointer appearance-none font-bold text-sm lg:text-base">
              <option disabled value="">Bitte Firma auswählen</option>
              <option v-for="(firma, index) in sortedFirmen" :key="index" :value="firma">
                {{ firma.FNAME }} {{
                  $servertalk.getName(
                    "GESFORM",
                    firma.FGESFORM
                  )
                }} {{ firma.FKENNR }} / {{ firma.FABRGR }}
              </option>
            </select>
          </div>

          <!-- Wenn 5 oder weniger Firmen vorhanden sind, zeige sie direkt an -->
          <div v-else>
            <div class="group" :class="store.subData.data?.Firmenstammdaten?.FENR == firma.FFENR
              ? 'active'
              : ''
              " v-for="(firma, index) in sortedFirmen" :key="index">
              <div
                class="firma cursor-pointer text-sm lg:text-base p-4 bg-white mb-1 rounded-md hover:bg-gray-50 hover:scale-[101%] hover:shadow-md transition-all duration-500 flex justify-between items-center group-[.active]:bg-slate-300"
                @click="selectFirma(firma)">
                <div>
                  <span class="font-bold">
                    {{ firma.FNAME }}
                    {{
                      $servertalk.getName(
                        "GESFORM",
                        firma.FGESFORM
                      )
                    }}
                    {{ firma.FKENNR }} / {{ firma.FABRGR }}
                  </span>
                </div>
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"
                    class="w-4 lg:w-8 h-auto -translate-x-1 group-hover:translate-x-0 transition-all duration-500 fill-tertiary">
                    <path
                      d="M295.5 115.7l-19.6 19.6c-4.8 4.8-4.7 12.5 .2 17.1L356.8 230H12c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h344.8l-80.7 77.5c-4.8 4.7-4.9 12.4-.2 17.1l19.6 19.6c4.7 4.7 12.3 4.7 17 0l131.8-131.8c4.7-4.7 4.7-12.3 0-17L312.5 115.7c-4.7-4.7-12.3-4.7-17 0z" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <SchnellZugriff :key="componentKey" />
    
    <div  class="auftraege-section py-8 mb-16 px-5 xl:px-0 max-w-7xl mx-auto">
      <h2 v-if="ungespeicherteEingaben" class="text-2xl lg:text-4xl font-bold text-primary mb-8 lg:mb-12 text-center">
        Meine Aufträge
      </h2>
      <div v-if="isWartung"
        class="p-5 text-primary border border-primary bg-primary/10 rounded-md my-5 max-w-3xl mx-auto">
        {{ messageWartung }}
      </div>
      <div class="alertbox" :class="{ group: store.message.text }">
        <div
          class="fixed translate-y-[999px] group-[.alertbox]:translate-y-0 bottom-8 transition-all duration-1000 left-1/2 -translate-x-1/2 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white z-[70]">
          <div class="mt-3 text-center">
            <div v-if="
              store.message.status === 'ok' || store.message.status === ''
            " class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
              <svg class="h-6 w-6 text-green-600" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
              </svg>
            </div>
            <div v-else class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
              <svg class="h-6 w-6 text-red-600" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
              </svg>
            </div>

            <div class="mt-2 px-7 py-3">
              <p class="text-sm font-bold text-tertiary whitespace-pre-line">
                {{ store.message.text }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="unversandt">
        <AenderungsauftraegeSelfService :key="componentKey" @removed="chckUnbearbeitet" />
      </div>
      <AuftraegeSelfService :key="componentKey" v-bind:nurOffene="true" v-bind:unversandt="this.unversandt"
        v-bind:inBearbeitung="this.inBearbeitung" />
    </div>

    <FooterMenu />
  </div>
</template>
<style scoped>
.custom-select {
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.35355 4.06066C8.15829 3.8654 7.84171 3.8654 7.64645 4.06066L5.35355 6.35355C5.15829 6.54882 4.84171 6.54882 4.64645 6.35355C4.45118 6.15829 4.45118 5.84171 4.64645 5.64645L6.93934 3.35356C7.52513 2.76777 8.47487 2.76777 9.06066 3.35355L11.3536 5.64645C11.5488 5.84171 11.5488 6.15829 11.3536 6.35355C11.1583 6.54882 10.8417 6.54882 10.6464 6.35355L8.35355 4.06066Z' fill='currentColor'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.35355 11.9393C8.15829 12.1346 7.84171 12.1346 7.64645 11.9393L5.35355 9.64645C5.15829 9.45119 4.84171 9.45119 4.64645 9.64645C4.45118 9.84171 4.45118 10.1583 4.64645 10.3536L6.93934 12.6464C7.52513 13.2322 8.47487 13.2322 9.06066 12.6464L11.3536 10.3536C11.5488 10.1583 11.5488 9.84171 11.3536 9.64645C11.1583 9.45119 10.8417 9.45119 10.6464 9.64645L8.35355 11.9393Z' fill='currentColor'/%3E%3C/svg%3E%0A");
  background-size: 1.25em 1.25em;
  background-repeat: no-repeat;
  background-position: right 16px top 18px;
}
</style>
