<script setup>
import AppAccordion from "../components/VersorgungAccordion";
</script>

<script>
import { store } from "./store.vue";
/** Komponente zur Anzeige einer Übersicht der Versorgungsdokumente im Akkordeon
 * @displayName Anzeige Versorgungsdokumente
 */
export default {
  name: "VersorgungsDokumente",
  props: {
    /**
     * Pensionsmanagement-Nummer
     */
    PMNR: {
      type: Number,
      required: true,
      default: null,
    },
  },

  data() {
    return {
      store,
      myPMNR:
        this.PMNR === -1
          ? store.masterData.data.Versorgung.PMNR
          : store.masterData.data.Versorgung.PMNR[this.PMNR],
    };
  },
  setup() {
    //this.$servertalk.doLog("this.matchingPMNR:", this.matchingPMNR);
    //this.$servertalk.doLog(
    //  "this.documentsWithoutDokutypnr:",
    //  this.documentsWithoutDokutypnr
    //);

    return {};
  },
  computed: {
    groupedDocuments: function () {
      let grouped = {};

      this.matchingPMNR.forEach((doc) => {
        const dokutypnr = doc.document.DOKUTYPNR;

        if (!grouped[dokutypnr]) {
          grouped[dokutypnr] = [];
        }
        grouped[dokutypnr].push(doc);
      });

      return grouped;
    },
    matchingPMNR: function () {
      let xpmr = this.myPMNR["@attributes"].PMNR;
      //this.$servertalk.doLog(xpmr, "ccccccc");
      return store.masterData.docs.filter(function (p) {
        return p.document.PMNR == xpmr;
      });
    },
    sortedMatchingPMNR: function () {
      return this.matchingPMNR.slice().sort((a, b) => {
        // Annahme: TEXT ist die Eigenschaft, die die Abrechnungsnummern enthält
        const regex = /Abrechnung (\d{2})/; // Ein regulärer Ausdruck, um die Abrechnungsnummer zu extrahieren

        const getAbrechnungNumber = (str) => {
          const match = str.match(regex);
          return match ? match[1] : ""; // Extrahiere die Abrechnungsnummer als String
        };

        const numA = getAbrechnungNumber(a.document.TEXT);
        const numB = getAbrechnungNumber(b.document.TEXT);

        // Vergleiche die Abrechnungsnummern als Strings
        return numA.localeCompare(numB, undefined, {
          numeric: true,
          sensitivity: "base",
        });
      });
    },
    documentsWithoutDokutypnr: function () {
      const result = this.matchingPMNR.filter((doc) => {
        const isMatch =
          doc.document.DOKUTYPNR == null || doc.document.DOKUTYPNR === "";
        this.$servertalk.doLog(
          "Document:",
          doc.document.TEXT,
          "DOKUTYPNR:",
          doc.document.DOKUTYPNR,
          "IsMatch:",
          isMatch
        );
        return isMatch;
      });
    },
  },

  methods: {
    getDokutypnrTitle(dokutypnr) {
      //const indextable = store.indextable.TAB[6].KEY;

      const matchingKey = this.$servertalk.getName("DOKUTYP", dokutypnr);

      return matchingKey ? matchingKey : `Unbekannter DOKUTYPNR ${dokutypnr}`;
    },

    openDocument(p) {
      // Hier kannst du die Logik für das Öffnen des Dokuments implementieren
      this.$servertalk.doLog("Dokument öffnen:", p);
      // Zum Beispiel: Öffne ein Modal mit dem ausgewählten Dokument
    },
  },
};
</script>

<template>
  <app-accordion>
    <template v-slot:versorgungtitle>
      <div>
        <span class="block lg:inline">
          PM-Nr. {{ myPMNR["@attributes"].PMNR }}
        </span>
        <span class="text-tertiary lg:pl-4" v-if="myPMNR.FANR == 3">
          Unterstützungskasse
        </span>
        <span class="text-tertiary lg:pl-4" v-else>{{ myPMNR.FENAME }}</span>
      </div>
    </template>

    <template v-slot:versorgungcontent>
      <div v-if="store.masterData.docs.length > 0">
        <div
          v-for="(p, index) in sortedMatchingPMNR"
          :key="index"
          class="second-level"
        >
          {{ documentsWithoutDokutypnr }}
          <div v-if="p.document.DOKUTYPNR === ''">
            {{ p.document.TEXT }}
          </div>
        </div>

        <div
          v-for="(documents, dokutypnr) in groupedDocuments"
          :key="dokutypnr"
        >
          <!-- Hier wird ein weiteres Akkordeon für jeden Dokutypnr erstellt -->
          <app-accordion :key="dokutypnr">
            <template v-slot:versorgungtitle>
              {{ getDokutypnrTitle(dokutypnr) }}
            </template>

            <template v-slot:versorgungcontent>
              <div
                v-for="(p, index) in sortedMatchingPMNR"
                :key="index"
                class="third-level"
              >
                <!-- Hier werden die Dokumente für jeden Dokutypnr angezeigt -->
                <!-- Du kannst die Anzeige nach Bedarf anpassen -->
                <div
                  @click="$servertalk.getDBFile(p)"
                  class="document-item"
                  v-if="dokutypnr == p.document.DOKUTYPNR"
                >
                  {{ p.document.TEXT }}
                  <span class="text-sm text-tertiary">
                    ({{ p.document.TYP }})
                  </span>
                  <div class="absolute top-3 right-2 cursor-pointer">
                    <!-- SVG-Icon für das Öffnen des Dokuments -->
                    <svg
                      fill="none"
                      stroke="currentColor"
                      stroke-width="1.5"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      class="w-8 h-8"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m.75 12l3 3m0 0l3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                      ></path>
                    </svg>
                  </div>
                  <!-- Weitere Inhalte und Aktionen hier einfügen -->
                </div>
              </div>
            </template>
          </app-accordion>
        </div>
      </div>
      <div v-else>
        <div class="p-8">
          Es sind keine Dokumente zu dieser PM-NR. vorhanden.
        </div>
      </div>
    </template>
  </app-accordion>
</template>

<style scoped>
.document-item {
  @apply py-4 px-4 bg-gray-50 cursor-pointer font-bold text-primary relative hover:bg-gray-100 transition border-t border-gray-200;
}
</style>
