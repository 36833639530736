<script setup>
import HauptMenue from "../../components/HauptMenue.vue";
import FooterMenu from "../../components/FooterMenu.vue";
import DatenschutzHinweis from "../../components/DatenschutzHinweis.vue";
import ButtonSendDataSelfService from "../../components/ButtonSendDataSelfService.vue";
import ButtonResetServiceData from "../../components/ButtonResetServiceData.vue";
import CustomSelect from "../../components/CustomSelect.vue";
import Alertbox from "../../components/Alertbox.vue";
import ButtonBack from "../../components/ButtonBack.vue";
import { store } from "../../components/store.vue";
import { onMounted } from "vue";
//import { Tooltip } from "flowbite";
import {
  getNamePattern,
  getNamePatternEmpty,
  getIntegerPattern,
  getPostalCodePattern,
  getStreetAndNumberPattern,
} from "../../utility";

/** View-Komponente zum Erfassen von Änderungsaufträgen zu Finanzamtsdaten
 * @displayName Selfservice Änderung Finanzamtsdaten
 */
onMounted(() => {
  // options with default values
  /*
  const options = {
    placement: "top",
    triggerType: "hover",
  };
  */
  // instance options with default values
});
</script>
<script>
export default {
  name: "SelfServiceAenderungFirmenFinanzamtsdaten",

  components: {
    // myRouter: router,
    HauptMenue,
    FooterMenu,
    DatenschutzHinweis,
    ButtonSendDataSelfService,
    ButtonResetServiceData,
    CustomSelect,
    Alertbox,
    ButtonBack,
  },
  data() {
    return {
      store,
      saved: false,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    window._paq.push(["setDocumentTitle", this.name]);
    window._paq.push(["trackPageView"]);
  },
  unmounted() {
    if (this.saved) {
      // Nach Beauftragung Service in Draft komplett löschen
      delete store.subData.olddraft.sService.Entwuerfe
        .AenderungFirmenFinanzamtsdaten;
      delete store.subData.draft.sService.Entwuerfe
        .AenderungFirmenFinanzamtsdaten;
      this.saved = false;
    }
  },
  beforeCreate: function () {
    this.$servertalk.doLog("Create");
    this.$servertalk.doLog("Inhalt store:");
    this.$servertalk.doLog(store);
    let action = this.$servertalk.initSelfService(
      "AenderungFirmenFinanzamtsdaten",
      '{"FSTEUNR":"","FINFNAME1":"","FINFNAME2":"", "FINSTRASSE":"", "FINPLZ":"", "FINORT":"", "FINORTZUS":"", "version":"1.0"}',
      "1.1",
      store.subData
    );
    if (action % 2 == 1) {
      // olddraft wurde initialisiert
      let olddraft =
        store.subData.olddraft.sService.Entwuerfe
          .AenderungFirmenFinanzamtsdaten;
      olddraft.FSTEUNR = store.subData.data.Firmenstammdaten.FSTEUNR;
      olddraft.FINFNAME1 = store.subData.data.Finanzamt.FINFNAME1;
      olddraft.FINFNAME2 = store.subData.data.Finanzamt.FINFNAME2;
      olddraft.FINSTRASSE = store.subData.data.Finanzamt.FINSTRASSE;
      olddraft.FINPLZ = store.subData.data.Finanzamt.FINPLZ;
      olddraft.FINORT = store.subData.data.Finanzamt.FINORT;
      olddraft.FINORTZUS = store.subData.data.Finanzamt.FINORTZUS;
    }
    if (action >= 2) {
      // draft wurde initialisiert
      this.$servertalk.copyToDraftSelfService(
        "AenderungFirmenFinanzamtsdaten",
        store.subData
      );
    }
  },
  methods: {
    infoDocStatus(val) {
      this.$servertalk.doLog("infoDocStatus: ");
      this.$servertalk.doLog(val);
    },
    infoDocOk(val) {
      this.$servertalk.doLog("infoDocOk: " + val.uploadStatus);
    },
    docDocStatus(val) {
      this.$servertalk.doLog("docDocStatus: " + val.uploadStatus);
    },
    docDocOk(val) {
      this.$servertalk.doLog("docDocOk: " + val.uploadStatus);
    },
    validFSTEUNR() {
      const pattern = getIntegerPattern();
      return pattern.test(store.subData.draft.sService.Entwuerfe.AenderungFirmenFinanzamtsdaten.FSTEUNR);
    },
    validFINFNAME1() {
      const pattern = getNamePattern();
      return pattern.test(store.subData.draft.sService.Entwuerfe.AenderungFirmenFinanzamtsdaten.FINFNAME1);
    },
    validFINFNAME2() {
      const pattern = getIntegerPattern();
      return pattern.test(store.subData.draft.sService.Entwuerfe.AenderungFirmenFinanzamtsdaten.FINFNAME2);
    },
    validFINSTRASSE() {
      const pattern = getStreetAndNumberPattern();
      return pattern.test(store.subData.draft.sService.Entwuerfe.AenderungFirmenFinanzamtsdaten.FINSTRASSE);
    },
    validFINPLZ() {
      const pattern = getPostalCodePattern();
      return pattern.test(store.subData.draft.sService.Entwuerfe.AenderungFirmenFinanzamtsdaten.FINPLZ) && 
      store.subData.draft.sService.Entwuerfe.AenderungFirmenFinanzamtsdaten.FINPLZ.length > 0;
    },
    validFINORT() {
      const pattern = getNamePattern();
      return pattern.test(store.subData.draft.sService.Entwuerfe.AenderungFirmenFinanzamtsdaten.FINORT);
    },
    validFINORTZUS() {
      const pattern = getNamePatternEmpty();
      return pattern.test(store.subData.draft.sService.Entwuerfe.AenderungFirmenFinanzamtsdaten.FINORTZUS);
    },
    sendDataFinished() {
      let messageCode = this.$servertalk.sendDataSelfService(
        "AenderungFirmenFinanzamtsdaten",
        store.subData
      );
      store.message =
        messageCode == 0
          ? {
              text: "Ihre Änderungen im Bereich\n Änderung Finanzamtsdaten \n wurden erfolgreich beauftragt.",
              status: "ok",
            }
          : {
              text:
                "Beim Speichern des Auftrags im Bereich\n Änderung Finanzamtsdaten \n trat ein Fehler auf (Error-Code " +
                messageCode +
                ").",
              status: "error",
            };
      if (messageCode == 0) this.saved = true;
      this.$router.push("/auftrag-an-pensus");
    },
    resetData() {
      //this.$refs.SelectFamStand.reset(); // Zurücksetzen Select
      let messageCode = this.$servertalk.copyToDraftSelfService(
        "AenderungFirmenFinanzamtsdaten",
        store.subData
      );
      /*
            this.sFamStand = {
              text: this.$servertalk.getName(
                "FAMSTAND",
                store.subData.draft.sService.Entwuerfe.AenderungAnsprechpartnerdaten.FAMSTD
              ),
              key: store.subData.draft.sService.Entwuerfe.AenderungAnsprechpartnerdaten.FAMSTD
            };*/
      store.message =
        messageCode == 0
          ? {
              text: "Ihre Änderungen im Bereich\n Änderung Finanzamtsdaten \n wurden zurückgesetzt.",
              status: "ok",
            }
          : {
              text:
                "Beim Zurücksetzen der Daten im Bereich\n Änderung Finanzamtsdaten \n trat ein Fehler auf (Error-Code " +
                messageCode +
                ").",
              status: "error",
            };
    },
  },
  computed: {
    setSchwebendClass() {
      // Erhalte den Wert "Privatadresse" von $servertalk
      //const aenderung = this.$servertalk.gettAttr("fadata.Finanzamt");
      //const aenderungPrivatadresseValue = this.$servertalk.gettAttr("subData.draft.sService.Entwuerfe.AenderungKontaktdaten");
      return null;
    },
    setStatusClass() {
      // Erhalte den Wert "AenderungAnsprechpartnerdaten" von $servertalk
      const AenderungAnsprechpartnerdatenValue = this.$servertalk.gettAttr(
        "subData.draft.sService.Entwuerfe.AenderungFirmenFinanzamtsdaten"
      );
      return AenderungAnsprechpartnerdatenValue;
    },
    setStatusClassFSTEUNR() {
      // Erhalte den Wert "FSTEUNR" von $servertalk
      return this.$servertalk.gettAttr(
        "subData.draft.sService.Entwuerfe.AenderungFirmenFinanzamtsdaten.FSTEUNR"
      );
    },
    setStatusClassFINFNAME1() {
      // Erhalte den Wert "FINFNAME1" von $servertalk
      return this.$servertalk.gettAttr(
        "subData.draft.sService.Entwuerfe.AenderungFirmenFinanzamtsdaten.FINFNAME1"
      );
    },
    setStatusClassFINFNAME2() {
      // Erhalte den Wert "FINFNAME2" von $servertalk
      return this.$servertalk.gettAttr(
        "subData.draft.sService.Entwuerfe.AenderungFirmenFinanzamtsdaten.FINFNAME2"
      );
    },
    setStatusClassFINSTRASSE() {
      // Erhalte den Wert "FINSTRASSE" von $servertalk
      return this.$servertalk.gettAttr(
        "subData.draft.sService.Entwuerfe.AenderungFirmenFinanzamtsdaten.FINSTRASSE"
      );
    },
    setStatusClassFINPLZ() {
      // Erhalte den Wert "FINPLZ" von $servertalk
      return this.$servertalk.gettAttr(
        "subData.draft.sService.Entwuerfe.AenderungFirmenFinanzamtsdaten.FINPLZ"
      );
    },
    setStatusClassFINORT() {
      // Erhalte den Wert "FINORT" von $servertalk
      return this.$servertalk.gettAttr(
        "subData.draft.sService.Entwuerfe.AenderungFirmenFinanzamtsdaten.FINORT"
      );
    },
    setStatusClassFINORTZUS() {
      // Erhalte den Wert "FINORTZUS" von $servertalk
      return this.$servertalk.gettAttr(
        "subData.draft.sService.Entwuerfe.AenderungFirmenFinanzamtsdaten.FINORTZUS"
      );
    },
  },
};
</script>
<template>
  <div class="main-wrapper" :class="store.accessRestriction">
    <HauptMenue />
    <Transition>
      <div class="mt-36 md:mt-48 lg:mt-56 max-w-7xl mx-auto">
        <div class="max-w-5xl mx-auto mb-8 px-5 lg:px-0">
          <h1
            class="text-primary/75 font-bold text-xl sm:text-2xl md:text-3xl lg:text-4xl text-center mb-12 uppercase">
            Änderung der Finanzamtsdaten für
            <br />
            <span>
              "{{ store.subData.data.Firmenstammdaten.FNAME }}
              {{
                $servertalk.getName(
                  "GESFORM",
                  store.subData.data.Firmenstammdaten?.FGESFORM
                )
              }} {{ store.subData.data.Firmenstammdaten.FKENNR }}/{{
                store.subData.data.Firmenstammdaten.FABRGR
              }}"
            </span>
            melden
          </h1>
          <!--modal content-->
          <Alertbox />
          <div class="status-label mb-8" v-if="setStatusClass === 'modified'">
            <span
              class="bearbeitung bg-yellow-400 text-tertiary inline-block py-1 px-2 text-sm uppercase mb-2">
              Entwurf
            </span>
            <span class="infotext text-tertiary text-sm block">
              Dies ist ein Entwurf. Bitte bestätigen Sie diesen
              Änderungsauftrag, indem Sie auf
              <strong>Beauftragen</strong>
              klicken.
            </span>
          </div>
          <form :class="setStatusClass">
            <div class="grid lg:grid-cols-6 sm:gap-x-5">
              <div class="relative lg:col-span-6">
                <label for="steuernr" class="text-sm mb-2 block">
                  Steuernummer
                </label>
                <input
                  maxlength="100"
                  type="text"
                  id="steuernr"
                  :class="setStatusClassFSTEUNR"
                  class="peer w-full p-3 border border-secondary/50 rounded-md mb-8 invalid:border-red-500 invalid:bg-red-50 focus:outline-0 peer invalid:text-red-500 focus:invalid:border-red-500"
                  v-model="
                    store.subData.draft.sService.Entwuerfe
                      .AenderungFirmenFinanzamtsdaten.FSTEUNR
                  "
                  :pattern="getIntegerPattern().source" />
                <span
                  class="invisible peer-invalid:visible text-red-500 text-sm absolute bottom-2 left-0 min-w-max">
                  Der als Steuernummer angegebene Wert muss gültig sein.
                </span>
              </div>
              <div class="relative lg:col-span-4">
                <label for="finanzamt" class="text-sm mb-2 block">
                  Finanzamt
                </label>
                <input
                  maxlength="100"
                  type="text"
                  id="finanzamt"
                  :class="setStatusClassFINFNAME1"
                  class="peer w-full p-3 border border-secondary/50 rounded-md mb-8 invalid:border-red-500 invalid:bg-red-50 focus:outline-0 peer invalid:text-red-500 focus:invalid:border-red-500"
                  v-model="
                    store.subData.draft.sService.Entwuerfe
                      .AenderungFirmenFinanzamtsdaten.FINFNAME1
                  "
                  :pattern="getNamePattern().source" />
                <span
                  class="invisible peer-invalid:visible text-red-500 text-sm absolute bottom-2 left-0 min-w-max">
                  Der als Kennummer angegebene Wert muss gültig sein.
                </span>
              </div>
              <div class="relative lg:col-span-2">
                <label for="finanzamtnr" class="text-sm mb-2 block">
                  Finanzamtsnummer
                </label>
                <input
                  maxlength="10"
                  type="text"
                  id="finanzamtnr"
                  :class="setStatusClassFINFNAME2"
                  class="peer w-full p-3 border border-secondary/50 rounded-md mb-8 invalid:border-red-500 invalid:bg-red-50 focus:outline-0 peer invalid:text-red-500 focus:invalid:border-red-500"
                  v-model="
                    store.subData.draft.sService.Entwuerfe
                      .AenderungFirmenFinanzamtsdaten.FINFNAME2
                  "
                  :pattern="getIntegerPattern().source" />
                <span
                  class="invisible peer-invalid:visible text-red-500 text-sm absolute bottom-2 left-0 min-w-max">
                  Der als Finanzamtnummer angegebene Wert muss gültig sein.
                </span>
              </div>
              <div class="relative lg:col-span-6">
                <label for="finanzamtstr" class="text-sm mb-2 block">
                  Finanzamt Straße
                </label>
                <input
                  maxlength="255"
                  type="text"
                  id="finanzamtstr"
                  :class="setStatusClassFINSTRASSE"
                  class="peer w-full p-3 border border-secondary/50 rounded-md mb-8 invalid:border-red-500 invalid:bg-red-50 focus:outline-0 peer invalid:text-red-500 focus:invalid:border-red-500"
                  v-model="
                    store.subData.draft.sService.Entwuerfe
                      .AenderungFirmenFinanzamtsdaten.FINSTRASSE
                  "
                  :pattern="getStreetAndNumberPattern().source" />
                <span
                  class="invisible peer-invalid:visible text-red-500 text-sm absolute bottom-2 left-0 min-w-max">
                  Der als Straße angegebene Wert muss gültig sein.
                </span>
              </div>
              <div class="plz lg:col-span-1 relative">
                <label for="finanzamtplz" class="text-sm mb-2 block">
                  PLZ Finanzamt
                </label>
                <input
                  maxlength="255"
                  type="text"
                  id="finanzamtplz"
                  :class="setStatusClassFINPLZ"
                  class="peer w-full p-3 border border-secondary/50 rounded-md mb-8 invalid:border-red-500 invalid:bg-red-50 focus:outline-0 peer invalid:text-red-500 focus:invalid:border-red-500"
                  v-model="
                    store.subData.draft.sService.Entwuerfe
                      .AenderungFirmenFinanzamtsdaten.FINPLZ
                  "
                  :pattern="getPostalCodePattern().source" />
                <span
                  class="invisible peer-invalid:visible text-red-500 text-sm absolute bottom-2 left-0 min-w-max">
                  Der als PLZ angegebene Wert muss gültig sein.
                </span>
              </div>
              <div class="ort lg:col-span-3 relative">
                <label for="finanzamtort" class="text-sm mb-2 block">
                  Ort Finanzamt
                </label>
                <input
                  maxlength="255"
                  type="text"
                  id="finanzamtort"
                  :class="setStatusClassFINORT"
                  class="peer w-full p-3 border border-secondary/50 rounded-md mb-8 invalid:border-red-500 invalid:bg-red-50 focus:outline-0 peer invalid:text-red-500 focus:invalid:border-red-500"
                  v-model="
                    store.subData.draft.sService.Entwuerfe
                      .AenderungFirmenFinanzamtsdaten.FINORT
                  "
                  :pattern="getNamePattern().source" />
                <span
                  class="invisible peer-invalid:visible text-red-500 text-sm absolute bottom-2 left-0 min-w-max">
                  Der als Ort angegebene Wert muss gültig sein.
                </span>
              </div>
              <div class="ortzusatz lg:col-span-2 relative">
                <label for="ortzusatz" class="text-sm mb-2 block">
                  Ort Zusatz Finanzamt
                </label>
                <input
                  maxlength="255"
                  type="text"
                  id="ortzusatz"
                  :class="setStatusClassFINORTZUS"
                  class="peer w-full p-3 border border-secondary/50 rounded-md mb-8 invalid:border-red-500 invalid:bg-red-50 focus:outline-0 peer invalid:text-red-500 focus:invalid:border-red-500"
                  v-model="
                    store.subData.draft.sService.Entwuerfe
                      .AenderungFirmenFinanzamtsdaten.FINORTZUS
                  "
                  :pattern="getNamePatternEmpty().source" />
                <span
                  class="invisible peer-invalid:visible text-red-500 text-sm absolute bottom-2 left-0 min-w-max">
                  Der als Ort Zusatz angegebene Wert muss gültig sein.
                </span>
              </div>
            </div>

            <div
              class="button-wrapper flex justify-center gap-4 [&>button]:mx-0">
              <ButtonSendDataSelfService
                :disabled="
                  setStatusClass !== 'modified' ||
                  !validFSTEUNR() ||
                  !validFINFNAME1() ||
                  !validFINFNAME2() ||
                  !validFINSTRASSE() ||
                  !validFINPLZ() ||
                  !validFINORT() ||
                  !validFINORTZUS() ||
                  store.subData.isReadOnly ||
                  store.selectedFirma?.VERMITTLER == 1
                "
                @finished="sendDataFinished" />
              <ButtonResetServiceData
                :disabled="
                  setStatusClass !== 'modified' ||
                  store.subData.isReadOnly ||
                  store.selectedFirma?.VERMITTLER == 1
                "
                @reset="resetData" />
            </div>
          </form>
          <DatenschutzHinweis />
          <ButtonBack />
        </div>
      </div>
    </Transition>

    <FooterMenu />
  </div>
</template>
