<script setup>
import HauptMenue from "../../components/HauptMenue.vue";
import FooterMenu from "../../components/FooterMenu.vue";
import DatenschutzHinweis from "../../components/DatenschutzHinweis.vue";
import ButtonSendDataSelfService from "../../components/ButtonSendDataSelfService.vue";
import ButtonResetServiceData from "../../components/ButtonResetServiceData.vue";
import CustomSelect from "../../components/CustomSelect.vue";
import DokumenteHochladen from "../../components/DokumenteHochladenService.vue";
import Alertbox from "../../components/Alertbox.vue";
import ButtonBack from "../../components/ButtonBack.vue";
import { store } from "../../components/store.vue";
import { onMounted } from "vue";
//import { Tooltip } from "flowbite";
import {
  getPhoneNumberPattern,
  getNamePatternEmpty,
  getIntegerPattern,
  getMailPattern,
} from "../../utility";

/** View-Komponente zum Erfassen von Änderungsaufträgen zu Firmendaten
 * @displayName Selfservice Änderung Firmendaten
 */
onMounted(() => {
  // options with default values
  /*
  const options = {
    placement: "top",
    triggerType: "hover",
  };
  */
  // instance options with default values
});
</script>
<script>
export default {
  name: "SelfServiceAenderungFirmendaten",

  components: {
    // myRouter: router,
    HauptMenue,
    FooterMenu,
    DatenschutzHinweis,
    ButtonSendDataSelfService,
    ButtonResetServiceData,
    CustomSelect,
    Alertbox,
    ButtonBack,
  },
  data() {
    return {
      store,
      saved: false,
      sGesellschaftsformOptions: this.$servertalk.getSelectOpts("GESFORM"),
      sGesellschaftsform: {
        text: this.$servertalk.getName(
          "GESFORM",
          store.subData.data.Firmenstammdaten.FGESFORM
        ),
        key: store.subData.data.Firmenstammdaten.FGESFORM,
      },
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    window._paq.push(["setDocumentTitle", this.name]);
    window._paq.push(["trackPageView"]);
  },
  unmounted() {
    if (this.saved) {
      // Nach Beauftragung Service in Draft komplett löschen
      delete store.subData.olddraft.sService.Entwuerfe.AenderungFirmendaten;
      delete store.subData.draft.sService.Entwuerfe.AenderungFirmendaten;
      this.saved = false;
    }
  },
  beforeCreate: function () {
    this.$servertalk.doLog("Create");
    this.$servertalk.doLog("Inhalt store:");
    this.$servertalk.doLog(store);
    let action = this.$servertalk.initSelfService(
      "AenderungFirmendaten",
      '{"FKENNR":"","FNAME":"", "GTELEFON":"", "FGESFORM":"", "GEMAIL":"", "uuid":"", "uploadsDokumente":[], "version":"1.1"}',
      "1.1",
      store.subData
    );
    if (action % 2 == 1) {
      // olddraft wurde initialisiert
      let olddraft =
        store.subData.olddraft.sService.Entwuerfe.AenderungFirmendaten;
      olddraft.FKENNR = store.subData.data.Firmenstammdaten.FKENNR;
      olddraft.FNAME = store.subData.data.Firmenstammdaten.FNAME;
      olddraft.GTELEFON = store.subData.data.Geschaeftsadresse.GTELEFON;
      olddraft.FGESFORM = store.subData.data.Firmenstammdaten.FGESFORM;
      olddraft.GEMAIL = store.subData.data.Geschaeftsadresse.GEMAIL;
      olddraft.uuid = this.$servertalk.getUUID();
    }
    if (action >= 2) {
      // draft wurde initialisiert
      this.$servertalk.copyToDraftSelfService(
        "AenderungFirmendaten",
        store.subData
      );
    }
  },
  methods: {
    setGesellschaftsform(val) {
      if (val && typeof val === "object" && typeof val.value !== "undefined") {
        store.subData.draft.sService.Entwuerfe.AenderungFirmendaten.FGESFORM =
          val.value + "";
        //this.$forceUpdate(); // Erzwingt ein erneutes Rendern der Komponente
      }
    },
    infoDocStatus(val) {
      this.$servertalk.doLog("infoDocStatus: ");
      this.$servertalk.doLog(val);
    },
    infoDocOk(val) {
      this.$servertalk.doLog("infoDocOk: " + val.uploadStatus);
    },
    docDocStatus(val) {
      this.$servertalk.doLog("docDocStatus: " + val.uploadStatus);
    },
    docDocOk(val) {
      this.$servertalk.doLog("docDocOk: " + val.uploadStatus);
    },
    changeFiles(val, i) {
      this.$servertalk.doLog("changeFiles");
      this.$servertalk.doLog(val);
      store.subData.draft.sService.Entwuerfe.AenderungFirmendaten.uploadsDokumente =
        val;
    },
    validFKENNR() {
      const pattern = getIntegerPattern();
      return pattern.test(
        store.subData.draft.sService.Entwuerfe.AenderungFirmendaten.FKENNR
      );
    },
    validFNAME() {
      const pattern = getNamePatternEmpty();
      return (
        pattern.test(
          store.subData.draft.sService.Entwuerfe.AenderungFirmendaten.FNAME
        ) &&
        store.subData.draft.sService.Entwuerfe.AenderungFirmendaten.FNAME.trim()
          .length > 0
      );
    },
    validGEMAIL() {
      const pattern = getMailPattern();
      return (
        pattern.test(
          store.subData.draft.sService.Entwuerfe.AenderungFirmendaten.GEMAIL
        ) ||
        store.subData.draft.sService.Entwuerfe.AenderungFirmendaten.GEMAIL ===
        ""
      );
    },
    validGTELEFON() {
      const pattern = getPhoneNumberPattern();
      return (
        pattern.test(
          store.subData.draft.sService.Entwuerfe.AenderungFirmendaten.GTELEFON
        ) ||
        store.subData.draft.sService.Entwuerfe.AenderungFirmendaten.GTELEFON ===
        ""
      );
    },
    sendDataFinished() {
      let messageCode = this.$servertalk.sendDataSelfService(
        "AenderungFirmendaten",
        store.subData
      );
      store.message =
        messageCode == 0
          ? {
            text: "Ihre Änderungen im Bereich\n Änderung Firmendaten \n wurden erfolgreich beauftragt.",
            status: "ok",
          }
          : {
            text:
              "Beim Speichern des Auftrags im Bereich\n Änderung Firmendaten \n trat ein Fehler auf (Error-Code " +
              messageCode +
              ").",
            status: "error",
          };
      if (messageCode == 0) this.saved = true;
      this.$router.push("/auftrag-an-pensus");
    },
    resetData() {
      //this.$refs.SelectFamStand.reset(); // Zurücksetzen Select
      let messageCode = this.$servertalk.copyToDraftSelfService(
        "AenderungFirmendaten",
        store.subData
      );
      /*
            this.sFamStand = {
              text: this.$servertalk.getName(
                "FAMSTAND",
                store.subData.draft.sService.Entwuerfe.AenderungAnsprechpartnerdaten.FAMSTD
              ),
              key: store.subData.draft.sService.Entwuerfe.AenderungAnsprechpartnerdaten.FAMSTD
            };*/
      store.message =
        messageCode == 0
          ? {
            text: "Ihre Änderungen im Bereich\n Änderung Firmendaten \n wurden zurückgesetzt.",
            status: "ok",
          }
          : {
            text:
              "Beim Zurücksetzen der Daten im Bereich\n Änderung Firmendaten \n trat ein Fehler auf (Error-Code " +
              messageCode +
              ").",
            status: "error",
          };
    },
  },
  computed: {
    validAttachment() {
      return (
        store.subData.draft.sService.Entwuerfe.AenderungFirmendaten
          ?.uploadsDokumente.length !== 0
      );
    },
    setSchwebendClass() {
      // Erhalte den Wert "Privatadresse" von $servertalk
      //const aenderung = this.$servertalk.gettAttr("fadata.Firmenstammdaten");
      //const aenderungPrivatadresseValue = this.$servertalk.gettAttr("subData.draft.sService.Entwuerfe.AenderungKontaktdaten");
      return null;
    },
    combinedFirmenstammdaten() {
      // Kombiniert FKENNR und FABRGR aus dem Store und trennt mit "/"
      const { FKENNR, FABRGR } = store.subData.data.Firmenstammdaten;
      return `${FKENNR || ""}/${FABRGR || ""}`;
    },
    setStatusClass() {
      // Erhalte den Wert "AenderungAnsprechpartnerdaten" von $servertalk
      const AenderungAnsprechpartnerdatenValue = this.$servertalk.gettAttr(
        "subData.draft.sService.Entwuerfe.AenderungFirmendaten"
      );
      return AenderungAnsprechpartnerdatenValue;
    },
    setStatusClassFKENNR() {
      // Erhalte den Wert "FKENNR" von $servertalk
      return this.$servertalk.gettAttr(
        "subData.draft.sService.Entwuerfe.AenderungFirmendaten.FKENNR"
      );
    },
    setStatusClassFNAME() {
      // Erhalte den Wert "FNAME" von $servertalk
      return this.$servertalk.gettAttr(
        "subData.draft.sService.Entwuerfe.AenderungFirmendaten.FNAME"
      );
    },
    setStatusClassGTELEFON() {
      // Erhalte den Wert "GTELEFON" von $servertalk
      return this.$servertalk.gettAttr(
        "subData.draft.sService.Entwuerfe.AenderungFirmendaten.GTELEFON"
      );
    },
    setStatusClassFGESFORM() {
      // Erhalte den Wert "FGESFORM" von $servertalk
      return this.$servertalk.gettAttr(
        "subData.draft.sService.Entwuerfe.AenderungFirmendaten.FGESFORM"
      );
    },
    setStatusClassGEMAIL() {
      // Erhalte den Wert "GEMAIL" von $servertalk
      return this.$servertalk.gettAttr(
        "subData.draft.sService.Entwuerfe.AenderungFirmendaten.GEMAIL"
      );
    },
  },
};
</script>
<template>
  <div class="main-wrapper" :class="store.accessRestriction">
    <HauptMenue />
    <Transition>
      <div class="mt-36 md:mt-48 lg:mt-56 max-w-7xl mx-auto">
        <div class="max-w-5xl mx-auto mb-8 px-5 lg:px-0">
          <h1 class="text-primary/75 font-bold text-xl sm:text-2xl md:text-3xl lg:text-4xl text-center mb-12 uppercase">
            Änderung der Firmen-Stammdaten für
            <br />
            <span>
              "{{ store.subData.data.Firmenstammdaten.FNAME }}
              {{
                $servertalk.getName(
                  "GESFORM",
                  store.subData.data.Firmenstammdaten?.FGESFORM
                )
              }} {{ store.subData.data.Firmenstammdaten.FKENNR }}/{{
                store.subData.data.Firmenstammdaten.FABRGR
              }}"
            </span>
            melden
          </h1>
          <!--modal content-->
          <Alertbox />
          <div class="status-label mb-8" v-if="setStatusClass === 'modified'">
            <span class="bearbeitung bg-yellow-400 text-tertiary inline-block py-1 px-2 text-sm uppercase mb-2">
              Entwurf
            </span>
            <span class="infotext text-tertiary text-sm block">
              Dies ist ein Entwurf. Bitte bestätigen Sie diesen
              Änderungsauftrag, indem Sie auf
              <strong>Beauftragen</strong>
              klicken.
            </span>
          </div>
          <form :class="setStatusClass">
            <div class="grid lg:grid-cols-12 lg:gap-5">
              <div class="relative lg:col-span-3">
                <label for="aendKennnr" class="text-sm mb-2 block">
                  Kennnummer/Abrechnungsgruppe
                </label>
                <input maxlength="10" type="text" id="aendKennnr" :class="setStatusClassFKENNR" disabled="true"
                  class="peer w-full p-3 border border-secondary/50 rounded-md mb-8 invalid:border-red-500 invalid:bg-red-50 focus:outline-0 peer invalid:text-red-500 focus:invalid:border-red-500"
                  v-model="combinedFirmenstammdaten" :pattern="getIntegerPattern().source" />
              </div>
              <div class="relative lg:col-span-5 schwebend">
                <label for="aendName" class="text-sm mb-2 block">
                  Firmenname
                </label>
                <input maxlength="100" type="text" id="aendName" :class="setStatusClassFNAME"
                  class="peer w-full p-3 border border-secondary/50 rounded-md mb-8 invalid:border-red-500 invalid:bg-red-50 focus:outline-0 peer invalid:text-red-500 focus:invalid:border-red-500"
                  v-model="store.subData.draft.sService.Entwuerfe.AenderungFirmendaten
                      .FNAME
                    " :pattern="getNamePatternEmpty().source" required />
                <span class="invisible peer-invalid:visible text-red-500 text-sm absolute bottom-2 left-0 min-w-max">
                  Der als Firmenname angegebene Wert muss gültig sein.
                </span>
              </div>
              <div class="relative lg:col-span-4">
                <label for="firmenname" class="text-sm mb-2 block">
                  Gesellschaftsform
                </label>
                <CustomSelect @input="setGesellschaftsform" class="select schwebend" :class="setStatusClassFGESFORM"
                  :options="sGesellschaftsformOptions" :default="store.subData.draft.sService.Entwuerfe.AenderungFirmendaten
                      .FGESFORM
                    " :oldDefault="store.subData.olddraft.sService.Entwuerfe
                      .AenderungFirmendaten.FGESFORM
                    " placeholder="Keine" />
              </div>
            </div>
            <div class="grid lg:grid-cols-2 sm:gap-5">
              <div class="relative">
                <label for="aendMail" class="text-sm mb-2 block">
                  E-Mail Adresse
                </label>
                <input maxlength="255" type="email" id="aendMail" :class="setStatusClassGEMAIL"
                  class="peer w-full p-3 border border-secondary/50 rounded-md mb-8 invalid:border-red-500 invalid:bg-red-50 focus:outline-0 peer invalid:text-red-500 focus:invalid:border-red-500"
                  v-model="store.subData.draft.sService.Entwuerfe.AenderungFirmendaten
                      .GEMAIL
                    " :pattern="getMailPattern().source" />
                <span class="invisible peer-invalid:visible text-red-500 text-sm absolute bottom-2 left-0 min-w-max">
                  Der als Mail-Adresse angegebene Wert muss gültig sein.
                </span>
              </div>
              <div class="relative">
                <label for="aendTelefon" class="text-sm mb-2 block">
                  Telefon
                </label>
                <input maxlength="100" type="text" id="aendTelefon" :class="setStatusClassGTELEFON"
                  class="peer w-full p-3 border border-secondary/50 rounded-md mb-8 invalid:border-red-500 invalid:bg-red-50 focus:outline-0 peer invalid:text-red-500 focus:invalid:border-red-500"
                  v-model="store.subData.draft.sService.Entwuerfe.AenderungFirmendaten
                      .GTELEFON
                    " :pattern="getPhoneNumberPattern().source" />
                <span class="invisible peer-invalid:visible text-red-500 text-sm absolute bottom-2 left-0 min-w-max">
                  Bitte geben Sie eine gültige Telefonnummer ein.
                </span>
              </div>
            </div>
            <div v-if="setStatusClassFGESFORM == 'modified'">
              <label class="text-sm mb-2 block">
                Handelregistereintrag
                <sup>*</sup>
              </label>
              <DokumenteHochladen :formId="store.subData.draft.sService.Entwuerfe.AenderungFirmendaten.uuid"
                :defaultData="store.subData.draft.sService.Entwuerfe.AenderungFirmendaten.uploadsDokumente" maxfiles="1"
                minfiles="1" infoText="AenderungFirmenData" docArt="Docs" @status="docDocStatus" @isOk="docDocOk"
                @newfiles="changeFiles" @change="changeFiles" />
            </div>
            <div class="button-wrapper flex justify-center gap-4 [&>button]:mx-0">
              <ButtonSendDataSelfService :disabled="setStatusClass !== 'modified' ||
                !validFKENNR() ||
                !validFNAME() ||
                !validGTELEFON() ||
                !validGEMAIL() ||
                (!validAttachment && setStatusClassFGESFORM == 'modified') ||
                store.subData.isReadOnly ||
                store.selectedFirma?.VERMITTLER == 1
                " @finished="sendDataFinished" />
              <ButtonResetServiceData :disabled="setStatusClass !== 'modified' ||
                store.subData.isReadOnly ||
                store.selectedFirma?.VERMITTLER == 1
                " @reset="resetData" />
            </div>
          </form>
          <DatenschutzHinweis />
          <ButtonBack />
        </div>
      </div>
    </Transition>

    <FooterMenu />
  </div>
</template>
