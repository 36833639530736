<script setup>
import { store } from "./store.vue";
import TimerComponent from "./TimerComponent.vue";
import ResetTimerComponent from "./ResetTimerComponent.vue";
import FontSizeComponent from "./FontSizeComponent.vue";

import { onMounted } from "vue";
import { Dropdown } from "flowbite";
/** Hauptmenü im Kopfbereich der Seite
 * @displayName Hauptmenü
 */
onMounted(() => {
  // set the dropdown menu element
  const $droptargetEl = document.getElementById("dropdownHover");
  const $droptargetVersorgungEl = document.getElementById(
    "dropdownVersorgungHover"
  );
  const $droptargetFirmaEl = document.getElementById("dropdownFirmaHover");
  // set the element that trigger the dropdown menu on click
  const $droptriggerEl = document.getElementById("dropdownHoverButton");
  const $droptriggerVersorgungEl = document.getElementById(
    "dropdownVersorgungHoverButton"
  );
  const $droptriggerFirmaEl = document.getElementById(
    "dropdownFirmaHoverButton"
  );

  const $droptargetZugangsdatenEl = document.getElementById(
    "dropdownZugangsdaten"
  );
  const $droptriggerZugangsdatenEl = document.getElementById(
    "dropdownZugangsdatenHoverButton"
  );
  // options with default values
  const dropoptions = {
    placement: "bottom",
    activeClasses: "text-primary",
    triggerType: "click",
    offsetSkidding: 0,
    offsetDistance: 20,
    delay: 300,
    onShow: () => {
      if ($droptriggerEl) {
        $droptriggerEl.classList.add("dropdown-open");
      }
    },
    onHide: () => {
      $droptriggerEl.classList.remove("dropdown-open");
    },
  };

  const dropVersorgungoptions = {
    placement: "bottom",
    activeClasses: "text-primary",
    triggerType: "click",
    offsetSkidding: 0,
    offsetDistance: 20,
    delay: 300,
    onShow: () => {
      document
        .getElementById("dropdownVersorgungHoverButton")
        .classList.add("dropdown-open");
    },
    onHide: () => {
      document
        .getElementById("dropdownVersorgungHoverButton")
        .classList.remove("dropdown-open");
    },
  };

  const dropFirmaoptions = {
    placement: "bottom",
    activeClasses: "text-primary",
    triggerType: "click",
    offsetSkidding: 0,
    offsetDistance: 20,
    delay: 300,
    onShow: () => {
      document
        .getElementById("dropdownFirmaHoverButton")
        .classList.add("dropdown-open");
    },
    onHide: () => {
      document
        .getElementById("dropdownFirmaHoverButton")
        .classList.remove("dropdown-open");
    },
  };

  const dropZugangsdatenoptions = {
    placement: "bottom",
    activeClasses: "text-primary",
    triggerType: "click",
    offsetSkidding: 0,
    offsetDistance: 10,
    delay: 300,
  };

  const dropdown = new Dropdown($droptargetEl, $droptriggerEl, dropoptions);
  const dropdownVersorgung = new Dropdown(
    $droptargetVersorgungEl,
    $droptriggerVersorgungEl,
    dropVersorgungoptions
  );

  const dropdownFirma = new Dropdown(
    $droptargetFirmaEl,
    $droptriggerFirmaEl,
    dropFirmaoptions
  );

  const dropdownZugangsdaten = new Dropdown(
    $droptargetZugangsdatenEl,
    $droptriggerZugangsdatenEl,
    dropZugangsdatenoptions
  );

  // check if dropdown is visible/open
  if (dropdown) {
    dropdown.hide();
  }
  if (dropdownZugangsdaten) {
    dropdownZugangsdaten.hide();
  }
  if (dropdownVersorgung) {
    dropdownVersorgung.hide();
  }
  if (dropdownFirma) {
    dropdownFirma.hide();
  }
});
</script>
<script>
export default {
  name: "HauptMenue",
  components: {
    TimerComponent,
    ResetTimerComponent,
    FontSizeComponent,
  },
  props: {
    MSGIDENT: Object,
  },
  data() {
    return {
      store,
      show: false,
      timeResetToggler: false,
      active: false,
      mobileactive: false,
      //messages: store.masterData.data.Message.MSGIDENT,
      messages:
        typeof store.masterData.data.Message?.MSGIDENT == "undefined" ||
        Array.isArray(store.masterData.data.Message?.MSGIDENT)
          ? store.masterData.data.Message.MSGIDENT
          : [store.masterData.data.Message.MSGIDENT],
      famessages:
        typeof store.subData?.data?.Message?.MSGIDENT == "undefined" ||
        Array.isArray(store.subData?.data?.Message?.MSGIDENT)
          ? store.subData?.data?.Message?.MSGIDENT
          : [store.subData?.data?.Message?.MSGIDENT],
    };
  },
  computed: {
    isDataSaved() {
      return (
        store.masterData.datasent == 0 &&
        (this.isPerson || store.subData.datasent <= 0)
      );
    },

    isDataPending() {
      return (
        store.masterData.datasent > 0 ||
        (!this.isPerson && store.subData.datasent > 0)
      );
    },

    showBankverbindung() {
      return this.$servertalk.checkRentner();
    },
    nrFilteredMessages() {
      // Kombiniere die beiden Arrays
      const msgs = [...(this.messages || []), ...(this.famessages || [])];

      let cntMsg = 0;

      // Überprüfe, ob Nachrichten vorhanden sind und zähle die ungelesenen
      msgs.forEach((msg) => {
        // Überprüfen der Bedingungen für ungelesene Nachrichten
        if (msg["MSGGELESEN"] === "0" && msg["MSGDELETE"] === "0") {
          cntMsg++;
        }
      });

      return cntMsg; // Anzahl der ungelesenen Nachrichten zurückgeben
    },

    /* SessionID() {
      return this.$servertalk.getSessionID();
    }, */
    isDisabled() {
      return (
        this.$servertalk.gettValue("masterData.data.Stammdaten.IS_PWD_INIT") ==
          "1" ||
        this.$servertalk.gettValue("masterData.data.Stammdaten.IS_USER_INIT") ==
          "1"
      );
    },
    inSubmenue() {
      const subMenuRoutes = [
        "persoenlichedaten",
        "persoenlichedatenansprechpartner",
        "meinebankverbindung",
        "zugangsdatenaendern",
        //"zugriffshistorie",
        "kontaktdaten",
      ];
      const currRoute = this.$route.name;
      return subMenuRoutes.includes(currRoute);
    },
    inSubmenueFirma() {
      const subMenuRoutes = ["firmenstammdaten", "firmenbankdaten"];
      const currRoute = this.$route.name;
      return subMenuRoutes.includes(currRoute);
    },
    inSubmenueVersorgung() {
      const subMenuRoutes = ["versorgungsdaten", "versorgungsdokumente"];
      const currRoute = this.$route.name;
      return subMenuRoutes.includes(currRoute);
    },
    isAuftrag() {
      const auftragRoutes = [
        "sservice-aenderung-persoenlichedaten",
        "sservice-aenderung-ansprechpartnerdaten",
        "sservice-aenderung-bankverbindung",
        "sservice-aenderung-kontaktdaten",
        "sservice-antrag-betriebsrente",
        "sservice-antrag-vorausberechnung",
        "sservice-aenderung-beitrag",
        "sservice-erfassung-steuerid",
        "kontaktformular",
        "sservice-beitragsfreistellung",
        "sservice-firmenaustritt",
        "sservice-leistungsmeldung",
        "sservice-wiederinkraftsetzung",
        "sservice-teilnahme-beiratsversammlung",
      ];
      const currRoute = this.$route.name;
      return auftragRoutes.includes(currRoute);
    },
    /* userName() {
      return `${
        this.store.masterData.data.Stammdaten?.VORNAME ?? ""
      } ${
        this.store.masterData.data.Stammdaten
          ?.NAME ?? ""
      }`;
    }, */
    isPerson() {
      return store.satzart === "person";
    },
    isAnsprechpartner() {
      return store.satzart === "ansprechpartner";
    },
    /*async mounted() {
      await this.loadUserData(); // Annahme: loadUserData ist eine Funktion, die die Daten lädt
      this.vueOnScroll();
    },*/
  },
  methods: {
    doLogout() {
      if (this.isDataSaved) {
        this.$servertalk.logoutSystem();
      } else {
        console.log("abmelden nicht erlaubt");
      }
    },
    /* doResetTimer() {
      // Toggle Eigenschaft bei Reset-Event aus ResetTimerComponent
      this.timeResetToggler = !this.timeResetToggler;
    }, */
    vueOnScroll() {
      var prev = window.scrollY;
      const refs = this.$refs.headRef; // assign the reference in variable
      window.addEventListener("scroll", () => {
        var curr = window.scrollY;
        if (prev < curr) {
          refs.classList.add("shrink");
        } else {
          refs.classList.remove("shrink");
        }
        if (curr === 0) {
          refs.classList.remove("shrink");
        }
        prev = curr;
      });
    },
  },
  mounted() {
    // run the function when the component's mount
    this.vueOnScroll();
    //console.log("Messages:", this.messages);
    //console.log("Firma Messages:", this.famessages);
  },
  watch: {
    // whenever active changes, this function will run
    mobileactive: function () {
      var wrapper = document.querySelector(".main-wrapper");
      if (this.mobileactive) {
        wrapper.classList.add("mobile-open");
        wrapper.classList.remove("mobile-close");
      } else {
        wrapper.classList.add("mobile-close");
        wrapper.classList.remove("mobile-open");
      }
    },
    "store.masterData.data.Message.MSGIDENT": {
      handler(newVal) {
        if (typeof newVal === "undefined" || Array.isArray(newVal)) {
          this.messages = newVal || [];
        } else {
          this.messages = [newVal];
        }
      },
      immediate: true, // Überprüft sofort, nicht nur bei Änderungen
    },
    "store.subData.data.Message.MSGIDENT": {
      handler(newVal) {
        if (typeof newVal === "undefined" || Array.isArray(newVal)) {
          this.famessages = newVal || [];
        } else {
          this.famessages = [newVal];
        }
      },
      immediate: true,
    },
  },
};
</script>
<template>
  <div
    class="fixed-sidebar hidden lg:block lg:fixed right-0 bottom-24 lg:top-1/2 z-[9999]"
    :class="{ hidden: !isPerson }">
    <div class="fixed-sidebar-wrapper">
      <div
        class="fixed-sidebar-content flex flex-col relative items-center -top-8 mb-1"
        :class="isDisabled ? 'lg:hidden' : ''">
        <router-link
          active-class="font-bold"
          to="/pensus-faq"
          class="phone-icon w-16 h-16 px-3 text-white overflow-hidden absolute bg-primary flex transition-all duration-500 right-0 hover:w-[230px] items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            class="w-10 h-10 inline-block shrink-0 fill-white">
            <path
              d="M256 40c118.621 0 216 96.075 216 216 0 119.291-96.61 216-216 216-119.244 0-216-96.562-216-216 0-119.203 96.602-216 216-216m0-32C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm-36 344h12V232h-12c-6.627 0-12-5.373-12-12v-8c0-6.627 5.373-12 12-12h48c6.627 0 12 5.373 12 12v140h12c6.627 0 12 5.373 12 12v8c0 6.627-5.373 12-12 12h-72c-6.627 0-12-5.373-12-12v-8c0-6.627 5.373-12 12-12zm36-240c-17.673 0-32 14.327-32 32s14.327 32 32 32 32-14.327 32-32-14.327-32-32-32z" />
          </svg>

          <span class="pl-3 whitespace-nowrap">
            Benötigen Sie Hilfe
            <br />
            beim Serviceportal?
          </span>
        </router-link>
      </div>
      <div
        class="fixed-sidebar-content flex flex-col relative items-center top-8"
        :class="isDisabled ? 'lg:hidden' : ''">
        <router-link
          active-class="font-bold"
          to="/kontaktformular"
          class="phone-icon w-16 h-16 px-3 text-white overflow-hidden absolute bg-primary flex transition-all duration-500 right-0 hover:w-[230px] items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            class="w-10 h-10 inline-block shrink-0 fill-white">
            <path
              d="M336 137.4c-12.5-12.5-32.8-12.5-45.2 0l-126.1 126c-2 2-3.4 4.5-4.2 7.3l-16 61.2c-1.4 5.5.1 11.3 4.2 15.4 3 3 7.1 4.7 11.3 4.7 1.3 0 2.7-.2 4-.5l61.2-16c2.8-.7 5.3-2.2 7.3-4.2l126.1-126.1c12.5-12.5 12.5-32.8 0-45.2L336 137.4zM213 305.6l-30.6 8 8-30.6 75-75 22.6 22.6-75 75zm97.6-97.6L288 185.4l25.4-25.4 22.6 22.6-25.4 25.4zM256 32C114.6 32 0 125.1 0 240c0 47.6 19.9 91.2 52.9 126.3C38 405.7 7 439.1 6.5 439.5c-6.6 7-8.4 17.2-4.6 26S14.4 480 24 480c61.5 0 110-25.7 139.1-46.3C192 442.8 223.2 448 256 448c141.4 0 256-93.1 256-208S397.4 32 256 32zm0 384c-28.3 0-56.3-4.3-83.2-12.8l-15.2-4.8-13 9.2c-23 16.3-58.5 35.3-102.6 39.6 12-15.1 29.8-40.4 40.8-69.6l7.1-18.7-13.7-14.6C47.3 313.7 32 277.6 32 240c0-97 100.5-176 224-176s224 79 224 176-100.5 176-224 176z" />
          </svg>

          <span class="pl-3 whitespace-nowrap">
            So kommen wir in
            <br />
            Kontakt
          </span>
        </router-link>
      </div>
    </div>
  </div>
  <div
    class="above-header lg:px-5 h-[46px] flex justify-between text-secondary fixed w-full z-40 min-[1921px]:max-w-[1600px] max-w-[1920px]"
    :class="{
      'bg-red-50': store.masterData.isReadOnly,
      'bg-slate-100': !store.masterData.isReadOnly,
    }">
    <div class="hidden lg:flex mx-auto justify-between items-center w-full">
      <div class="left flex items-center">
        <div
          class="user-logout gap-2 group hover:text-black transition-all duration-500 lg:flex mr-5 items-center"
          :class="{
            'cursor-default opacity-50 hover:text-secondary': isDataPending > 0,
            'cursor-pointer': isDataSaved,
          }"
          @click="doLogout">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            class="w-4 h-4 rotate-180 fill-primary"
            :class="{
              hidden: isDataPending,
              block: isDataSaved,
            }">
            <path
              d="M96 64h84c6.6 0 12 5.4 12 12v24c0 6.6-5.4 12-12 12H96c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h84c6.6 0 12 5.4 12 12v24c0 6.6-5.4 12-12 12H96c-53 0-96-43-96-96V160c0-53 43-96 96-96zm231.1 19.5l-19.6 19.6c-4.8 4.8-4.7 12.5.2 17.1L420.8 230H172c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h248.8L307.7 391.7c-4.8 4.7-4.9 12.4-.2 17.1l19.6 19.6c4.7 4.7 12.3 4.7 17 0l164.4-164c4.7-4.7 4.7-12.3 0-17l-164.4-164c-4.7-4.6-12.3-4.6-17 .1z" />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            class="animate-spin opacity-50 w-4 h-4 text-secondary"
            :class="{
              block: isDataPending,
              hidden: isDataSaved,
            }">
            <path
              d="M500 8h-27.711c-6.739 0-12.157 5.548-11.997 12.286l2.347 98.575C418.212 52.043 342.256 8 256 8 134.813 8 33.933 94.924 12.296 209.824 10.908 217.193 16.604 224 24.103 224h28.576c5.674 0 10.542-3.982 11.737-9.529C83.441 126.128 161.917 60 256 60c79.545 0 147.942 47.282 178.676 115.302l-126.39-3.009c-6.737-.16-12.286 5.257-12.286 11.997V212c0 6.627 5.373 12 12 12h192c6.627 0 12-5.373 12-12V20c0-6.627-5.373-12-12-12zm-12.103 280h-28.576c-5.674 0-10.542 3.982-11.737 9.529C428.559 385.872 350.083 452 256 452c-79.546 0-147.942-47.282-178.676-115.302l126.39 3.009c6.737.16 12.286-5.257 12.286-11.997V300c0-6.627-5.373-12-12-12H12c-6.627 0-12 5.373-12 12v192c0 6.627 5.373 12 12 12h27.711c6.739 0 12.157-5.548 11.997-12.286l-2.347-98.575C93.788 459.957 169.744 504 256 504c121.187 0 222.067-86.924 243.704-201.824 1.388-7.369-4.308-14.176-11.807-14.176z" />
          </svg>
          abmelden
        </div>
        <TimerComponent
          :timeResetToggler="timeResetToggler"
          @loggedOutByTime="doLogout"
          class="mr-8" />

        <FontSizeComponent />
      </div>
      <div
        v-if="store.masterData.isReadOnly"
        class="fixed bottom-0 left-0 px-5 py-3 bg-primary gap-5 flex text-white">
        Ihre Daten werden aktuell an anderer Stelle bearbeitet.
      </div>
      <div
        v-if="store.subData.isLoaded && !isPerson"
        class="right gap-2 flex text-primary">
        <span v-if="!store.subData.isReadOnly">Sie bearbeiten:</span>
        <span
          v-if="
            store.subData.isReadOnly 
          ">
          Schreibgeschützt:
        </span>
        <span
        v-if="
          store.selectedFirma?.VERMITTLER == 1
        ">
        nur zur Ansicht:
      </span>
        {{ store.subData.data.Firmenstammdaten.FNAME }}
        {{
          $servertalk.getName(
            "GESFORM",
            store.subData.data.Firmenstammdaten?.FGESFORM
          )
        }}
        {{ store.subData.data.Firmenstammdaten.FKENNR }} /
        {{ store.subData.data.Firmenstammdaten.FABRGR }}
      </div>
      <div class="right gap-5 flex">
        <div
          class="user-name flex gap-3 transition cursor-pointer duration-500 hover:text-black"
          id="dropdownZugangsdatenHoverButton"
          v-if="store.masterData.data.Stammdaten">
          <svg
            fill="none"
            stroke="currentColor"
            stroke-width="1.5"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            class="w-6 text-primary">
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"></path>
          </svg>
          {{ store.masterData.data.Stammdaten.TITEL }}
          {{ store.masterData.data.Stammdaten.VORNAME }}
          {{ store.masterData.data.Stammdaten.NAMEZUS }}
          {{ store.masterData.data.Stammdaten.NAMEVS }}
          {{ store.masterData.data.Stammdaten.NAME }}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-4 h-6">
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
          </svg>
          <div
            id="dropdownZugangsdaten"
            class="z-10 hidden lg:bg-white lg:rounded-md lg:shadow-md">
            <ul
              class="py-1 text-base"
              aria-labelledby="dropdownHoverZugangsdaten">
              <li>
                <router-link
                  active-class="font-bold"
                  to="/zugangsdaten-aendern/both"
                  class="block px-4 py-1">
                  Zugangsdaten Ändern
                </router-link>
              </li>
            </ul>
            <div id="arrow" data-popper-arrow></div>
          </div>
        </div>
        <router-link
          to="/persoenliches-postfach"
          v-if="!isDisabled"
          class="postfachlink">
          <div
            class="postfach flex transition cursor-pointer duration-500 hover:text-black relative">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6 text-primary mr-3">
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
            </svg>
            <span
              class="absolute flex h-4 w-4 left-4 -top-1"
              v-if="nrFilteredMessages > 0 && store.subData.isLoaded">
              <span
                class="animate-ping absolute inline-flex h-full w-full rounded-full bg-primary opacity-75"></span>
              <span
                class="relative grid place-content-center rounded-full h-4 w-4 bg-primary text-white text-[0.5rem]">
                {{ nrFilteredMessages }}
              </span>
            </span>

            Postfach

            <!--
          <a
            href="mailto:info@pensus.de"
            class="hover:text-black transition-all duration-500"
          >
            info@pensus.de
          </a>
        </div>
        <div class="tel flex gap-1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6 text-primary"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
            />
          </svg>
          <a
            href="tel:+4955170153772"
            class="hover:text-black transition-all duration-500"
          >
            +49&nbsp;(0)551&nbsp;701537-72
          </a>
             -->
          </div>
        </router-link>
      </div>
    </div>
  </div>
  <div
    class="above-header-mobile flex lg:hidden h-10 fixed top-0 z-50 w-full shadow-lg items-center justify-between px-4"
    :class="{
      'bg-red-50': store.masterData.isReadOnly,
      'bg-tertiary': !store.masterData.isReadOnly,
    }">
    <a href="#" @click="doLogout">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-6 h-6 text-white"
        :class="{ 'stroke-primary': store.masterData.isReadOnly }">
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75" />
      </svg>
    </a>
    <div
      v-if="store.masterData.isReadOnly"
      class="fixed bottom-0 left-0 px-5 py-3 bg-primary gap-5 flex text-white">
      Ihre Daten werden aktuell an anderer Stelle bearbeitet.
    </div>

    <!--<a href="tel:+4955170153772">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-6 h-6 text-white"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
        />
      </svg>
    </a>-->
    <router-link to="/persoenliches-postfach" class="postfachlink">
      <div
        class="postfach flex transition cursor-pointer duration-500 hover:text-black">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6 text-white"
          :class="{ 'stroke-primary': store.masterData.isReadOnly }">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
        </svg>
        <span
          class="relative flex h-4 w-4 right-1 -top-1"
          v-if="nrFilteredMessages > 0 && store.subData.isLoaded">
          <span
            class="animate-ping absolute inline-flex h-full w-full rounded-full bg-primary opacity-75"></span>
          <span
            class="relative grid place-content-center rounded-full h-4 w-4 bg-primary text-white text-[0.5rem]">
            {{ nrFilteredMessages }}
          </span>
        </span>
      </div>
    </router-link>
  </div>
  <header
    class="header px-5 xl:px-0 transition-all duration-1000 lg:border-y border-primary fixed min-[1921px]:max-w-[1600px] max-w-[1920px] w-full flex top-[41px] lg:top-0 flex-col lg:flex-row container h-[60px] md:h-[100px] mx-auto lg:gap-16 lg:items-center mb-0 justify-center lg:mt-[46px] bg-gray-50 lg:bg-white z-30 border-b lg:border-0"
    ref="headRef">
    <!--<div><small><span v-if="store.masterData.isReadOnly">readonly<br/></span><span>{{SessionID}}</span></small></div>-->
    <div
      class="mobile-menu block lg:hidden absolute lg:static right-5 top-2 md:top-7 lg:top-5 z-40 cursor-pointer w-8 h-8"
      :class="{ active: mobileactive, hidden: isDisabled }"
      @click="mobileactive = !mobileactive">
      <span
        class="w-8 h-1 rounded-full bg-primary absolute top-[10px] transition-all duration-500"></span>
      <span
        class="w-8 h-1 rounded-full bg-primary absolute top-[20px] transition-all duration-500"></span>
      <span
        class="w-8 h-1 rounded-full bg-primary absolute top-[30px] transition-all duration-500"></span>
    </div>
    <div
      class="header-wrapper w-full max-w-screen-2xl xl:px-5 lg:flex gap-8 mx-auto items-center">
      <router-link
        to="/startseite"
        :class="{ 'pointer-events-none': isDisabled }">
        <img
          class="w-40 md:w-48 xl:w-72 h-auto mb-0 transition-all duration-500"
          src="/assets/img/pensus-logo.svg"
          alt="Pensus Logo" />
      </router-link>
      <div
        class="tab-bar lg:mb-0 h-0 lg:h-auto group-hover:h-auto overflow-hidden lg:overflow-visible lg:flex gap-8 xl:gap-4 ml-auto duration-500 transition-all fixed lg:static left-0 top-[100px] lg:top-auto w-full lg:w-auto"
        :class="
          mobileactive
            ? 'opacity-100'
            : 'h-0 opacity-0 lg:h-auto lg:opacity-100'
        ">
        <div
          class="container max-w-7xl mx-auto text-3xl lg:text-lg text-tertiary">
          <ul
            class="lg:flex px-5 lg:px-0 gap-x-4 xl:gap-x-8 items-end"
            :class="isDisabled ? 'lg:hidden' : ''">
            <li>
              <router-link
                active-class="lg:after:h-[3px]"
                class="relative cursor-pointer after:transition-all after:duration-1000 after:absolute after:w-full lg:after:-bottom-9 lg:focus:after:h-[3px] lg:after:active:h-[3px] after:left-0 after:bg-primary after:h-0 transition duration-500 pb-4 lg:pb-0 block"
                :class="{
                  'scaled-up-half': store.fontfactor >= 1.05,
                  'scaled-up-full': store.fontfactor >= 1.1,
                }"
                to="/startseite">
                Startseite
              </router-link>
            </li>
            <div>
              <li
                id="dropdownHoverButton"
                data-dropdown-toggle="dropdownHover"
                data-dropdown-trigger="hover"
                class="flex justify-between items-center lg:block cursor-pointer relative after:transition-all after:duration-1000 after:absolute after:w-full lg:after:-bottom-9 after:z-10 focus:after:h-0.5 after:active:h-0.5 after:left-0 after:bg-transparent after:h-0 transition duration-500 pb-4 lg:pb-0"
                :class="{
                  'lg:after:h-[3px] lg:after:bg-primary': inSubmenue,
                  'scaled-up-half': store.fontfactor >= 1.05,
                  'scaled-up-full': store.fontfactor >= 1.1,
                }"
                type="button">
                Meine Daten
                <div
                  class="toggle block lg:hidden relative before:h-1 after:transition-all after:duration-500 before:w-8 before:bg-primary before:absolute before:right-0 after:h-1 after:w-8 after:bg-primary after:absolute after:right-0 after:-rotate-90 before:rounded after:rounded"></div>
              </li>
              <div
                id="dropdownHover"
                class="z-10 block lg:bg-gray-50 lg:rounded-lg lg:shadow-md">
                <ul
                  class="grid pb-2 lg:py-2 text-xl lg:text-base lg:font-medium relative"
                  :class="isPerson ? 'grid-cols-2' : 'grid-cols-1'"
                  aria-labelledby="dropdownHoverButton">
                  <li v-if="isPerson">
                    <router-link
                      active-class="font-bold"
                      to="/persoenlichedaten"
                      class="block lg:px-4 py-2 border-r border-gray-100">
                      Persönliche Daten
                    </router-link>
                  </li>
                  <li v-if="isAnsprechpartner">
                    <router-link
                      active-class="font-bold"
                      to="/persoenlichedatenansprechpartner"
                      class="block lg:px-4 py-2 border-r border-gray-100">
                      Persönliche Daten
                    </router-link>
                  </li>

                  <li v-if="showBankverbindung && isPerson">
                    <router-link
                      active-class="font-bold"
                      to="/meinebankverbindung"
                      class="block lg:px-4 py-2">
                      Meine Bankverbindung
                    </router-link>
                  </li>
                  <li v-if="isPerson">
                    <router-link
                      active-class="font-bold"
                      to="/kontaktdaten"
                      class="block lg:px-4 py-2 border-r border-gray-100">
                      Kontaktdaten
                    </router-link>
                  </li>
                  <li v-if="isPerson">
                    <router-link
                      active-class="font-bold"
                      to="/krankenkassendaten"
                      class="block lg:px-4 py-2 border-r border-gray-100">
                      Meine Krankenkasse
                    </router-link>
                  </li>
                  <li>
                    <router-link
                      active-class="font-bold"
                      to="/zugangsdaten-aendern/both"
                      class="block lg:px-4 py-2"
                      :class="
                        showBankverbindung ? 'border-r border-gray-100' : ''
                      ">
                      Zugangsdaten ändern
                    </router-link>
                  </li>
                </ul>
                <div id="arrow" data-popper-arrow></div>
              </div>
            </div>
            <div
              :class="{
                hidden:
                  isPerson || !store.subData.data?.Firmenstammdaten?.FNAME,
              }">
              <li
                id="dropdownFirmaHoverButton"
                data-dropdown-toggle="dropdownFirmaHover"
                data-dropdown-trigger="hover"
                class="flex justify-between items-center lg:block cursor-pointer relative after:transition-all after:duration-1000 after:absolute after:w-full after:-bottom-9 focus:after:h-[3px] after:active:h-[3px] after:left-0 after:bg-transparent after:h-0 transition duration-500 pb-4 lg:pb-0"
                :class="{
                  'lg:after:h-[3px] lg:after:bg-primary': inSubmenueFirma,
                  'scaled-up-half': store.fontfactor >= 1.05,
                  'scaled-up-full': store.fontfactor >= 1.1,
                }"
                type="button">
                Firmendaten
                <div
                  class="toggle block lg:hidden relative before:h-1 after:transition-all after:duration-500 before:w-8 before:bg-primary before:absolute before:right-0 after:h-1 after:w-8 after:bg-primary after:absolute after:right-0 after:-rotate-90 before:rounded after:rounded"></div>
              </li>
              <div
                id="dropdownFirmaHover"
                class="z-10 hidden lg:bg-gray-50 lg:rounded-lg lg:shadow-md">
                <ul
                  class="grid grid-cols-2 pb-2 lg:py-2 text-xl lg:text-base"
                  aria-labelledby="dropdownFirmaHoverButton">
                  <li>
                    <router-link
                      active-class="font-bold"
                      to="/stammdaten"
                      class="block lg:px-4 py-2 border-r">
                      Stammdaten
                    </router-link>
                  </li>

                  <li>
                    <router-link
                      active-class="font-bold"
                      to="/bankdaten"
                      class="block lg:px-4 py-2">
                      Bankdaten
                    </router-link>
                  </li>
                  <li>
                    <router-link
                      active-class="font-bold"
                      to="/firmenadressdaten"
                      class="block lg:px-4 py-2 border-r">
                      Adressdaten
                    </router-link>
                  </li>
                  <li>
                    <router-link
                      active-class="font-bold"
                      to="/finanzamtsdaten"
                      class="block lg:px-4 py-2 border-r">
                      Finanzamtsdaten
                    </router-link>
                  </li>
                </ul>
                <div id="arrow" data-popper-arrow></div>
              </div>
            </div>
            <div :class="{ hidden: !isPerson }">
              <li
                id="dropdownVersorgungHoverButton"
                data-dropdown-toggle="dropdownVersorgungHover"
                data-dropdown-trigger="hover"
                class="flex justify-between items-center lg:block cursor-pointer relative after:transition-all after:duration-1000 after:absolute after:w-full after:-bottom-9 focus:after:h-[3px] after:active:h-[3px] after:left-0 after:bg-transparent after:h-0 transition duration-500 pb-4 lg:pb-0"
                :class="{
                  'lg:after:h-[3px] lg:after:bg-primary': inSubmenueVersorgung,
                  'scaled-up-half': store.fontfactor >= 1.05,
                  'scaled-up-full': store.fontfactor >= 1.1,
                }"
                type="button">
                Meine Versorgung
                <div
                  class="toggle block lg:hidden relative before:h-1 after:transition-all after:duration-500 before:w-8 before:bg-primary before:absolute before:right-0 after:h-1 after:w-8 after:bg-primary after:absolute after:right-0 after:-rotate-90 before:rounded after:rounded"></div>
              </li>
              <div
                id="dropdownVersorgungHover"
                class="z-10 hidden lg:bg-gray-50 lg:rounded-lg lg:shadow-md">
                <ul
                  class="grid grid-cols-1 pb-2 lg:py-2 text-xl lg:text-base"
                  aria-labelledby="dropdownHoverButton">
                  <li>
                    <router-link
                      active-class="font-bold"
                      to="/versorgungsdaten"
                      class="block lg:px-4 py-2 border-r">
                      Versorgungsdaten
                    </router-link>
                  </li>

                  <li>
                    <router-link
                      active-class="font-bold"
                      to="/versorgungsdokumente"
                      class="block lg:px-4 py-2">
                      Versorgungsdokumente
                    </router-link>
                  </li>
                </ul>
                <div id="arrow" data-popper-arrow></div>
              </div>
            </div>
            <li :class="{ hidden: isPerson || !store.subData.isLoaded }">
              <router-link
                active-class="font-bold"
                to="/dokumente"
                class="block pb-4 lg:pb-0">
                Firmendokumente
              </router-link>
            </li>
            <li>
              <router-link
                active-class="lg:after:h-[3px]"
                class="relative cursor-pointer after:transition-all after:duration-1000 after:absolute after:w-full lg:after:-bottom-9 lg:focus:after:h-[3px] lg:after:active:h-[3px] after:left-0 after:bg-primary after:h-0 transition duration-500 pb-4 lg:pb-0 block"
                :class="{
                  'lg:after:h-[3px] lg:after:bg-primary': isAuftrag,
                  'scaled-up-half': store.fontfactor >= 1.05,
                  'scaled-up-full': store.fontfactor >= 1.1,
                }"
                to="/auftrag-an-pensus">
                Auftrag an Pensus
              </router-link>
            </li>
          </ul>
          <div class="fixed-sidebar lg:hidden block fixed bottom-16 w-full">
            <div class="fixed-sidebar-wrapper">
              <div
                class="fixed-sidebar-content flex flex-col items-center mb-1">
                <router-link
                  active-class="font-bold"
                  to="/pensus-faq"
                  class="phone-icon h-16 text-white overflow-hidden bg-primary flex transition-all duration-500 w-full items-center px-5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-10 h-10 inline-block shrink-0">
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                  </svg>

                  <span class="px-3 text-lg">
                    Benötigen Sie Hilfe beim Serviceportal?
                  </span>
                </router-link>
              </div>

              <router-link
                active-class="font-bold"
                to="/kontaktformular"
                class="phone-icon h-16 px-5 text-white overflow-hidden absolute bg-primary flex transition-all duration-500 right-0 w-full items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-10 h-10 inline-block shrink-0">
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z" />
                </svg>

                <span class="px-3 text-lg">So kommen wir in Kontakt</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>
